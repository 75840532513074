import { useEffect, useState } from "react";

import { UserVesselsGetResponse } from "../../model/api/response/UserVesselsGetResponse";

interface Props {
  data: any[]; //ページネーションしたいデータ
  numPerPage: number; //1ページに表示するデータ数
  handleChangePageIndex?: (data: any[], page: number) => void; //ページが変わったときの処理
  selectedPage?: number; //初期表示ページ
}

function Pagination(props: Props) {
  const [currentPage, setCurrentPage] = useState<number>(0); //現在のページ
  //総ページ数
  const totalPage: number =
    Math.floor(props.data?.length % props.numPerPage) === 0
      ? Math.floor(props.data?.length / props.numPerPage)
      : Math.floor(props.data?.length / props.numPerPage) + 1;

  //ページ切り替えボタン
  const pageChangeButton = (value: string) => {
    return (
      <span
        className="px-2 hover-pointer"
        onClick={() => handleClickPageChangeButton(value)}
        data-cy="船舶ページング"
      >
        {value}
      </span>
    );
  };

  //現在のページ数をもとに表示するデータを算出する。
  function calcDisplayData(currentPage: number) {
    const displayData: any[] = props.data?.filter(
      (it: UserVesselsGetResponse, index: number) =>
        index >= props.numPerPage * (currentPage - 1) &&
        index <= props.numPerPage * currentPage - 1
    );
    if (props.handleChangePageIndex) {
      props.handleChangePageIndex(displayData, currentPage);
    }
  }

  //ページ切り替えボタン押下時の処理
  function handleClickPageChangeButton(id: string) {
    switch (id) {
      case "|<":
        setCurrentPage(1);
        break;
      case "<":
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
        break;
      case ">":
        if (currentPage !== totalPage) {
          setCurrentPage(currentPage + 1);
        }
        break;
      case ">|":
        setCurrentPage(totalPage);
        break;
      default:
        break;
    }
  }

  //ページが切り替わるごとに実行
  useEffect(() => {
    // 2ページ目以降にいてデータがなくなったら1ページ目に飛ばす
    if (currentPage > 1 && props.data?.length < props.numPerPage) {
      setCurrentPage(1);
    }

    calcDisplayData(currentPage);
  }, [currentPage, props.data]);

  //初回各種データ読み込み&レンダリング後に1度だけ実行
  useEffect(() => {
    if (currentPage === 0 && props.data && props.data.length !== 0) {
      calcDisplayData(1);
      setCurrentPage(props.selectedPage ?? 1);
    }
  });

  //レンダリング
  return (
    <>
      {props.data?.length >= props.numPerPage && (
        <div className="text-center mt-4">
          {pageChangeButton("|<")}
          {pageChangeButton("<")}
          {pageChangeButton(">")}
          {pageChangeButton(">|")}
        </div>
      )}
    </>
  );
}
export default Pagination;
