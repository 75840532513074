/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import axios from "axios";
import { useRef, useState } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { AiOutlineBarChart, AiOutlineFileSearch } from "react-icons/ai";
import { BsDiagram3, BsTools } from "react-icons/bs";
import { FiHelpCircle } from "react-icons/fi";
import { HiOutlineCalculator } from "react-icons/hi";
import { IoSettingsOutline } from "react-icons/io5";
import { MdEngineering, MdHistory } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { NavLink, useNavigate } from "react-router-dom";
import Sidebar from "react-sidebar";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import { useS3 } from "../../hook/useS3";
import { FileUploadParamater } from "../../util/Constant";
import FileUpload, { FileUploadHandles } from "../common/FileUpload";
import UserDetail, {
  UserDetailHandles,
  statusUpdate,
} from "../setting/UserDetail";

function SideMenu() {
  // ファイルアップロード定義
  const FileUploadRef = useRef<FileUploadHandles>(null);
  const userDetailRef = useRef<UserDetailHandles>(null);

  const navigate = useNavigate();

  // S3使用宣言
  const s3 = useS3();

  // 権限情報
  const auth = useAuthUserContext();

  // ダイアログ使用宣言
  const showDialog = useDialog();

  const onClick = (fileType: string) => {
    getFile(fileType);
  };

  //ユーザ名が長い際に折り返す(10文字ごと)
  function editUserName(userName: string) {
    let resultUserName: string = "";
    let resultUserNameList: string[] = [];

    for (let i = 0; i < userName.length; i++) {
      resultUserName += userName.charAt(i);
      if (resultUserName.length % 10 === 0) {
        resultUserNameList.push(resultUserName);
        resultUserName = "";
      }
    }
    resultUserNameList.push(resultUserName);
    return resultUserNameList;
  }

  //ログアウトする処理
  function handleClickLogout() {
    auth.signout(() => {
      navigate("/");
    });
  }

  function getFile(fileType: string) {
    let s3Prefix;
    let isPublic: boolean = false; // デフォルトは署名付き
    let messageArgs = "";
    switch (fileType) {
      case FileUploadParamater.SERVICE_TARIFF:
        // Service Tariff
        s3Prefix = `document/service-tariff/`;
        messageArgs = "Service Tariff";
        isPublic = false;
        break;
      case FileUploadParamater.SYSTEM_DIAGRAM:
        // System Diagram
        s3Prefix = `document/system-diagram/`;
        messageArgs = "System Diagram";
        isPublic = true;
        break;
      case FileUploadParamater.SUPPORT_END:
        // サポート終了部品
        s3Prefix = `document/end-of-support-item/`;
        messageArgs = "サポート終了部品";
        isPublic = true;
        break;
      case FileUploadParamater.MANUAL:
        // マニュアル
        s3Prefix = `manual/`;
        messageArgs = "マニュアル";
        isPublic = true;
        break;
      default:
        break;
    }
    if (s3Prefix) {
      s3.getS3FileList(s3Prefix, isPublic).then((response) => {
        if (response.data.length === 0) {
          showDialog({ id: "E065", args: [messageArgs], confirm: false });
          return;
        }
        response.data.forEach((objectKey) => {
          if (isPublic) {
            const url = s3.getS3Path(objectKey, true);
            axios
              .get<Blob>(url, { responseType: "blob" })
              .then((blobResponse) => {
                if (blobResponse) {
                  const fileName = objectKey.substring(
                    objectKey.lastIndexOf("/") + 1
                  );
                  const blobUrl = URL.createObjectURL(
                    new File([blobResponse.data], fileName, {
                      type: blobResponse.headers["content-type"],
                    })
                  );
                  window.open(blobUrl);
                }
              });
          } else {
            // 署名付きURLを取得してからデータ取得
            s3.getS3PresignedUrl("GET", objectKey).then((res2) => {
              const presignedUrl = res2.data;
              axios
                .get<Blob>(presignedUrl, { responseType: "blob" })
                .then((blobResponse) => {
                  if (blobResponse) {
                    const fileName = objectKey.substring(
                      objectKey.lastIndexOf("/") + 1
                    );

                    const blobUrl = URL.createObjectURL(
                      new File([blobResponse.data], fileName, {
                        type: blobResponse.headers["content-type"],
                      })
                    );
                    window.open(blobUrl);
                  }
                });
            });
          }
        });
      });
    }
  }

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false); //サイドバー開閉フラグ

  //サイドバー閉じてる時のcss
  const closeSideBarCss = css`
    color: #b5b5b5;
    .topIcon,
    .userIcon {
      opacity: 0.4;
    }
    :hover {
      color: black;
      .topIcon,
      .userIcon {
        opacity: 1;
      }
    }
  `;

  //サイドバー開いてるときのcss
  const openSideBarCss = css`
    opacity: 1;
    .nav-link:hover,
    span:hover {
      opacity: 0.6;
    }
  `;

  //サイドバー閉じてる時のstyle
  const defaultStyle = {
    root: {
      left: `calc(100% - 72px)`,
    },
  };

  //サイドバー開いてる時のstyle
  const sideMenuStyle = {
    content: {
      position: "absolute",
      left: "200px",
    },
  };

  return (
    <Sidebar
      pullRight
      styles={sidebarOpen ? sideMenuStyle : defaultStyle}
      open={sidebarOpen}
      onSetOpen={setSidebarOpen}
      sidebarClassName="b-sidebar"
      rootClassName="b-sidebar-root"
      contentClassName="b-sidebar-content"
      overlayClassName="b-sidebar-overlay"
      sidebar={
        //サイドバー開いてるとき
        <div
          className="h-100"
          css={openSideBarCss}
          style={{
            overflowX: "hidden",
            backgroundColor: "#1C232E",
            width: "270px",
            opacity: "0.95",
          }}
        >
          {/*TOP */}
          <Nav.Item
            style={{
              position: "relative",
              top: "100px",
              left: "30px",
            }}
          >
            <Nav.Link
              as={NavLink}
              to="/"
              className="px-0 pb-4 pt-0"
              onClick={() => setSidebarOpen(false)}
              data-cy="トップメニューリンク"
            >
              <img
                src={`${process.env.PUBLIC_URL}/jump-to-top-icon.png`}
                alt="MaSSA-Logo"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
              <span className="mx-4">TOP</span>
            </Nav.Link>

            {/* ユーザ情報 */}
            <NavDropdown
              title={
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/user-icon.png`}
                    alt="userIcon"
                    style={{
                      position: "relative",
                      left: "-1px",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <span
                    className="mx-4"
                    style={{ whiteSpace: "pre-wrap", position: "absolute" }}
                  >
                    {editUserName(auth.user().userName + "様").map(
                      (it, index) => {
                        return <div key={index}>{it}</div>;
                      }
                    )}
                  </span>
                </div>
              }
              style={{ position: "relative", left: "-15px", top: "-8px" }}
              align="end"
              data-cy="ユーザメニューリンク"
            >
              <NavDropdown.Item
                onClick={() =>
                  userDetailRef.current?.show(
                    auth.user().userId,
                    statusUpdate,
                    auth.user().bemacFlg
                  )
                }
                data-cy="ユーザ情報リンク"
              >
                ユーザ情報
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={handleClickLogout}
                data-cy="ログアウトリンク"
              >
                ログアウト
              </NavDropdown.Item>
            </NavDropdown>

            {/*分析 */}
            <Nav.Link
              className="px-0 pb-4 pt-2"
              as={NavLink}
              to="/order-analysis-top"
              onClick={() => setSidebarOpen(false)}
              data-cy="分析メニューリンク"
            >
              <div>
                <AiOutlineBarChart size="20px" />
                <span className="mx-4">VESSEL ANALYSIS</span>
              </div>
            </Nav.Link>

            {/* LCM（商社ユーザは非表示） */}
            {!auth.user().tradingFlg && (
              <Nav.Link
                as={NavLink}
                to="/lcm-top"
                className="px-0 pb-4 pt-2"
                onClick={() => setSidebarOpen(false)}
                data-cy="LCMメニューリンク"
              >
                <div>
                  <MdEngineering size="20px" />

                  <span
                    className="mx-4"
                    style={{ whiteSpace: "pre-wrap", position: "absolute" }}
                  >
                    LIFE CYCLE MAINTENANCE
                  </span>
                </div>
              </Nav.Link>
            )}

            {/* 見積 */}
            <Nav.Link
              className="px-0 pb-4 pt-2"
              as={NavLink}
              to="/quotation-list"
              onClick={() => setSidebarOpen(false)}
              data-cy="見積メニューリンク"
            >
              <div>
                <TbReportSearch size="20px" />
                <span className="mx-4">Past QUOTATION</span>
              </div>
            </Nav.Link>

            {/* 注文 */}
            <div>
              <Nav.Link
                className="px-0 pb-4 pt-2"
                as={NavLink}
                to={"/order-list"}
                onClick={() => setSidebarOpen(false)}
                data-cy="注文メニューリンク"
              >
                <div>
                  <AiOutlineFileSearch size={"20px"} />
                  <span className="mx-4">Past ORDER</span>
                </div>
              </Nav.Link>
            </div>
            {/* 不具合対応履歴（商社ユーザは非表示） */}
            {!auth.user().tradingFlg && (
              <div>
                <Nav.Link
                  className="px-0 pb-4 pt-2"
                  as={NavLink}
                  to={"/maintenance-history-list"}
                  onClick={() => setSidebarOpen(false)}
                  data-cy="不具合対応履歴メニューリンク"
                >
                  <div>
                    <MdHistory />
                    <span className="mx-4">Maintenance History</span>
                  </div>
                </Nav.Link>
              </div>
            )}

            {/* Service Tariff */}
            {auth.isBemacAdmin() ? (
              <div>
                <NavDropdown
                  title={
                    <div>
                      <HiOutlineCalculator
                        size="20px"
                        style={{ position: "relative", left: "-1px" }}
                      />
                      <span className="mx-4">Service Tariff</span>
                    </div>
                  }
                  style={{ position: "relative", left: "-15px" }}
                  data-cy="サービスタリフリンク"
                >
                  <NavDropdown.Item
                    onClick={() => onClick(FileUploadParamater.SERVICE_TARIFF)}
                    data-cy="サービスタリフビューリンク"
                  >
                    View
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() =>
                      FileUploadRef.current?.show(
                        FileUploadParamater.SERVICE_TARIFF
                      )
                    }
                    data-cy="サービスタリフアップロードリンク"
                  >
                    Upload
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            ) : (
              <div
                className="text-white hover-pointer px-0 pb-4 pt-2"
                onClick={() => onClick(FileUploadParamater.SERVICE_TARIFF)}
                css={openSideBarCss}
                data-cy="サービスタリフリンク"
              >
                <HiOutlineCalculator size="20px" />
                <span className="mx-4">Service Tariff</span>
              </div>
            )}

            {/* System Diagram */}
            {auth.isBemacAdmin() ? (
              <NavDropdown
                title={
                  <div>
                    <BsDiagram3
                      size="20px"
                      style={{ position: "relative", left: "-1px" }}
                    />
                    <span className="mx-4">System Diagram</span>
                  </div>
                }
                style={{ position: "relative", left: "-15px" }}
                id="systemDiagram"
                data-cy="システムダイアグラムリンク"
              >
                <NavDropdown.Item
                  onClick={() => onClick(FileUploadParamater.SYSTEM_DIAGRAM)}
                  data-cy="システムダイアグラムビューリンク"
                >
                  View
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() =>
                    FileUploadRef.current?.show(
                      FileUploadParamater.SYSTEM_DIAGRAM
                    )
                  }
                  data-cy="システムダイアグラムアップロードリンク"
                >
                  Upload
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <div
                className="text-white hover-pointer px-0 pb-4 pt-2"
                onClick={() => onClick(FileUploadParamater.SYSTEM_DIAGRAM)}
                data-cy="システムダイアグラムリンク"
              >
                <BsDiagram3 size="20px" />
                <span className="mx-4">System Diagram</span>
              </div>
            )}

            {/* サポート終了部品 */}
            {auth.isBemacAdmin() ? (
              <NavDropdown
                title={
                  <div>
                    <BsTools
                      size="20px"
                      style={{ position: "relative", left: "-1px" }}
                    />
                    <span className="mx-4">End of Support Item</span>
                  </div>
                }
                style={{ position: "relative", left: "-15px" }}
                data-cy="サポート終了部品リンク"
              >
                <NavDropdown.Item
                  onClick={() => onClick(FileUploadParamater.SUPPORT_END)}
                  data-cy="サポート終了部品ビューリンク"
                >
                  View
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() =>
                    FileUploadRef.current?.show(FileUploadParamater.SUPPORT_END)
                  }
                  data-cy="サポート終了部品アップロードリンク"
                >
                  Upload
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <div
                className="text-white hover-pointer px-0 pb-4 pt-2"
                onClick={() => onClick(FileUploadParamater.SUPPORT_END)}
                data-cy="サポート終了部品リンク"
              >
                <BsTools size="20px" />
                <span className="mx-4">End of Support Item</span>
              </div>
            )}

            {/* Manual */}
            {auth.isBemacAdmin() ? (
              <NavDropdown
                title={
                  <div>
                    <FiHelpCircle
                      size="20px"
                      style={{ position: "relative", left: "-1px" }}
                    />
                    <span className="mx-4">MANUAL</span>
                  </div>
                }
                style={{ position: "relative", left: "-15px" }}
                data-cy="マニュアルリンク"
              >
                <NavDropdown.Item
                  onClick={() => onClick(FileUploadParamater.MANUAL)}
                  data-cy="マニュアルビューリンク"
                >
                  View
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() =>
                    FileUploadRef.current?.show(FileUploadParamater.MANUAL)
                  }
                  data-cy="マニュアルアップロードリンク"
                >
                  Upload
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <div
                className="text-white hover-pointer px-0 pb-4 pt-2"
                onClick={() => onClick(FileUploadParamater.MANUAL)}
                data-cy="マニュアルリンク"
              >
                <FiHelpCircle size="20px" />
                <span className="mx-4">MANUAL</span>
              </div>
            )}

            {/* Setting Menu */}
            {(auth.isBemacAdmin() || auth.isCustomerAdmin()) && (
              <NavDropdown
                title={
                  <div>
                    <IoSettingsOutline
                      size="20px"
                      style={{ position: "relative", left: "-1px" }}
                    />
                    <span className="mx-4">SETTING</span>
                  </div>
                }
                style={{ position: "relative", left: "-15px", top: "-8px" }}
                data-cy="設定メニューリンク"
              >
                <NavDropdown.Item
                  as={NavLink}
                  to="/user-list"
                  onClick={() => setSidebarOpen(false)}
                  data-cy="ユーザ一覧リンク"
                >
                  ユーザ一覧
                </NavDropdown.Item>
                {auth.isBemacAdmin() && (
                  <>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/ship-list"
                      onClick={() => setSidebarOpen(false)}
                      data-cy="船舶一覧リンク"
                    >
                      船舶一覧
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/product-list"
                      onClick={() => setSidebarOpen(false)}
                      data-cy="商品一覧リンク"
                    >
                      商品一覧
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/company-list"
                      onClick={() => setSidebarOpen(false)}
                      data-cy="得意先一覧リンク"
                    >
                      得意先一覧
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/service-news-list"
                      onClick={() => setSidebarOpen(false)}
                      data-cy="サービスニュース一覧リンク"
                    >
                      サービスニュース一覧
                    </NavDropdown.Item>
                  </>
                )}
              </NavDropdown>
            )}
          </Nav.Item>
          <FileUpload ref={FileUploadRef} />
          <UserDetail ref={userDetailRef} />
        </div>
      }
    >
      {/*サイドバー閉じてるとき */}
      {!sidebarOpen && (
        <button
          className="border-0 h-100"
          onClick={() => setSidebarOpen(true)}
          css={closeSideBarCss}
          style={{
            width: "72px",
            overflow: "hidden",
            backgroundColor: "#F5F5F5",
          }}
          data-cy="サイドバーボタン"
        >
          {/* TOP */}
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/jump-to-top-icon.png`}
              alt="MaSSA-Logo"
              className="my-3 topIcon"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </div>
          {/* ユーザー情報 */}
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/user-icon.png`}
              alt="MaSSA-Logo"
              className="my-3 userIcon"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </div>
          {/* 分析 */}
          <div>
            <AiOutlineBarChart className="my-3" size="20px" />
          </div>
          {/* LCM（商社は非表示） */}
          {!auth.user().tradingFlg && (
            <div>
              <MdEngineering className="my-3" size="20px" />
            </div>
          )}
          {/* 見積 */}
          <div>
            <TbReportSearch className="my-3" size="20px" />
          </div>
          {/* 注文 */}
          <div>
            <AiOutlineFileSearch className="my-3" size="20px" />
          </div>
          {/* 不具合対応履歴（商社は非表示） */}
          {!auth.user().tradingFlg && (
            <div>
              <MdHistory className="my-3" size="20px" />
            </div>
          )}
          {/* サービスタリフ */}
          <div>
            <HiOutlineCalculator className="my-3" size="20px" />
          </div>
          {/* システムダイアグラム */}
          <div>
            <BsDiagram3 className="my-3" size="20px" />
          </div>
          {/* サポート終了部品 */}
          <div>
            <BsTools className="my-3" size="20px" />
          </div>
          {/* マニュアル */}
          <div>
            <FiHelpCircle className="my-3" size="20px" />
          </div>
          {/* 設定 */}
          <div>
            {(auth.isBemacAdmin() || auth.isCustomerAdmin()) && (
              <IoSettingsOutline className="my-3" size="20px" />
            )}
          </div>
        </button>
      )}
    </Sidebar>
  );
}

export default SideMenu;
