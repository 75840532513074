import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { useSplashMessage } from "src/hook/useSplashMessage";

import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { OrderCostsGetResponse } from "../../model/api/response/OrderCostsGetResponse";
import { OrderFactor, TextConst } from "../../util/Constant";
import { getBizError } from "../../util/ecUtil";
import { START_YEAR, SearchValueType } from "./OrderAnalysisTop";

type Props = {
  searchValues: SearchValueType;
  orderCostDataList: OrderCostsGetResponse[];
};

function OrderAnalysis(props: Props) {
  type DatalabelAnchorType = "start" | "center" | "end";
  type DatalabelAlignType =
    | "center"
    | "start"
    | "end"
    | "right"
    | "bottom"
    | "left"
    | "top";

  type GridType = {
    sumFlg: boolean;
    showYear?: string;
    year?: number;
    imoNo?: string;
    imoIndex?: number;
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  // API使用宣言
  const api = useApi();

  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();
  //ダイアログ仕様宣言
  const showDialog = useDialog();

  // state
  const [selectedValue, setSelectedValue] = useState(0);
  const [yearPeriod, setYearPeriod] = useState<number[]>([]);
  const [selectedYear, setSelectedYear] = useState(0);
  const [orderCostDataList, setOrderCostDataList] = useState<
    OrderCostsGetResponse[]
  >([]);
  const [searchValues, setSearchValues] = useState<SearchValueType>(
    props.searchValues
  );

  const SELECT_VESSEL_LIST = 0; //分析対象船舶(船名リストから選択)
  const SELECT_ALL_VESSEL = 10; //分析対象船舶(全船)
  const FILE_NAME_START = "年別船別注文金額一覧";
  const LABEL_NO_VESSEL = "船舶無し";
  const START_MONTH_JAN = "(年)";
  const START_MONTH_APR = "(年度)";
  const VESSEL_TOTAL = "管理船舶合算";

  /** "全船"を表す仮想IMO */
  const IMO_ALL = "all";
  /** "船舶なし"を表す仮想IMO */
  const IMO_NO_VESSEL = "containNoVesselName";

  // 表示値チェックボックスリスト
  const showValueList = [
    { value: 0, text: "合計" },
    { value: 1, text: "EC" },
    { value: 2, text: "LCM" },
  ];

  // グラフの幅・位置調整
  const graphDivStyle = { marginLeft: "5px" };
  const barGraphOptions = (yearOrMonth: String) => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      barPercentage: 0.1,
      plugins: {
        legend: {
          display: true,
        },
        datalabels: {
          anchor: "end" as DatalabelAnchorType,
          align: "top" as DatalabelAlignType,
          formatter: (value: number) => {
            return value.toLocaleString();
          },
        },
      },
      scales: {
        //グラフ・凡例間に余白を開けるため、y軸の値に幅を持たせる
        y: {
          max:
            calcYearMaxPrice(yearOrMonth) +
            10 ** (String(calcYearMaxPrice(yearOrMonth)).length - 1),
          min: 0,
        },
      },
    };
  };

  const chartRef = useRef();
  /** 年別グラフクリック */
  const onYearGraphClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;
    if (!chart) {
      return;
    }

    // X軸のラベルを取得
    let xLabel = "yyyy年";
    const graphElement = getElementAtEvent(chart, event);
    if (graphElement.length) {
      // 要素をクリック
      const index = graphElement[0].index;
      xLabel = barYearGraphData().labels[index];
    } else {
      // 背景エリアをクリック
      const myChart = chartRef.current! as ChartJS;
      const chartArea = myChart.chartArea;
      const xScale = myChart.scales["x"];
      const mouseX = event.clientX - chartArea.left;
      const xValue = xScale.getValueForPixel(mouseX);
      xLabel = xScale.getLabelForValue(xValue!);
    }

    // 項目のラベル名から年度を切り出し
    const year = xLabel.substring(0, 4);
    setSelectedYear(Number(year));
  };
  const YearGraphLabels = yearPeriod.map((it) => it + "年");

  const graphColor0 = "#FF999E";
  const graphColor1 = "#FF99CC";
  const graphColor2 = "#FF9EFF";
  const graphColor3 = "#CFC4ED";
  const graphColor4 = "#B4C7E7";
  const graphColor5 = "#99FFFF99";
  const graphColor6 = "#99FF99";
  const graphColor7 = "#CCFF99";
  const graphColor8 = "#FFFF9999";
  const graphColor9 = "#FFCC99";
  const graphColorDefault = "#FFFFFF";

  //年別のグラフデータ設定
  const YearDataSetList = () => {
    // 対象のIMOをリスト化
    const targetImoList = getGraphImoList();

    // IMOごとに年別グラフ用データを収集
    return targetImoList.map((imo, index) => {
      const data = yearPeriod.map((year) => {
        return yearGraphValues(year, imo);
      });
      // 色のインデックスを特定
      const colorIndex = imo === IMO_NO_VESSEL ? 10 : index; // 船舶なしは10番

      return {
        label: graphVesselName(imo),
        data: data,
        backgroundColor: graphElementColor(colorIndex),
      };
    });
  };

  //月別のグラフデータ設定
  const MonthDataSetList = () => {
    // 対象のIMOをリスト化
    const targetImoList = getGraphImoList();

    // IMOごとに月別グラフ用データを収集
    return targetImoList.map((imo, index) => {
      const data = [...Array(12)].map((_, i) => {
        // 年を特定
        let year = selectedYear;
        if (props.searchValues.showStartMonth !== START_YEAR && i >= 9) {
          year++; // 年度指定で10か月目以降は翌年
        }
        // 月を特定
        let month = i + props.searchValues.showStartMonth; // 開始月を加算
        if (month > 12) {
          month = month - 12; // 12月を超えたら1月に戻す
        }
        return monthGraphValues(year, month, imo);
      });
      // 色のインデックスを特定
      const colorIndex = imo === IMO_NO_VESSEL ? 10 : index; // 船舶なしは10番

      return {
        label: graphVesselName(imo),
        data: data,
        backgroundColor: graphElementColor(colorIndex),
      };
    });
  };

  /** グラフ出力対象のIMOリスト */
  const getGraphImoList = () => {
    const targetImoList: string[] = [];
    if (searchValues.analyzedVesselSelect === SELECT_ALL_VESSEL) {
      // 全船の場合
      targetImoList.push(IMO_ALL);
    } else {
      targetImoList.push(...searchValues.imo);
      if (searchValues.vesselNoOrNotContained) {
        targetImoList.push(IMO_NO_VESSEL);
      }
    }
    return targetImoList;
  };

  const barYearGraphData = () => {
    return {
      labels: YearGraphLabels,
      datasets: YearDataSetList(),
    };
  };

  const barMonthGraphData = () => {
    const labels = [...Array(12)].map((_, i) => {
      if (props.searchValues.showStartMonth === 1) {
        return i + 1 + "月";
      } else {
        if (i < 9) {
          return i + 4 + "月";
        } else {
          return i - 8 + "月";
        }
      }
    });
    return {
      labels,
      datasets: MonthDataSetList(),
    };
  };

  /** 月別グラフの表示幅を計算 */
  const monthGraphWidth = () => {
    const width = 1000 + getGraphImoList().length * 100;
    return width + "px";
  };

  //棒グラフの値を計算(年別)
  const yearGraphValues = (year: number, imo: string) => {
    let value = 0;

    orderCostDataList
      .filter(
        (it) =>
          isTargetYear(it, year) &&
          (selectedValue === 0 || // 合計
            (selectedValue === 1 &&
              it.orderClassValue === OrderFactor.EC_PARTS) || // EC
            (selectedValue === 2 &&
              it.orderClassValue !== OrderFactor.EC_PARTS)) && // LCM
          (imo === it.imo || // 選択船舶
            imo === IMO_ALL || // 全船
            (imo === IMO_NO_VESSEL && it.imo === "")) // 船舶なし
      )
      .forEach((data) => {
        value += data.replySubtotalPriceMonth;
      });

    return value;
  };

  //棒グラフの値を計算(月別)
  const monthGraphValues = (year: number, month: number, imo: string) => {
    let value = 0;

    orderCostDataList
      .filter(
        (it) =>
          it.showYear === year &&
          it.showMonth === month &&
          (selectedValue === 0 || // 合計
            (selectedValue === 1 &&
              it.orderClassValue === OrderFactor.EC_PARTS) || // EC
            (selectedValue === 2 &&
              it.orderClassValue !== OrderFactor.EC_PARTS)) && // LCM
          (imo === it.imo || // 選択船舶
            imo === IMO_ALL || // 全船
            (imo === IMO_NO_VESSEL && it.imo === "")) // 船舶なし
      )
      .forEach((data) => {
        value += data.replySubtotalPriceMonth;
      });

    return value;
  };

  const graphElementColor = (index: number) => {
    switch (index) {
      case 0:
        return graphColor0;
      case 1:
        return graphColor1;
      case 2:
        return graphColor2;
      case 3:
        return graphColor3;
      case 4:
        return graphColor4;
      case 5:
        return graphColor5;
      case 6:
        return graphColor6;
      case 7:
        return graphColor7;
      case 8:
        return graphColor8;
      case 9:
        return graphColor9;
      default:
        return graphColorDefault;
    }
  };

  const graphVesselName = (imoNo: string) => {
    let vesselName = "";

    if (imoNo === IMO_ALL) {
      vesselName = "全船";
    } else if (imoNo === IMO_NO_VESSEL) {
      vesselName = "船舶無し";
    } else {
      const findVesselName = orderCostDataList.find(
        (it) => it.imo === imoNo
      )?.vesselName;
      vesselName = findVesselName ?? "";

      if (orderCostDataList.find((it) => it.imo === imoNo)) {
        vesselName += "(IMO:" + imoNo + ")"; // グラフクリック時にIMOが必要なためラベルに保持
      }
    }
    return vesselName;
  };

  // グリッドデータ(項目列)
  const gridData: GridType[] = [];
  let countIndex: number = 0;
  if (searchValues.imo.length) {
    yearPeriod.forEach((year) => {
      searchValues.imo.forEach((imoNo, index) => {
        gridData.push({
          sumFlg: false,
          showYear: index === 0 ? year.toString() : undefined,
          year: year,
          imoNo: imoNo,
          imoIndex: index,
        });
        countIndex = index + 1;
      });
      if (searchValues.vesselNoOrNotContained) {
        //船舶無しを含む場合にチェックがある場合
        gridData.push({
          sumFlg: false,
          showYear: undefined,
          year: year,
          imoNo: IMO_NO_VESSEL,
          imoIndex: countIndex + 1,
        });
        countIndex++;
      }
    });
  }
  if (
    !searchValues.imo.length &&
    searchValues.analyzedVesselSelect === SELECT_VESSEL_LIST &&
    searchValues.vesselNoOrNotContained
  ) {
    //船舶無しを含む、オンリーの場合
    yearPeriod.forEach((year) => {
      gridData.push({
        sumFlg: false,
        showYear: year.toString(),
        year: year,
        imoNo: IMO_NO_VESSEL,
        imoIndex: 0,
      });
    });
  }
  if (searchValues.analyzedVesselSelect === SELECT_ALL_VESSEL) {
    //全船の場合
    yearPeriod.forEach((year) => {
      gridData.push({
        sumFlg: false,
        showYear: year.toString(),
        year: year,
        imoNo: IMO_ALL,
        imoIndex: 0,
      });
    });
  }
  gridData.push({
    sumFlg: true,
    showYear: "合計",
  });

  // グリッドの列定義
  const columnDefs: ColDef[] = [
    {
      headerName: "年",
      field: "showYear",
      colId: "year",
      width: 160,
      pinned: false,
      cellClassRules: {
        "b-cell-backgroupd-color-light-blue": (params: any) =>
          params.data.sumFlg,
        "bg-white": (params: any) => countIndex > 1,
      },
      cellClass: [
        "b-cell-border-right-solid-thin-gray",
        "text-center",
        "border-bottom",
      ],
      headerClass: [
        "b-header-cell-center",
        "b-cell-border-right-solid-thin-gray",
      ],
      rowSpan: (params: any) => {
        if (params.data.imoIndex === 0 && countIndex !== 0) {
          return countIndex;
        } else {
          return 1;
        }
      },
    },
    {
      headerName: "船名",
      colId: "vessel",
      cellRendererSelector: cellRenderSelector,
      width: 160,
      pinned: false,
      cellClass: ["b-cell-border-right-solid-thin-gray", "text-start"],
      cellClassRules: {
        "b-cell-backgroupd-color-light-blue": (params: any) =>
          params.data.sumFlg,
      },
      headerClass: [
        "b-header-cell-center",
        "b-cell-border-right-solid-thin-gray",
      ],
    },
    {
      headerName: "EC",
      colId: "ec",
      cellRendererSelector: cellRenderSelector,
      width: 200,
      pinned: false,
      cellClass: ["b-cell-border-right-solid-thin-gray", "text-end"],
      cellClassRules: {
        "b-cell-backgroupd-color-light-blue": (params: any) =>
          params.data.sumFlg,
      },
      headerClass: [
        "b-header-cell-center",
        "b-cell-border-right-solid-thin-gray",
      ],
    },
    {
      headerName: "LCM",
      colId: "lcm",
      cellRendererSelector: cellRenderSelector,
      width: 200,
      pinned: false,
      cellClass: ["b-cell-border-right-solid-thin-gray", "text-end"],
      cellClassRules: {
        "b-cell-backgroupd-color-light-blue": (params: any) =>
          params.data.sumFlg,
      },
      headerClass: [
        "b-header-cell-center",
        "b-cell-border-right-solid-thin-gray",
      ],
    },
  ];

  // 合計列を追加
  columnDefs.push({
    headerName: "合計",
    colId: "sumColumn",
    cellRenderer: summanyColumnCell,
    cellClass: ["text-end", "b-cell-backgroupd-color-light-blue"],
    width: 200,
    headerClass: ["b-header-cell-center", "b-cell-backgroupd-color-light-blue"],
  });

  function cellRenderSelector(params: ICellRendererParams) {
    switch (params.column?.getColId()) {
      case "vessel":
        return { component: isVesselCell };
      case "ec":
        return { component: isEcCell };
      case "lcm":
        return { component: isLcmCell };
    }
  }

  // 年の合計対象かどうか
  function isTargetYear(data: OrderCostsGetResponse, year: number) {
    if (props.searchValues.showStartMonth === START_YEAR) {
      // 1月はじまりの場合
      if (data.showYear === year) {
        return true;
      }
      return false;
    } else {
      // 4月はじまりの場合
      if (data.showYear === year && data.showMonth >= 4) {
        return true;
      }
      if (data.showYear === year + 1 && data.showMonth < 4) {
        return true;
      }
    }
    return false;
  }

  // 合計列のセル定義
  function summanyColumnCell(params: ICellRendererParams) {
    let value = 0;
    let orderCount = 0;

    if (!params.data.sumFlg) {
      if (params.data.year && params.data.imoNo) {
        orderCostDataList
          .filter(
            (it) =>
              isTargetYear(it, params.data.year) &&
              ((params.data.imoNo !== IMO_ALL &&
                params.data.imoNo !== IMO_NO_VESSEL &&
                it.imo === params.data.imoNo) ||
                params.data.imoNo === IMO_ALL ||
                (params.data.imoNo === IMO_NO_VESSEL && it.imo === ""))
          )
          .forEach((data) => {
            value += data.replySubtotalPriceMonth;
            orderCount += data.orderCount;
          });
      }
    } else {
      orderCostDataList.forEach((data) => {
        value += data.replySubtotalPriceMonth;
        orderCount += data.orderCount;
      });
    }

    return (
      value.toLocaleString(undefined, {
        style: "currency",
        currency: "JPY",
      }) +
      "　(" +
      orderCount +
      "件)"
    );
  }

  // 船名のセル定義
  function isVesselCell(params: ICellRendererParams) {
    let vesselName = "";
    if (params.data.imoNo === IMO_ALL) {
      vesselName = "全船";
    } else if (params.data.imoNo === IMO_NO_VESSEL) {
      vesselName = "船舶無し";
    } else {
      const fineVessel = orderCostDataList.find(
        (it) => it.imo === params.data.imoNo
      );
      vesselName = fineVessel ? fineVessel.vesselName : "";
    }
    return vesselName;
  }

  // ECのセル定義
  function isEcCell(params: ICellRendererParams) {
    let value = 0;
    let orderCount = 0;

    if (!params.data.sumFlg) {
      if (params.data.year && params.data.imoNo) {
        orderCostDataList
          .filter(
            (it) =>
              isTargetYear(it, params.data.year) &&
              it.orderClassValue === OrderFactor.EC_PARTS &&
              ((params.data.imoNo !== IMO_ALL &&
                params.data.imoNo !== IMO_NO_VESSEL &&
                it.imo === params.data.imoNo) ||
                params.data.imoNo === IMO_ALL ||
                (params.data.imoNo === IMO_NO_VESSEL && it.imo === ""))
          )
          .forEach((data) => {
            value += data.replySubtotalPriceMonth;
            orderCount += data.orderCount;
          });
      }
    } else {
      orderCostDataList
        .filter((it) => it.orderClassValue === OrderFactor.EC_PARTS)
        .forEach((data) => {
          value += data.replySubtotalPriceMonth;
          orderCount += data.orderCount;
        });
    }

    return (
      value.toLocaleString(undefined, {
        style: "currency",
        currency: "JPY",
      }) +
      "　(" +
      orderCount +
      "件)"
    );
  }

  // LCMのセル定義
  function isLcmCell(params: ICellRendererParams) {
    let value = 0;
    let orderCount = 0;
    if (!params.data.sumFlg) {
      if (params.data.year && params.data.imoNo) {
        orderCostDataList
          .filter(
            (it) =>
              isTargetYear(it, params.data.year) &&
              it.orderClassValue !== OrderFactor.EC_PARTS &&
              ((params.data.imoNo !== IMO_ALL &&
                params.data.imoNo !== IMO_NO_VESSEL &&
                it.imo === params.data.imoNo) ||
                params.data.imoNo === IMO_ALL ||
                (params.data.imoNo === IMO_NO_VESSEL && it.imo === ""))
          )
          .forEach((data) => {
            value += data.replySubtotalPriceMonth;
            orderCount += data.orderCount;
          });
      }
    } else {
      orderCostDataList
        .filter((it) => it.orderClassValue !== OrderFactor.EC_PARTS)
        .forEach((data) => {
          value += data.replySubtotalPriceMonth;
          orderCount += data.orderCount;
        });
    }

    return (
      value.toLocaleString(undefined, {
        style: "currency",
        currency: "JPY",
      }) +
      "　(" +
      orderCount +
      "件)"
    );
  }

  // 表示値のスタイル
  function statusButtonStyle(status: number) {
    // 選択中のステータスと一致していたらオレンジにする
    if (status === selectedValue) {
      return { backgroundColor: "#FD6B00", color: "white" };
    } else {
      return { backgroundColor: "white", color: "black" };
    }
  }

  // 表示値チェックボックスクリック
  function onClickValueButton(status: number) {
    setSelectedValue(status);
  }

  //　CSV出力
  function exportCsvFile() {
    const queryParams: string[] = [];
    queryParams.push(`startYear=${searchValues.startYear}`);
    queryParams.push(`endYear=${searchValues.endYear}`);
    queryParams.push(`showStartMonth=${searchValues.showStartMonth}`);
    queryParams.push(
      `analyzedVesselSelect=${searchValues.analyzedVesselSelect}`
    );
    for (let j = 0; j < searchValues.imo?.length!; j++) {
      queryParams.push(`vesselList=${searchValues.imo[j]}`);
    }
    queryParams.push(
      `vesselNoOrNotContained=${searchValues.vesselNoOrNotContained}`
    );
    const query = queryParams.join("&");

    api
      .get(
        `/api/v1/companies/${
          !props.searchValues.companyCode ? -1 : props.searchValues.companyCode
        }/order-costs-csv?${query}`
      )
      .then((it) => {
        //文字化け回避
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const csv = new Blob([bom, it.data], {
          type: "text/csv",
        });
        //aタグ作成
        const a = document.createElement("a");
        // ダウンロードされるファイル名
        // 得意先検索
        if (searchValues.companyCode) {
          api
            .get(
              `/api/v1/companies/${props.searchValues.companyCode}?searchMode=0`
            )
            .then((response) => {
              let vesselNames = "";
              //undefined対策
              if (searchValues.imo === undefined) {
                searchValues.imo = [];
              }
              //船名を設定
              if (searchValues.analyzedVesselSelect === SELECT_VESSEL_LIST) {
                for (let i = 0; i < searchValues.imo.length; i++) {
                  vesselNames = vesselNames + "_" + searchValues.imo[i];
                }
                //船舶無しを含む場合
                if (searchValues.vesselNoOrNotContained) {
                  vesselNames = vesselNames + "_" + LABEL_NO_VESSEL;
                }
              } else {
                vesselNames = "_" + VESSEL_TOTAL;
              }

              let selectYear = "";
              //年か年度かを設定
              if (searchValues.showStartMonth === 1) {
                selectYear = START_MONTH_JAN;
              } else {
                selectYear = START_MONTH_APR;
              }

              // ダウンロードされるファイル名
              a.download =
                FILE_NAME_START +
                "_" +
                response.data.companyGetResponseSub1.companyName +
                vesselNames +
                "_" +
                searchValues.startYear +
                "～" +
                searchValues.endYear +
                selectYear +
                ".csv";

              a.href = URL.createObjectURL(csv);
              // ダウンロード開始
              a.click();
              splashMessage.show("I055");
            })
            .catch((error) => {
              if (getBizError(error)) {
                //対応するエラーメッセージを表示
                showDialog({ error });
              } else {
                showDialog({ id: "E066" });
              }
            });
        } else {
          let selectYear = "";
          //年か年度かを設定
          if (searchValues.showStartMonth === 1) {
            selectYear = START_MONTH_JAN;
          } else {
            selectYear = START_MONTH_APR;
          }

          // ダウンロードされるファイル名
          a.download =
            FILE_NAME_START +
            "_" +
            "全得意先" +
            "_全船" +
            "_" +
            searchValues.startYear +
            "～" +
            searchValues.endYear +
            selectYear +
            ".csv";

          a.href = URL.createObjectURL(csv);
          // ダウンロード開始
          a.click();
          splashMessage.show("I055");
        }
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E066" });
        }
      });
  }

  // 再検索時に実行
  useEffect(() => {
    //表示値は合計にフォーカスが当たるようにする
    setSelectedValue(0);
    // 検索内容が変わってなければ処理しない
    if (orderCostDataList === props.orderCostDataList) {
      return;
    } else {
      setOrderCostDataList(props.orderCostDataList);
      setSelectedYear(0);
    }
    // 表示年度の期間を設定
    if (props.searchValues.startYear && props.searchValues.endYear) {
      if (props.searchValues.startYear < props.searchValues.endYear) {
        const len: number =
          props.searchValues.endYear - props.searchValues.startYear + 1;
        setYearPeriod(
          new Array(len).fill(props.searchValues.startYear).map((n, i) => n + i)
        );
      } else if (props.searchValues.startYear === props.searchValues.endYear) {
        setYearPeriod([props.searchValues.startYear]);
      }
      setSearchValues(props.searchValues);
    }
  }, [props]);

  //グラフY軸の最大値を算出する
  function calcYearMaxPrice(yearOrMonth: String) {
    let maxPrice = 0;
    const dataList =
      yearOrMonth === "year" ? YearDataSetList() : MonthDataSetList();
    for (let i = 0; i < dataList.length; i++) {
      for (let j = 0; j < dataList[i].data.length; j++) {
        if (dataList[i].data[j] > maxPrice) {
          maxPrice = dataList[i].data[j];
        }
      }
    }
    return maxPrice;
  }

  // レンダリング
  return (
    <div className="overflow-auto h-100">
      <div style={{ marginTop: "24px", marginBottom: "10px" }}>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          注文推移　
        </span>
      </div>
      <div className="w-100 mx-auto my-1">
        {orderCostDataList?.length > 0 && yearPeriod.length > 0 && (
          <div className="w-100 mx-auto my-1">
            表示値
            <br />
            <div>
              {showValueList.map((it) => {
                return (
                  <input
                    key={it.value}
                    type="button"
                    className="btn rounded-pill me-2"
                    style={statusButtonStyle(it.value)}
                    value={it.text}
                    onClick={(e) => onClickValueButton(it.value)}
                    data-cy={`${it.text}ボタン`}
                  />
                );
              })}
            </div>
          </div>
        )}
        {!orderCostDataList ||
          (orderCostDataList?.length === 0 && (
            <div
              className="w-100 mx-auto my-1 text-center"
              data-cy="分析データ無メッセージ"
            >
              {TextConst.GRID_NO_DATA}
            </div>
          ))}
      </div>
      {orderCostDataList?.length > 0 && yearPeriod.length > 0 && (
        <div
          style={{
            width: orderCostDataList?.length * 50 + "px",
            minWidth: "500px",
            height: "350px",
            paddingTop: "10px",
            ...graphDivStyle,
          }}
        >
          <span
            className="b-input-group-text"
            style={{ width: "100%" }}
            data-cy="年別グラフラベル"
          >
            年別({searchValues.startYear}年～{searchValues.endYear}年)
          </span>
          <Bar
            ref={chartRef}
            options={barGraphOptions("year")}
            data={barYearGraphData()}
            onClick={onYearGraphClick}
            data-cy="年別グラフエリア"
          />
        </div>
      )}
      {selectedYear > 0 && (
        <div
          style={{
            width: 1000 + getGraphImoList().length * 100 + "px",
            height: "290px",
            paddingTop: "70px",
            ...graphDivStyle,
          }}
        >
          <span
            className="b-input-group-text"
            style={{ width: "100%" }}
            data-cy="月別グラフラベル"
          >
            月別({selectedYear}年)
          </span>
          <div style={{ height: "250px", width: monthGraphWidth() }}>
            <Bar
              options={barGraphOptions("month")}
              data={barMonthGraphData()}
              data-cy="月別グラフエリア"
            />
          </div>
        </div>
      )}
      {orderCostDataList?.length > 0 && yearPeriod.length > 0 && (
        <>
          <h5 style={{ paddingTop: "70px" }}>年別船別注文金額</h5>
          <div
            style={{ width: "920px" }}
            className={"ag-theme-alpine mx-0 position-relative"}
            data-cy="年別船別注文金額グリッド"
          >
            <AgGridReact
              domLayout="autoHeight"
              columnDefs={columnDefs}
              rowData={gridData}
              suppressRowTransform
            />
            <div className="my-3 text-end">
              <Button
                className="b-btn-primary me-3"
                onClick={exportCsvFile}
                data-cy="注文分析CSV出力ボタン"
              >
                CSV出力
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default OrderAnalysis;
