import dayjs from "dayjs";

import { UserInfo } from "../type/UserInfo";

const TOKEN_KEY = "token";
const AUTH_KEY = "auth";
const EXPIRATION_DATE_KEY = "expirationDate";
const DEFAULT_EXPIRATION_TIME = 4 * 60 * 60 * 1000; // 4時間

export function clearStorageLogin() {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(AUTH_KEY);
  localStorage.removeItem(EXPIRATION_DATE_KEY);
}

export function saveToken(token: string) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function loadToken(): string {
  return localStorage.getItem(TOKEN_KEY) ?? "";
}

export function saveUserInfo(user: UserInfo) {
  localStorage.setItem(AUTH_KEY, JSON.stringify(user));
}

export function hasUserInfo() {
  const json = localStorage.getItem(AUTH_KEY) ?? "";
  return json ? true : false;
}

export function loadUserInfo() {
  const json = localStorage.getItem(AUTH_KEY) ?? "";
  return JSON.parse(json) as UserInfo;
}

export function saveExpirationTime(value: string | undefined) {
  // 有効期限（ミリ秒）
  const expirationTime = value ? parseInt(value) : DEFAULT_EXPIRATION_TIME;
  // 現在日時に加算して、有効期限日時をエポックミリ秒で保持
  const expirationDate = dayjs().add(expirationTime, "milliseconds").valueOf();
  localStorage.setItem(EXPIRATION_DATE_KEY, expirationDate.toString());
}

export function isWithinExpirationDate() {
  const now = dayjs().valueOf();
  const expirationDateString = localStorage.getItem(EXPIRATION_DATE_KEY);
  const expirationDate = expirationDateString
    ? parseInt(expirationDateString)
    : 0;
  // 有効期限のほうが後であること
  return now < expirationDate;
}
