/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { FaAnchor } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";

import { UserVesselsGetResponse } from "../../model/api/response/UserVesselsGetResponse";
import Pagination from "./Pagination";

type Props = {
  userVesselList: UserVesselsGetResponse[]; //個船リスト
};

function OwnShipList(props: Props) {
  //クエリパラメータの取り出し
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const queryImoNo = query.get("imono") ?? ""; // IMO番号
  const queryPage = query.get("page") ?? "1"; //ページ数

  const [displayedVesselList, setDisplayedVesselList] = useState<
    UserVesselsGetResponse[]
  >([]); //ページに表示される船舶リスト
  const [selectedPage, setSelectedPage] = useState<number>(1); //選択中のページ

  const [vesselNameCondition, setVesselNameCondition] = useState(""); // 船名絞り込み条件
  const [filteredVesselList, setFilteredVesselList] = useState<
    UserVesselsGetResponse[]
  >(props.userVesselList); // 絞り込まれた船舶リスト

  //個船リスト全体のstyle
  const ownShipListStyle: React.CSSProperties = {
    position: "absolute",
    left: "-250px",
    width: "250px",
    height: "calc(100vh - 400px)",
    top: "290px",
  };

  //個船のページ未遷移時のcss
  const defaultVesselListCss = css`
    :hover {
      font-weight: 800;
    }
  `;

  //個船のページ遷移後のcss
  const selectedVesselListCss = css`
    :hover {
      opacity: 1;
      font-weight: 800;
    }
    :not(.label) {
      opacity: 0.3;
    }
  `;

  //個船クリック時の遷移先URLを決定する
  function decideUrl(imoNo: string) {
    if (imoNo === queryImoNo) {
      return "/";
    } else {
      return "/home?imono=" + imoNo + "&page=" + selectedPage;
    }
  }

  //ページ切り替え時に実行
  function handleChangePageIndex(
    vesselList: UserVesselsGetResponse[],
    currentPage: number
  ) {
    setDisplayedVesselList(vesselList);
    setSelectedPage(currentPage);
  }

  // 船名絞り込み
  function getFilteredVessels(vesselName: string) {
    if (!props.userVesselList) {
      return [];
    }
    return props.userVesselList.filter((vessel) => {
      return vessel.vesselName
        .toLocaleLowerCase()
        .includes(vesselName.toLocaleLowerCase());
    });
  }

  // 船名絞り込みテキスト変更
  function onChangeVesselNameCondition(e: ChangeEvent<HTMLInputElement>) {
    const text = e.target.value;
    setVesselNameCondition(text);

    const filteredList = getFilteredVessels(text);
    setFilteredVesselList(filteredList);
    setSelectedPage(1);
  }

  // もとの船舶リスト変更時
  useEffect(() => {
    const filteredList = getFilteredVessels(vesselNameCondition);
    setFilteredVesselList(filteredList);
  }, [props.userVesselList]);

  //船舶リストレンダリング
  return (
    <>
      <div className="pe-0" style={ownShipListStyle}>
        <input
          type="text"
          className="form-control"
          style={{ width: "90%", backgroundColor: "white" }}
          placeholder="Filter names"
          value={vesselNameCondition}
          onChange={onChangeVesselNameCondition}
        />
        <div className="h-100 overflow-auto">
          {displayedVesselList?.map(
            (it: UserVesselsGetResponse, index: number) => {
              return (
                <div key={it.imoNo} className="px-1">
                  <Nav.Link
                    className={
                      "ps-2 " + (it.imoNo === queryImoNo ? "label" : "")
                    }
                    as={NavLink}
                    id={it.imoNo}
                    css={
                      queryImoNo ? selectedVesselListCss : defaultVesselListCss
                    }
                    to={decideUrl(it.imoNo)}
                    style={{
                      color: "black",
                      whiteSpace: "pre-wrap",
                      display: "flex",
                    }}
                    data-cy="船舶リンク"
                  >
                    <span className="px-2">
                      <FaAnchor />
                    </span>
                    {it.vesselName}
                  </Nav.Link>
                </div>
              );
            }
          )}
        </div>
        <Pagination
          data={filteredVesselList}
          numPerPage={20}
          handleChangePageIndex={handleChangePageIndex}
          selectedPage={parseInt(queryPage)}
        />
      </div>
    </>
  );
}

export default OwnShipList;
