import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSplashMessage } from "src/hook/useSplashMessage";

import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { QuotationOrderHistoryGetResponse } from "../../model/api/response/QuotationOrderHistoryGetResponse";
import { agYmdFormatter } from "../../util/AgGridUtil";
import { formatOrderNo, formatQuotationNo } from "../../util/ecUtil";
import { getBizError } from "../../util/ecUtil";
import OrderDetail, { OrderDetailHandles } from "../order/OrderDetail";
import QuotationDetail, {
  QuotationDetailHandles,
} from "../order/QuotationDetail";

// 公開する関数の定義
export interface QuotationOrderHistoryHandles {
  show(quotationOrderNo: number, quotationNoFlg: boolean): void;
}

type QuotationOrderHistoryType = {
  quotationOrOrder: String;
  quotationOrderNo: number;
  history: String;
  companyName: String;
  customerUserName: String;
  requestDateTime: Date;
  replyDateTime: Date;
  bemacUserName: String;
};

/** コンポーネント */
const QuotationOrderHistory = forwardRef<QuotationOrderHistoryHandles>(
  (props, ref) => {
    //定数定義
    const FILE_NAME = "見積注文履歴";

    // state宣言
    const [show, setShow] = useState(false);

    // 見積注文履歴グリッドレコード
    const [gridQuotationOrderHistoryList, setGridQuotationOrderHistoryList] =
      useState<QuotationOrderHistoryType[] | null>([]);

    // 参照
    const quotationDetailRef = useRef<QuotationDetailHandles>(null);
    const orderDetailRef = useRef<OrderDetailHandles>(null);

    // API使用宣言
    const api = useApi();

    //スプラッシュメッセージ使用宣言
    const splashMessage = useSplashMessage();

    //ダイアログ仕様宣言
    const showDialog = useDialog();

    // 検索条件（コンボはundefinedが入る）
    type SearchValueType = {
      quotationOrderNo: number | undefined;
      quotationNoFlg: boolean | undefined;
    };
    const [searchValues, setSearchValues] = useState<SearchValueType>({
      quotationOrderNo: undefined,
      quotationNoFlg: undefined,
    });

    // 公開する関数の実装
    useImperativeHandle(ref, () => ({
      show(quotationOrderNo: number, quotationNoFlg: boolean) {
        const gridDataList: QuotationOrderHistoryType[] = [];
        setShow(true);
        setGridQuotationOrderHistoryList(gridDataList);
        api
          .get(
            `/api/v1/quotation-order-histories/${quotationOrderNo}?quotationNoFlg=${quotationNoFlg}`
          )
          .then((response) => {
            const history = response.data.map(
              (row: QuotationOrderHistoryGetResponse) => {
                return row;
              }
            );
            setGridQuotationOrderHistoryList(history);
          })
          .catch((error) => {
            if (getBizError(error)) {
              //対応するエラーメッセージを表示
              showDialog({ error });
            } else {
              showDialog({ id: "E073" });
            }
          });
      },
    }));

    // 詳細画面保存ボタンクリック
    function handleClickSaveButton() {
      // 再検索
      search();
    }

    // 見積注文番号列レンダラ
    const cellRenderer = (params: ICellRendererParams) => {
      if (params.data.quotationOrOrder === "見積") {
        return (
          <a
            href="#"
            className="text-link"
            onClick={() => onClickQuotationLink(params.value)}
          >
            {formatQuotationNo(params.value)}
          </a>
        );
      } else {
        return (
          <a
            href="#"
            className="text-link"
            onClick={() => onClickOrderLink(params.value)}
          >
            {formatOrderNo(params.value)}
          </a>
        );
      }
    };

    //見積番号リンク押下時の処理
    function onClickQuotationLink(quotationNo: number) {
      const quotationOrderNo = quotationNo;
      const quotationNoFlg = true;
      setSearchValues({ ...searchValues, quotationOrderNo, quotationNoFlg });
      // 該当の見積が存在するか確認
      // 見積番号が設定されていたらAPIから初期化する
      api
        .get(`/api/v1/quotations/${quotationNo}`)
        .then((response) => {
          quotationDetailRef.current?.show({
            quotationNo: quotationNo,
          });
        })
        .catch((error) => {
          // エラー時の処理
          if (error.response.status === 404) {
            //エラーメッセージの表示
            showDialog({ id: "E077" });
          } else {
            showDialog({ error });
          }
        });
    }

    //注文番号リンク押下時の処理
    function onClickOrderLink(orderNo: number) {
      const quotationOrderNo = orderNo;
      const quotationNoFlg = false;
      setSearchValues({ ...searchValues, quotationOrderNo, quotationNoFlg });
      // 該当の注文が存在するか確認
      // 見積番号が設定されていたらAPIから初期化する
      api
        .get(`/api/v1/orders/${orderNo}`)
        .then((response) => {
          orderDetailRef.current?.show({ orderNo: orderNo });
        })
        .catch((error) => {
          // エラー時の処理
          if (error.response.status === 404) {
            //エラーメッセージの表示
            showDialog({ id: "E078" });
          } else {
            showDialog({ error });
          }
        });
    }

    // 検索処理
    function search(
      afterProc?: (data: QuotationOrderHistoryGetResponse[]) => void
    ) {
      const quotationOrderNo = searchValues.quotationOrderNo;
      const quotationNoFlg = searchValues.quotationNoFlg;
      api
        .get(
          `/api/v1/quotation-order-histories/${quotationOrderNo}?quotationNoFlg=${quotationNoFlg}`
        )
        .then((response) => {
          // 検索結果をセット
          setGridQuotationOrderHistoryList(response.data);
          // 後処理が指定されていたら実行
          if (afterProc) {
            afterProc(response.data);
          }
        })
        .catch((error) => {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          } else {
            showDialog({ id: "E073" });
          }
        });
    }

    // モーダルクローズハンドラ
    const handleClose = () => {
      setShow(false);
    };

    // 見積注文履歴グリッドの列定義
    const columnDefs: ColDef[] = [
      {
        headerName: "種別",
        field: "quotationOrOrder",
        width: 100,
        resizable: true,
        lockPosition: "left",
        headerClass: [
          "b-cell-border-right-solid-thin-gray",
          "b-header-cell-center",
        ],
        colId: "quotationOrOrder",
        cellClass: ["b-cell-border-right-solid-thin-gray", "text-start"],
      },
      {
        headerName: "見積注文番号",
        field: "quotationOrderNo",
        width: 130,
        resizable: true,
        lockPosition: "left",
        headerClass: [
          "b-cell-border-right-solid-thin-gray",
          "b-header-cell-center",
        ],
        colId: "quotationOrderNo",
        cellClass: ["b-cell-border-right-solid-thin-gray", "text-start"],
        cellRenderer: cellRenderer,
      },
      {
        headerName: "履歴",
        field: "history",
        width: 110,
        resizable: true,
        lockPosition: "left",
        headerClass: [
          "b-cell-border-right-solid-thin-gray",
          "b-header-cell-center",
        ],
        colId: "history",
        cellClass: ["b-cell-border-right-solid-thin-gray", "text-start"],
      },
      {
        headerName: "得意先名",
        field: "companyName",
        width: 140,
        resizable: true,
        lockPosition: "left",
        headerClass: [
          "b-header-cell-center",
          "b-cell-border-right-solid-thin-gray",
        ],
        colId: "companyName",
        cellClass: ["b-cell-border-right-solid-thin-gray", "text-start"],
      },
      {
        headerName: "顧客担当者",
        field: "customerUserName",
        width: 140,
        resizable: true,
        lockPosition: "left",
        headerClass: [
          "b-cell-border-right-solid-thin-gray",
          "b-header-cell-center",
        ],
        colId: "customerUserName",
        cellClass: ["b-cell-border-right-solid-thin-gray", "text-start"],
      },
      {
        headerName: "見積依頼・発注日",
        field: "requestDateTime",
        width: 160,
        resizable: true,
        lockPosition: "left",
        headerClass: [
          "b-cell-border-right-solid-thin-gray",
          "b-header-cell-center",
        ],
        colId: "requestDateTime",
        cellClass: ["b-cell-border-right-solid-thin-gray", "text-start"],
        valueFormatter: agYmdFormatter,
      },
      {
        headerName: "回答日",
        field: "replyDateTime",
        width: 160,
        resizable: true,
        lockPosition: "left",
        headerClass: [
          "b-cell-border-right-solid-thin-gray",
          "b-header-cell-center",
        ],
        colId: "replyDateTime",
        cellClass: ["b-cell-border-right-solid-thin-gray", "text-start"],
        valueFormatter: agYmdFormatter,
      },
      {
        headerName: "BEMAC担当者",
        field: "bemacUserName",
        width: 140,
        resizable: true,
        lockPosition: "left",
        headerClass: [
          "b-cell-border-right-solid-thin-gray",
          "b-header-cell-center",
        ],
        colId: "bemacUserName",
        cellClass: ["b-cell-border-right-solid-thin-gray", "text-start"],
      },
    ];

    // モーダルbody部レンダリング
    function modalBody() {
      return (
        <>
          <div
            className="ag-theme-alpine w-100 mx-auto b-grid-outer"
            style={{ height: 450 }}
            data-cy="見積注文履歴グリッド"
          >
            <AgGridReact
              columnDefs={columnDefs}
              rowData={gridQuotationOrderHistoryList}
              alwaysShowHorizontalScroll={true}
            />
          </div>
        </>
      );
    }

    // モーダルfooter部レンダリング
    function modalFooter() {
      return (
        <>
          <Button
            className="b-btn-close"
            onClick={handleClose}
            data-cy="Closeボタン"
          >
            Close
          </Button>
        </>
      );
    }

    // レンダリング
    return (
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        //scrollable
        data-cy="見積注文履歴モーダル"
      >
        <Modal.Header closeButton>
          <Modal.Title>見積～注文履歴</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody()}</Modal.Body>
        <Modal.Footer>{modalFooter()}</Modal.Footer>
        <QuotationDetail
          ref={quotationDetailRef}
          onClickSaveButton={handleClickSaveButton}
          quotationOrderHistoryFlg={true}
        />
        <OrderDetail
          ref={orderDetailRef}
          onClickSaveButton={handleClickSaveButton}
          quotationOrderHistoryFlg={true}
        />
      </Modal>
    );
  }
);

export default QuotationOrderHistory;
