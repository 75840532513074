import {
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { HiOutlineTrash } from "react-icons/hi";

import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { useSplashMessage } from "../../hook/useSplashMessage";
import { CompanyGroupsPostRequest } from "../../model/api/request/CompanyGroupsPostRequest";
import { CompanyVesselsPostRequest } from "../../model/api/request/CompanyVesselsPostRequest";
import { CompanyGetResponseSub1 } from "../../model/api/response/CompanyGetResponseSub1";
import { CompanyGetResponseSub2 } from "../../model/api/response/CompanyGetResponseSub2";
import { CompanyGetResponseSub3 } from "../../model/api/response/CompanyGetResponseSub3";
import { VesselGetResponse } from "../../model/api/response/VesselGetResponse";
import { defaultColDef } from "../../util/AgGridUtil";
import { TextConst } from "../../util/Constant";
import TooltipIcon from "../common/TooltipIcon";

// 公開する関数の定義
export interface CompanyDetailHandles {
  show(companyCode: string | undefined, browseFlg: boolean | undefined): void;
}
// 管理船舶一覧の明細データの型
export type manageShipList = {
  key: number;
  imoNo?: string;
  vesselName?: string;
  manageShipUpdateDateTime?: Date;
  canDelete?: boolean;
  messageId?: string;
};

// 子会社一覧の明細データの型
export type subsidiaryList = {
  key: number;
  childCompanyCode?: string;
  childCompanyName?: string;
  companyGroupUpdateDateTime?: Date;
  canDelete?: boolean;
  messageId?: string;
};

//主処理
const CompanyDetail = forwardRef<CompanyDetailHandles>((props, ref) => {
  //定数宣言

  //商社でない
  const COMPANY = "得意先";

  //商社
  const TRADING = "商社";

  //商社でない
  const TRADING_NOT = "商社でない";

  //管理船舶、子会社一覧の更新可能
  const BTN_UPDATE_CAN = "1";

  //管理船舶の更新中
  const BTN_MANERGE_SHIP_UPDATE_DOING = "2";

  //子会社の更新中
  const BTN_CHILD_COMPANY_UPDATE_DOING = "3";

  //管理船舶、子会社一覧の更新不可
  const BTN_UPDATE_NOT = "4";

  //テキストボックス入力可
  const TXT_CLASS_EDITABLE = "b-editable-cell";

  //テキストボックス入力不可
  const TXT_CLASS_UNEDIT = "";

  // state宣言
  const [show, setShow] = useState(false);

  //得意先コード
  const [companyCode, setCompanyCode] = useState<string | undefined>();

  //得意先データ
  let [companyData, setCompanyData] = useState<
    CompanyGetResponseSub1 | undefined
  >();

  //管理船舶データ
  let [companyManergeShip, setCompanyManergeShip] = useState<
    CompanyGetResponseSub2[] | undefined
  >();

  //子会社データ
  let [childCompany, setChildCompany] = useState<
    CompanyGetResponseSub3[] | undefined
  >();

  //管理船舶更新日
  let [manageShipUpdateDateTime, setManageShipUpdateDateTime] = useState<
    Date | undefined
  >();

  //子会社更新日
  let [companyGroupUpdateDateTime, setCompanyGroupUpdateDateTime] = useState<
    Date | undefined
  >();

  //管理船舶明細用画面独自項目
  let [manageShipDatas, setManergeShipDatas] = useState<manageShipList[]>([]);

  //子会社明細用画面独自項目
  let [subsidiaryDatas, setSubsidiarDatas] = useState<subsidiaryList[]>([]);

  // 管理船舶明細0件時のテキスト
  let [noRowsTextManergeShip, setNoRowsTextManergeShip] = useState(
    TextConst.GRID_NO_DATA
  );

  //IMOのテキストクラス
  let [imoTxt, setImoTxt] = useState<string>(TXT_CLASS_UNEDIT);

  // 子会社明細用0件時のテキスト
  let [noRowsTextChildCompany, setNoRowsTextChildCompany] = useState(
    TextConst.GRID_NO_DATA
  );

  //子得意先コードのテキストクラス
  let [childCompanyCodeTxt, setChildCompanyCodeTxt] =
    useState<string>(TXT_CLASS_UNEDIT);

  //商社
  const [trading, setTrading] = useState<string | undefined>();

  // 参照
  const gridRefManageShip = useRef<AgGridReact>(null);
  const gridRefChildCompany = useRef<AgGridReact>(null);

  // ヘッダ部見出しの幅
  const itemTitleStyle = { width: "13rem" };
  // エリアごとのCSSクラス
  const areaClass = "p-1 bg-white b-detail-items";

  // API使用宣言
  const api = useApi();

  //ダイアログ仕様宣言
  const showDialog = useDialog();
  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();

  //管理船舶更新ボタンフラグ
  let [browseFlg, setBrowseFlg] = useState<boolean>();

  //管理船舶更新ボタンフラグ
  let [btnManergeShip, setBtnManergeShip] = useState<string>(BTN_UPDATE_NOT);

  //子会社更新ボタンフラグ
  let [btnChildCompany, setBtnChildCompany] = useState<string>(BTN_UPDATE_NOT);

  //子会社更新フラグ
  let [flgChildCompany, setFlgChildCompany] = useState<boolean>(false);

  //セル編集フラグ
  let [flgCellEdit, setFlgCellEdit] = useState<boolean>(false);

  //更新ボタン押下フラグ
  let [flgUpdate, setFlgUpdate] = useState<boolean>(false);

  // 画面初期化
  useImperativeHandle(ref, () => ({
    show(companyCode: string | undefined, browseFlg: boolean | undefined) {
      // 初期化
      setBrowseFlg(browseFlg);
      //管理船舶、子会社更新関連ボタンの初期化
      if (browseFlg) {
        //閲覧モード 管理船舶、子会社更新不可
        setBtnManergeShip(BTN_UPDATE_NOT);
        setBtnChildCompany(BTN_UPDATE_NOT);
      } else {
        // 管理船舶、子会社更新可能
        setBtnManergeShip(BTN_UPDATE_CAN);
        setBtnChildCompany(BTN_UPDATE_CAN);
      }
      //編集中得意先コードの取得
      setCompanyCode(companyCode);
      //画面表示項目の編集
      getCompanyData(companyCode);
      setShow(true);
    },
  }));

  // 得意先データ取得
  function getCompanyData(companyCode: string | undefined) {
    // 得意先単一取得API実行
    api
      .get(`/api/v1/companies/${companyCode}?searchMode=3`)
      .then((it) => {
        // 得意先情報の取得
        const companyGetResponseSub1: CompanyGetResponseSub1 =
          it.data?.companyGetResponseSub1;
        // 管理船舶一覧の取得
        const companyGetResponseSub2: CompanyGetResponseSub2[] =
          it.data?.companyGetResponseSub2;
        // 子会社一覧の取得
        const companyGetResponseSub3: CompanyGetResponseSub3[] =
          it.data?.companyGetResponseSub3;
        //商社の設定
        setTradingLabel(companyGetResponseSub1.tradingFlg);
        //IMOテキストタイプ設定
        setImoTxt(TXT_CLASS_UNEDIT);
        //子得意先コードテキストタイプ設定
        setChildCompanyCodeTxt(TXT_CLASS_UNEDIT);

        //得意先情報を設定
        if (companyGetResponseSub1 !== undefined) {
          setCompanyData(companyGetResponseSub1);
        }

        //管理船舶データの保持
        setCompanyManergeShip(companyGetResponseSub2);
        // 管理船舶明細編集
        const vessleRows: manageShipList[] = [];
        companyGetResponseSub2?.forEach((it, index) => {
          vessleRows.push({
            key: index + 1,
            imoNo: it.imoNo,
            vesselName: it.vesselName,
            manageShipUpdateDateTime: it.manageShipUpdateDateTime,
            canDelete: true,
            messageId: undefined,
          });
        });
        //管理船舶一覧にデータセット
        setManergeShipDatas(vessleRows);
        //管理船舶の更新日を設定、明細0件の時メッセージ表示
        if (0 === companyGetResponseSub2.length) {
          setManageShipUpdateDateTime(undefined);
          setNoRowsTextManergeShip(TextConst.GRID_NO_DATA);
        } else {
          setManageShipUpdateDateTime(
            companyGetResponseSub2[0].manageShipUpdateDateTime
          );
        }
        //子会社データの保持
        setChildCompany(companyGetResponseSub3);
        // 子会社明細編集
        const childRows: subsidiaryList[] = [];
        companyGetResponseSub3?.forEach((it, index) => {
          childRows.push({
            key: index + 1,
            childCompanyCode: it.childCompanyCode,
            childCompanyName: it.childCompanyName,
            companyGroupUpdateDateTime: it.companyGroupUpdateDateTime,
            canDelete: true,
            messageId: undefined,
          });
        });
        //子会社一覧にデータセット
        setSubsidiarDatas(childRows);
        //子会社更新日を設定、明細0件の時メッセージ表示
        if (0 === companyGetResponseSub3.length) {
          setCompanyGroupUpdateDateTime(undefined);
          setNoRowsTextChildCompany(TextConst.GRID_NO_DATA);
        } else {
          setCompanyGroupUpdateDateTime(
            companyGetResponseSub3[0].companyGroupUpdateDateTime
          );
        }
        // 削除された得意先の場合、メッセージを表示しておく
        if (companyGetResponseSub1.delFlg) {
          showDialog({
            id: "I056",
            args: [COMPANY],
            confirm: false,
          });
        }
      })
      .catch((error) => {
        // エラー時の処理
        if (error.response.status !== 404) {
          showDialog({ error });
        }
      });
  }
  // 商社/商社でないの設定
  function setTradingLabel(tradingFlg: boolean) {
    if (tradingFlg) {
      setTrading(TRADING);
    } else {
      setTrading(TRADING_NOT);
    }
  }
  //-----------------------------------管理船舶一覧---------------------------------------------------------
  // 管理船舶の更新はこちらボタン押下
  function onClickManageShip(btnFlg: string) {
    //ボタンステータス変更
    changeBtnStatus(btnFlg);
    //IMOテキストタイプ設定
    setImoTxt(TXT_CLASS_EDITABLE);
    // 最終行を追加
    let maxKey = Math.max(...manageShipDatas.map((it) => it.key));
    if (maxKey === -Infinity) {
      maxKey = 0;
    }
    //管理船舶一覧にデータセット
    setManergeShipDatas([...manageShipDatas, { key: maxKey + 1 }]);
  }

  //管理船舶グリッドのセル値変更開始
  const onCellManergeShipEditingStarted = (event: CellEditingStartedEvent) => {
    setFlgCellEdit(true);
    setFlgUpdate(false);
  };

  //管理船舶グリッドのセル値変更終了
  const onCellManergeShipEditingStopped = (event: CellEditingStoppedEvent) => {
    setFlgCellEdit(false);
    const maxKey = Math.max(...manageShipDatas.map((it) => it.key));
    const rowNo = event.rowIndex;
    if (rowNo === null) {
      return;
    }
    //入力したIMO
    const inputImoNo = manageShipDatas[rowNo].imoNo;
    if (inputImoNo?.trim() === "" || inputImoNo?.trim() === undefined) {
      //IMOを消したらその行の値を初期化する
      manageShipDatas[rowNo].imoNo = "";
      manageShipDatas[rowNo].vesselName = "";
      manageShipDatas[rowNo].messageId = undefined;
      setManergeShipDatas([...manageShipDatas]);
      //更新ボタンが押下されていた場合、更新処理移行を行う
      if (flgUpdate) {
        //更新確認ダイアログを表示
        setFlgUpdate(false);
        showDialog({
          id: "I004",
          confirm: true,
          callback(isOk) {
            if (isOk) {
              // OKが押された場合、更新処理を実施する
              updateManergeShip();
            }
          },
        });
      }
      return;
    }
    // 管理船舶一覧の明細取得
    let messageId = "";
    let errKey = 0;
    manageShipDatas?.forEach((it, index) => {
      //IMOの重複チェック
      if (inputImoNo === it?.imoNo && rowNo + 1 !== it.key) {
        //入力した行の情報を書き換える
        manageShipDatas[rowNo].vesselName = "";
        manageShipDatas[rowNo].messageId = "E036";
        setManergeShipDatas([...manageShipDatas]);
        //エラーメッセージの表示
        messageId = "E036";
        errKey = manageShipDatas[rowNo].key;
        return;
      }
    });
    //エラーメッセージを表示する
    if (messageId !== "") {
      showDialog({
        id: messageId,
        args: [errKey],
        confirm: false,
      });
      return;
    }

    // 船舶単一取得API実行
    api
      .get(`/api/v1/vessels/${inputImoNo}`)
      .then((it) => {
        // 船舶の取得
        const vesselGetResponse: VesselGetResponse = it.data;
        //入力した行の情報を書き換える
        manageShipDatas[rowNo].imoNo = vesselGetResponse.imoNo;
        manageShipDatas[rowNo].vesselName = vesselGetResponse.vesselName;
        manageShipDatas[rowNo].canDelete = true;
        manageShipDatas[rowNo].messageId = undefined;
        if (rowNo + 1 === maxKey) {
          // 最終行で入力された場合、新たに入力行を追加
          setManergeShipDatas([...manageShipDatas, { key: maxKey + 1 }]);
        } else {
          setManergeShipDatas([...manageShipDatas]);
        }
        //更新ボタンが押下されていた場合、更新処理移行を行う
        if (flgUpdate) {
          //更新確認ダイアログを表示
          setFlgUpdate(false);
          showDialog({
            id: "I004",
            confirm: true,
            callback(isOk) {
              if (isOk) {
                // OKが押された場合、更新処理を実施する
                updateManergeShip();
              }
            },
          });
        }
      })
      .catch((error) => {
        // エラー時の処理
        if (error.response.status === 404) {
          //入力した行の船名を書き換える
          manageShipDatas[rowNo].vesselName = "";
          manageShipDatas[rowNo].messageId = "E035";
          setManergeShipDatas([...manageShipDatas]);
          //エラーメッセージの表示
          showDialog({
            id: "E035",
            args: [manageShipDatas[rowNo].key],
            confirm: false,
          });
        } else if (error.response.status !== 404) {
          showDialog({ error });
        }
      });
  };

  // 管理船舶一覧更新確認ダイアログ
  function updateManergeShipDialog() {
    if (flgCellEdit) {
      //セル編集中に更新ボタン押下された場合、onChangeイベントを実行する。
      setFlgUpdate(true);
      gridRefManageShip.current?.api.stopEditing();
    } else {
      //更新確認ダイアログを表示
      setFlgUpdate(false);
      showDialog({
        id: "I004",
        confirm: true,
        callback(isOk) {
          if (isOk) {
            // OKが押された場合、更新処理を実施する
            updateManergeShip();
          }
        },
      });
    }
  }

  // 管理船舶一覧更新
  function updateManergeShip() {
    // 最終行番号を取得
    let messageId = "";
    let errKey = 0;

    // 管理船舶一覧の明細取得
    const manageShipRows: CompanyGetResponseSub2[] = [];
    manageShipDatas?.forEach((it, index) => {
      //エラーメッセージが発生していた場合、エラーメッセージを表示して処理を終了する
      if (it.messageId !== undefined) {
        messageId = it.messageId;
        errKey = it.key;
        return;
      }
      //空行を無視する
      if (it.imoNo !== "" && it.imoNo !== undefined) {
        manageShipRows.push({
          imoNo: it?.imoNo,
          vesselName: it?.vesselName,
          manageShipUpdateDateTime: manageShipUpdateDateTime,
        });
      }
    });
    //明細にエラーが残ってい他場合はエラーメッセージを表示する
    if (messageId !== "") {
      showDialog({
        id: messageId,
        args: [errKey],
        confirm: false,
      });
      return;
    }
    //明細が0件（登録内容削除の場合）
    if (manageShipRows.length === 0) {
      manageShipRows.push({
        imoNo: "",
        vesselName: "",
        manageShipUpdateDateTime: manageShipUpdateDateTime,
      });
    }
    //リクエストの作成
    const request: CompanyVesselsPostRequest = {
      companyUpdateDateTime: companyData?.companyUpdateDateTime,
      companyVesselsPostRequestSub: manageShipRows,
    };
    // 得意先船舶登録API実行
    api
      .post(`/api/v1/companies/${companyCode}/vessels`, request)
      .then((it) => {
        //結果を取得
        const companyUpdateDateTime: Date = it.data?.companyUpdateDateTime;
        const companyVesselsPostRequestSub: CompanyGetResponseSub2[] =
          it.data?.companyVesselsPostRequestSub;

        setCompanyManergeShip(companyVesselsPostRequestSub);
        // 管理船舶明細編集
        const vessleRows: manageShipList[] = [];
        companyVesselsPostRequestSub?.forEach((it, index) => {
          vessleRows.push({
            key: index + 1,
            imoNo: it.imoNo,
            vesselName: it.vesselName,
            manageShipUpdateDateTime: it.manageShipUpdateDateTime,
            canDelete: true,
            messageId: undefined,
          });
        });
        // 明細を更新
        setManergeShipDatas(vessleRows);
        //ボタンステータス変更
        changeBtnStatus(BTN_UPDATE_CAN);
        //IMOテキストタイプ設定
        setImoTxt(TXT_CLASS_UNEDIT);
        //明細0件の時、メッセージ表示
        if (0 === companyVesselsPostRequestSub.length) {
          setManageShipUpdateDateTime(undefined);
          setNoRowsTextManergeShip(TextConst.GRID_NO_DATA);
        } else {
          setManageShipUpdateDateTime(
            companyVesselsPostRequestSub[0].manageShipUpdateDateTime
          );
        }
        //得意先マスタの更新日時を最新に
        if (companyData !== undefined) {
          companyData.companyUpdateDateTime = companyUpdateDateTime;
        }
        splashMessage.show("I046");
      })
      .catch((error) => {
        // エラー時の処理
        if (error.response.status === 404) {
          showDialog({ id: "E051" });
        } else {
          showDialog({ error });
        }
      });
  }

  // 管理船舶行削除
  function onClickManergeShipDeleteButton(key: number) {
    showDialog({
      id: "I030",
      confirm: true,
      callback(isOk) {
        if (isOk) {
          // OKが押された場合、自身の行を削除する
          const shipRowsData: manageShipList[] = manageShipDatas
            .filter((it) => it.key !== key)
            .map((it) => {
              return it;
            });
          //行番号をふり直す
          const shipRows: manageShipList[] = [];
          shipRowsData.forEach((it, index) => {
            shipRows.push({
              key: index + 1,
              imoNo: it.imoNo,
              vesselName: it.vesselName,
              manageShipUpdateDateTime: it.manageShipUpdateDateTime,
              canDelete: shipRowsData.length !== index + 1, //最終行の削除ボタンを非表示に,
              messageId: it.messageId,
            });
          });
          setManergeShipDatas(shipRows);
        }
      },
    });
  }

  // 管理船舶の更新を辞めるボタン押下
  function onClickManageShipCancel(btnFlg: string) {
    showDialog({
      id: "I001",
      confirm: true,
      callback(isOk) {
        if (isOk) {
          //管理船舶一覧とボタンステータス変更
          changeManageShipCancel(btnFlg);
        }
      },
    });
  }
  // 管理船舶一覧とボタンステータス変更
  function changeManageShipCancel(btnFlg: string) {
    //ボタンステータス変更
    changeBtnStatus(btnFlg);
    //IMOテキストタイプ設定
    setImoTxt(TXT_CLASS_UNEDIT);
    // 管理船舶明細編集
    const vessleRows: manageShipList[] = [];
    companyManergeShip?.forEach((it, index) => {
      vessleRows.push({
        key: index + 1,
        imoNo: it.imoNo,
        vesselName: it.vesselName,
        manageShipUpdateDateTime: it.manageShipUpdateDateTime,
        canDelete: true,
        messageId: undefined,
      });
    });
    setManergeShipDatas([...vessleRows]);
    //明細0件の時、メッセージ表示
    if (0 === manageShipDatas.length) {
      setManageShipUpdateDateTime(undefined);
      setNoRowsTextManergeShip(TextConst.GRID_NO_DATA);
    } else {
      setManageShipUpdateDateTime(manageShipDatas[0].manageShipUpdateDateTime);
    }
  }
  //-----------------------------------子会社一覧---------------------------------------------------------
  // 子会社の更新はこちらボタン押下
  function onClicChildCompany(btnFlg: string) {
    //ボタンステータス変更
    changeBtnStatus(btnFlg);
    //子得意先コードテキストタイプ設定
    setChildCompanyCodeTxt(TXT_CLASS_EDITABLE);
    // 最終行を追加
    let maxKey = Math.max(...subsidiaryDatas.map((it) => it.key));
    if (maxKey === -Infinity) {
      maxKey = 0;
    }
    setSubsidiarDatas([...subsidiaryDatas, { key: maxKey + 1 }]);
  }
  //子会社一覧グリッドのセル値変更開始
  const onCellSubsidiarEditingStarted = (event: CellEditingStartedEvent) => {
    setFlgCellEdit(true);
    setFlgUpdate(false);
  };

  // 子会社一覧グリッドのセル値変更イベント
  const onCellSubsidiarValueEditingStopped = (
    event: CellEditingStoppedEvent
  ) => {
    setFlgCellEdit(false);
    const maxKey = Math.max(...subsidiaryDatas.map((it) => it.key));
    const rowNo = event.rowIndex;
    if (rowNo === null) {
      return;
    }
    //入力した得意先コード
    const inputCompanyCode = subsidiaryDatas[rowNo].childCompanyCode;
    //得意先の未入力は行の値を初期化する
    if (
      inputCompanyCode?.trim() === "" ||
      inputCompanyCode?.trim() === undefined
    ) {
      subsidiaryDatas[rowNo].childCompanyCode = "";
      subsidiaryDatas[rowNo].childCompanyName = "";
      subsidiaryDatas[rowNo].messageId = undefined;
      setSubsidiarDatas([...subsidiaryDatas]);
      //更新ボタンが押下されていた場合、更新処理移行を行う
      if (flgUpdate) {
        //新規に子会社一覧を追加していたらメッセージ変更
        let messageId = "I004";
        if (flgChildCompany) {
          messageId = "I032";
        }
        //更新確認ダイアログを表示
        setFlgUpdate(false);
        showDialog({
          id: messageId,
          confirm: true,
          callback(isOk) {
            if (isOk) {
              // OKが押された場合、更新処理を実施する
              updateChildCompany();
            }
          },
        });
      }
      return;
    } else if (inputCompanyCode === companyCode) {
      //編集中の得意先コードを入力した場合、エラー
      //入力した行の情報を書き換える
      subsidiaryDatas[rowNo].childCompanyName = "";
      subsidiaryDatas[rowNo].messageId = "E060";
      setSubsidiarDatas([...subsidiaryDatas]);
      showDialog({ id: "E060", args: [rowNo + 1], confirm: false });
      return;
    }
    //得意先コードの重複チェック
    let messageId = "";
    let errKey = 0;
    subsidiaryDatas?.forEach((it, index) => {
      if (inputCompanyCode === it?.childCompanyCode && rowNo + 1 !== it.key) {
        //入力した行の情報を書き換える
        subsidiaryDatas[rowNo].childCompanyName = "";
        subsidiaryDatas[rowNo].messageId = "E039";
        setSubsidiarDatas([...subsidiaryDatas]);
        //エラーメッセージ表示
        messageId = "E039";
        errKey = subsidiaryDatas[rowNo].key;
        return;
      }
    });
    //エラーメッセージを表示する
    if (messageId !== "") {
      showDialog({
        id: messageId,
        args: [errKey],
        confirm: false,
      });
      return;
    }

    // 得意先単一取得API実行
    api
      .get(`/api/v1/companies/${inputCompanyCode}?searchMode=2`)
      .then((it) => {
        // 得意先情報の取得
        const companyGetResponseSub1: CompanyGetResponseSub1 =
          it.data?.companyGetResponseSub1;
        // 子会社一覧の取得
        const companyGetResponseSub3: CompanyGetResponseSub3[] =
          it.data?.companyGetResponseSub3;
        let errorMessage = undefined;
        // 親会社判定
        companyGetResponseSub3.forEach((it, index) => {
          if (companyCode === it.childCompanyCode) {
            //入力した行の情報を書き換える
            subsidiaryDatas[rowNo].childCompanyName = "";
            subsidiaryDatas[rowNo].messageId = "E038";
            errorMessage = "E038";
            setSubsidiarDatas([...subsidiaryDatas]);
            //エラーメッセージの表示
            showDialog({
              id: "E038",
              args: [rowNo + 1],
              confirm: false,
            });
            return;
          }
        });
        //入力した行の情報を書き換える
        subsidiaryDatas[rowNo].childCompanyCode =
          companyGetResponseSub1.companyCode;
        subsidiaryDatas[rowNo].childCompanyName =
          companyGetResponseSub1.companyName;
        subsidiaryDatas[rowNo].canDelete = true;
        subsidiaryDatas[rowNo].messageId = errorMessage;
        // 最終行を追加
        if (rowNo + 1 === maxKey) {
          setSubsidiarDatas([...subsidiaryDatas, { key: maxKey + 1 }]);
        } else {
          setSubsidiarDatas([...subsidiaryDatas]);
        }
        //更新フラグオン
        setFlgChildCompany(true);
        //更新ボタンが押下されていた場合、更新処理移行を行う
        if (flgUpdate) {
          let messageId = "";
          let errKey = 0;
          // 子会社一覧の明細取得
          const childCompanyRows: CompanyGetResponseSub3[] = [];
          subsidiaryDatas?.forEach((it, index) => {
            //エラーメッセージが発生していた場合、エラーメッセージを表示して処理を終了する
            if (it.messageId !== undefined) {
              messageId = it.messageId;
              errKey = it.key;
              return;
            }
            //空行を無視する
            if (
              it.childCompanyCode !== "" &&
              it.childCompanyCode !== undefined
            ) {
              childCompanyRows.push({
                childCompanyCode: it?.childCompanyCode,
                childCompanyName: it?.childCompanyName,
                companyGroupUpdateDateTime: companyGroupUpdateDateTime,
              });
            }
          });
          //明細にエラーが残っていた場合はエラーメッセージを表示する
          if (messageId !== "") {
            showDialog({
              id: messageId,
              args: [errKey],
              confirm: false,
            });
            return;
          } else {
            //新規に子会社一覧を追加していたらメッセージ変更
            messageId = "I004";
            if (flgChildCompany) {
              messageId = "I032";
            }
            //更新確認ダイアログ表示
            setFlgUpdate(false);
            showDialog({
              id: messageId,
              confirm: true,
              callback(isOk) {
                if (isOk) {
                  // OKが押された場合、更新処理を実施する
                  updateChildCompany();
                }
              },
            });
          }
        }
      })
      .catch((error) => {
        // エラー時の処理
        if (error.response.status === 404) {
          //入力した行の情報を書き換える
          subsidiaryDatas[rowNo].childCompanyName = "";
          subsidiaryDatas[rowNo].messageId = "E037";
          setSubsidiarDatas([...subsidiaryDatas]);
          //エラーメッセージの表示
          showDialog({
            id: "E037",
            args: [rowNo + 1],
            confirm: false,
          });
        } else if (error.response.status !== 404) {
          showDialog({ error });
        }
      });
  };

  // 子会社一覧更新確認ダイアログ
  function updateChildCompanyDialog() {
    if (flgCellEdit) {
      //セル編集中に更新ボタン押下された場合、onChangeイベントを実行する。
      setFlgUpdate(true);
      gridRefChildCompany.current?.api.stopEditing();
    } else {
      //新規に子会社一覧を追加していたらメッセージ変更
      let messageId = "I004";
      if (flgChildCompany) {
        messageId = "I032";
      }
      //更新確認ダイアログを表示
      setFlgUpdate(false);
      showDialog({
        id: messageId,
        confirm: true,
        callback(isOk) {
          if (isOk) {
            // OKが押された場合、更新処理を実施する
            updateChildCompany();
          }
        },
      });
    }
  }

  // 子会社一覧更新
  function updateChildCompany() {
    let messageId = "";
    let errKey = 0;
    // 子会社一覧の明細取得
    const childCompanyRows: CompanyGetResponseSub3[] = [];
    subsidiaryDatas?.forEach((it, index) => {
      //エラーメッセージが発生していた場合、エラーメッセージを表示して処理を終了する
      if (it.messageId !== undefined) {
        messageId = it.messageId;
        errKey = it.key;
        return;
      }
      //空行を無視する
      if (it.childCompanyCode !== "" && it.childCompanyCode !== undefined) {
        childCompanyRows.push({
          childCompanyCode: it?.childCompanyCode,
          childCompanyName: it?.childCompanyName,
          companyGroupUpdateDateTime: companyGroupUpdateDateTime,
        });
      }
    });
    //明細にエラーが残っていた場合はエラーメッセージを表示する
    if (messageId !== "") {
      showDialog({
        id: messageId,
        args: [errKey],
        confirm: false,
      });
      return;
    }
    //明細が0件（登録内容削除の場合）
    if (childCompanyRows.length === 0) {
      childCompanyRows.push({
        childCompanyCode: "",
        childCompanyName: "",
        companyGroupUpdateDateTime: companyGroupUpdateDateTime,
      });
    }
    //リクエストの作成
    const request: CompanyGroupsPostRequest = {
      companyUpdateDateTime: companyData?.companyUpdateDateTime,
      companyGroupsPostRequestSub: childCompanyRows,
    };
    // 得意先グループ登録API実行
    api
      .post(`/api/v1/companies/${companyCode}/groups`, request)
      .then((it) => {
        //結果を取得
        const companyUpdateDateTime: Date = it.data?.companyUpdateDateTime;
        const companyGroupsPostRequestSub: CompanyGetResponseSub3[] =
          it.data?.companyGroupsPostRequestSub;

        setChildCompany(companyGroupsPostRequestSub);
        // 子会社明細編集
        const childCompanyRows: subsidiaryList[] = [];
        companyGroupsPostRequestSub?.forEach((it, index) => {
          childCompanyRows.push({
            key: index + 1,
            childCompanyCode: it.childCompanyCode,
            childCompanyName: it.childCompanyName,
            companyGroupUpdateDateTime: it.companyGroupUpdateDateTime,
            canDelete: true,
            messageId: undefined,
          });
        });
        setSubsidiarDatas([...childCompanyRows]);
        //ボタンステータス変更
        changeBtnStatus(BTN_UPDATE_CAN);
        //子得意先コードテキストタイプ設定
        setChildCompanyCodeTxt(TXT_CLASS_UNEDIT);
        //明細0件の時、メッセージ表示
        if (0 === companyGroupsPostRequestSub.length) {
          setCompanyGroupUpdateDateTime(undefined);
          setNoRowsTextChildCompany(TextConst.GRID_NO_DATA);
        } else {
          setCompanyGroupUpdateDateTime(
            companyGroupsPostRequestSub[0].companyGroupUpdateDateTime
          );
        }
        //得意先マスタの更新日時を最新に
        if (companyData !== undefined) {
          companyData.companyUpdateDateTime = companyUpdateDateTime;
        }
        //新規追加フラグオフ
        setFlgChildCompany(false);
        splashMessage.show("I046");
      })
      .catch((error) => {
        // エラー時の処理
        if (error.response.status === 404) {
          showDialog({ id: "E051" });
        } else {
          showDialog({ error });
        }
      });
  }

  // 子会社行削除
  function onClickSubsidiaryDeleteButton(key: number) {
    showDialog({
      id: "I031",
      confirm: true,
      callback(isOk) {
        if (isOk) {
          // OKが押された場合、自身の行を削除する
          const childRowsData: subsidiaryList[] = subsidiaryDatas
            .filter((it) => it.key !== key)
            .map((it) => {
              return it;
            });
          //行番号をふり直す
          const childRows: subsidiaryList[] = [];
          childRowsData.forEach((it, index) => {
            childRows.push({
              key: index + 1,
              childCompanyCode: it.childCompanyCode,
              childCompanyName: it.childCompanyName,
              companyGroupUpdateDateTime: it.companyGroupUpdateDateTime,
              canDelete: childRowsData.length !== index + 1, //最終行の削除ボタンを非表示に,
              messageId: it.messageId,
            });
          });
          setSubsidiarDatas(childRows);
        }
      },
    });
  }

  // 子会社の更新を辞めるボタン押下
  function onClicChildCompanyCancel(btnFlg: string) {
    showDialog({
      id: "I001",
      confirm: true,
      callback(isOk) {
        if (isOk) {
          //OKボタン押下で画面を閉じる
          //ボタンステータス変更
          changeBtnStatus(btnFlg);
          //子得意先コードテキストタイプ設定
          setChildCompanyCodeTxt(TXT_CLASS_UNEDIT);
          // 子会社明細編集
          const childRows: subsidiaryList[] = [];
          childCompany?.forEach((it, index) => {
            childRows.push({
              key: index + 1,
              childCompanyCode: it.childCompanyCode,
              childCompanyName: it.childCompanyName,
              companyGroupUpdateDateTime: it.companyGroupUpdateDateTime,
              canDelete: true,
              messageId: undefined,
            });
          });
          setSubsidiarDatas([...childRows]);
          //明細0件の時、メッセージ表示
          if (0 === subsidiaryDatas.length) {
            setCompanyGroupUpdateDateTime(undefined);
            setNoRowsTextChildCompany(TextConst.GRID_NO_DATA);
          } else {
            setCompanyGroupUpdateDateTime(
              subsidiaryDatas[0].companyGroupUpdateDateTime
            );
          }
          setFlgChildCompany(false);
        }
      },
    });
  }

  // 商社/商社でないの設定
  function changeBtnStatus(btnFlg: string) {
    if (browseFlg) {
      //閲覧モード 管理船舶、子会社更新不可
      setBtnManergeShip(BTN_UPDATE_NOT);
      setBtnChildCompany(BTN_UPDATE_NOT);
    } else if (btnFlg === "1") {
      // 管理船舶、子会社更新可能
      setBtnManergeShip(BTN_UPDATE_CAN);
      setBtnChildCompany(BTN_UPDATE_CAN);
    } else if (btnFlg === "2") {
      // 管理船舶更新中
      setBtnManergeShip(BTN_MANERGE_SHIP_UPDATE_DOING);
      setBtnChildCompany(BTN_MANERGE_SHIP_UPDATE_DOING);
    } else if (btnFlg === "3") {
      // 子会社更新中
      setBtnManergeShip(BTN_CHILD_COMPANY_UPDATE_DOING);
      setBtnChildCompany(BTN_CHILD_COMPANY_UPDATE_DOING);
    } else {
      // 管理船舶、子会社更新不可
      setBtnManergeShip(BTN_UPDATE_NOT);
      setBtnChildCompany(BTN_UPDATE_NOT);
    }
  }

  // グリッド内の削除ボタン
  function deleteButtonStyle(canDelete: boolean) {
    if (canDelete) {
      return {};
    } else {
      // 削除不可の場合は非表示
      return { display: "none" };
    }
  }

  // モーダルクローズハンドラ
  const handleClose = () => {
    //管理船舶または子会社一覧編集中に画面を閉滋養とした場合
    if (
      btnManergeShip === BTN_MANERGE_SHIP_UPDATE_DOING ||
      btnChildCompany === BTN_CHILD_COMPANY_UPDATE_DOING
    ) {
      showDialog({
        id: "I001",
        confirm: true,
        callback(isOk) {
          if (isOk) {
            //OKボタン押下で画面を閉じる
            setShow(false);
            setCompanyCode(undefined);
          }
        },
      });
    } else {
      //画面を閉じる
      setShow(false);
      setCompanyCode(undefined);
    }
  };

  // 管理船舶一覧グリッドの列定義
  const manageShipColumnDefs = [
    {
      headerName: "No",
      field: "key",
      width: 70,
    },
    {
      headerName: "IMO",
      field: "imoNo",
      width: 150,
      editable: btnManergeShip === BTN_MANERGE_SHIP_UPDATE_DOING,
      cellClass: imoTxt,
    },
    {
      headerName: "船名",
      field: "vesselName",
      width: 230,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "得意先船舶紐づけマスタ更新日時",
      field: "manergeShipUpdateDateTime",
      hide: true,
    },
    {
      headerName: "削除",
      colId: "delete",
      width: 80,
      cellRenderer: (params: any) => {
        return (
          <span
            style={deleteButtonStyle(params.data["canDelete"])}
            data-cy="管理船舶削除ボタン"
          >
            <a
              style={{ color: "black" }}
              href="#"
              onClick={(e) =>
                onClickManergeShipDeleteButton(params.data["key"])
              }
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <HiOutlineTrash size={20} style={{ margin: "10 10px" }} />
              </div>
            </a>
          </span>
        );
      },
      //管理船舶一覧を編集中のみ削除ボタンは有効にする
      hide: !(btnManergeShip === BTN_MANERGE_SHIP_UPDATE_DOING),
    },
    {
      field: "messageId",
      hide: true,
    },
  ];

  // 子会社一覧グリッドの列定義
  const childCompanyColumnDefs = [
    {
      headerName: "No",
      field: "key",
      width: 70,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "得意先コード",
      field: "childCompanyCode",
      width: 130,
      editable: btnChildCompany === BTN_CHILD_COMPANY_UPDATE_DOING,
      cellClass: childCompanyCodeTxt,
    },
    {
      headerName: "会社名",
      field: "childCompanyName",
      width: 230,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "得意先グループマスタ更新日時",
      field: "companyGroupUpdateDateTime",
      hide: true,
    },
    {
      headerName: "削除",
      colId: "delete",
      width: 80,
      cellRenderer: (params: any) => {
        return (
          <span
            style={deleteButtonStyle(params.data["canDelete"])}
            data-cy="子会社削除ボタン"
          >
            <a
              style={{ color: "black" }}
              href="#"
              onClick={(e) => onClickSubsidiaryDeleteButton(params.data["key"])}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <HiOutlineTrash size={20} style={{ margin: "10 10px" }} />
              </div>
            </a>
          </span>
        );
      },
      //子会社一覧を編集中のみ削除ボタンは有効にする
      hide: !(btnChildCompany === BTN_CHILD_COMPANY_UPDATE_DOING),
    },
    {
      field: "messageId",
      hide: true,
    },
  ];

  // モーダルbody部レンダリング
  function modalBody() {
    return (
      <>
        <div>
          <div className={areaClass}>
            <div className="row">
              <div className="col-6">
                <div className="input-group">
                  {/* 得意先コード */}
                  <span className=" b-input-group-text" style={itemTitleStyle}>
                    得意先コード
                  </span>
                  <span className="form-control" data-cy="得意先コード">
                    {companyData?.companyCode}
                  </span>
                </div>
              </div>

              {/* 得意先名 */}
              <div className="col-6">
                <div className="input-group">
                  <span className="b-input-group-text" style={itemTitleStyle}>
                    得意先名
                  </span>
                  <span className="form-control" data-cy="得意先名">
                    {companyData?.companyName}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              {/* 商社 */}
              <div className="col-6">
                <div className="input-group">
                  <span className="b-input-group-text" style={itemTitleStyle}>
                    商社
                  </span>

                  {/* トグル */}
                  <div
                    className="form-control form-check form-switch mb-0"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      lineHeight: "1.5",
                    }}
                  >
                    <input
                      className="form-check-input mt-0"
                      style={{
                        marginLeft: "-2rem",
                        fontSize: 20,
                        width: 60,
                      }}
                      type="checkbox"
                      disabled
                      checked={companyData?.tradingFlg ?? false}
                      data-cy="商社チェックボックス"
                    />
                    <span className="mx-2" data-cy="商社">
                      {trading}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="input-group">
                  {/* 郵便番号 */}
                  <span className="b-input-group-text" style={itemTitleStyle}>
                    〒
                  </span>
                  <span className="form-control" data-cy="郵便番号">
                    {companyData?.postalCode}
                  </span>
                </div>
              </div>
            </div>

            {/* 住所１ */}
            <div className="row">
              <div className="col-12">
                <div className="input-group">
                  <span className="b-input-group-text" style={itemTitleStyle}>
                    住所１
                  </span>

                  <span className="form-control" data-cy="住所１">
                    {companyData?.address1}
                  </span>
                </div>
              </div>
            </div>
            {/* 住所２ */}
            <div className="row">
              <div className="col-12">
                <div className="input-group">
                  {companyData?.address2?.trim() && (
                    <>
                      <span
                        className="b-input-group-text"
                        style={itemTitleStyle}
                      >
                        住所２
                      </span>
                      <span className="form-control" data-cy="住所２">
                        {companyData?.address2}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="input-group">
                  {/* 電話番号 */}
                  <span className="b-input-group-text" style={itemTitleStyle}>
                    電話番号
                  </span>
                  <span className="form-control" data-cy="電話番号">
                    {companyData?.tel}
                  </span>
                </div>
              </div>
              {/* FAX */}
              <div className="col-6 ps-0">
                <div className="input-group">
                  <span className="b-input-group-text" style={itemTitleStyle}>
                    FAX
                  </span>
                  <span className="form-control" data-cy="FAX">
                    {companyData?.fax}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="input-group">
                  {/* 支払条件 */}
                  <span className="b-input-group-text" style={itemTitleStyle}>
                    支払条件
                  </span>
                  <span className="form-control" data-cy="支払条件">
                    {companyData?.paymentTerms}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="input-group">
                  {/* BEMAC主担当１ */}
                  <span
                    className="b-input-group-text-light_blue"
                    style={itemTitleStyle}
                  >
                    BEMAC主担当１
                  </span>
                  <span className="form-control" data-cy="BEMAC主担当１">
                    {companyData?.bemacMainCharge1}
                  </span>
                </div>
              </div>
              {/* BEMAC主担当２ */}
              <div className="col-6 ps-0">
                <div className="input-group">
                  <span
                    className="b-input-group-text-light_blue"
                    style={itemTitleStyle}
                  >
                    BEMAC主担当２
                  </span>
                  <span className="form-control" data-cy="BEMAC主担当２">
                    {companyData?.bemacMainCharge2}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              {/* BEMAC副担当１ */}
              <div className="col-6">
                <div className="input-group">
                  <span
                    className="b-input-group-text-light_blue"
                    style={itemTitleStyle}
                  >
                    BEMAC副担当１
                  </span>
                  <span className="form-control" data-cy="BEMAC副担当１">
                    {companyData?.bemacSubCharge1}
                  </span>
                </div>
              </div>
              {/* BEMAC副担当２ */}
              <div className="col-6 ps-0">
                <div className="input-group">
                  <span
                    className="b-input-group-text-light_blue"
                    style={itemTitleStyle}
                  >
                    BEMAC副担当２
                  </span>
                  <span className="form-control" data-cy="BEMAC副担当２">
                    {companyData?.bemacSubCharge2}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              {/* BEMACアシスタント１ */}
              <div className="col-6">
                <div className="input-group">
                  <span
                    className="b-input-group-text-light_blue"
                    style={itemTitleStyle}
                  >
                    BEMACアシスタント１
                  </span>
                  <span className="form-control" data-cy="BEMACアシスタント１">
                    {companyData?.bemacAssistant1}
                  </span>
                </div>
              </div>
              {/* BEMACアシスタント２ */}
              <div className="col-6 ps-0">
                <div className="input-group">
                  <span
                    className="b-input-group-text-light_blue"
                    style={itemTitleStyle}
                  >
                    BEMACアシスタント２
                  </span>
                  <span className="form-control" data-cy="BEMACアシスタント２">
                    {companyData?.bemacAssistant2}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {!companyData?.tradingFlg && (
            <div className="row">
              <div className="input-group">
                <div className="col-6">
                  <div className="row">
                    <div className="col-4">
                      <span className="sub-title-font-style">管理船舶一覧</span>

                      {!browseFlg && (
                        <span>
                          <TooltipIcon
                            messageId="T012"
                            position="up-right"
                            data-cy="管理船舶一覧ツールチップ"
                          />
                        </span>
                      )}
                    </div>
                    {/* 管理船舶一覧更新ボタン */}
                    {btnManergeShip === BTN_UPDATE_CAN && (
                      <div className="col-8">
                        <Button
                          type="button"
                          className="btn b-btn-primary m-0 mb-2"
                          onClick={() =>
                            onClickManageShip(BTN_MANERGE_SHIP_UPDATE_DOING)
                          }
                          data-cy="管理船舶の更新はこちらからボタン"
                        >
                          管理船舶の更新はこちらから
                        </Button>
                      </div>
                    )}
                    {btnManergeShip === BTN_MANERGE_SHIP_UPDATE_DOING && (
                      <div className="col-8">
                        <Button
                          type="button"
                          className="btn b-btn-light mb-2"
                          onClick={() =>
                            onClickManageShipCancel(BTN_UPDATE_CAN)
                          }
                          data-cy="管理船舶の更新をやめるボタン"
                        >
                          管理船舶の更新をやめる
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    {(btnManergeShip === BTN_CHILD_COMPANY_UPDATE_DOING ||
                      btnManergeShip === BTN_UPDATE_NOT) && (
                      <div style={{ marginLeft: "25rem" }}></div>
                    )}
                    <div className="col-4">
                      {browseFlg && (
                        <span className="sub-title-font-style">子会社一覧</span>
                      )}
                      {!browseFlg && (
                        <span className="sub-title-font-style">子会社一覧</span>
                      )}
                      {!browseFlg && (
                        <span>
                          <TooltipIcon
                            messageId="T013"
                            position="up-left"
                            data-cy="子会社一覧ツールチップ"
                          />
                        </span>
                      )}
                    </div>
                    {/* 子会社一覧更新ボタン */}
                    {btnChildCompany === BTN_UPDATE_CAN && (
                      <div className="col-8">
                        <Button
                          type="button"
                          className="btn b-btn-primary m-0 mb-2"
                          onClick={() =>
                            onClicChildCompany(BTN_CHILD_COMPANY_UPDATE_DOING)
                          }
                          data-cy="子会社の更新はこちらからボタン"
                        >
                          子会社の更新はこちらから
                        </Button>
                      </div>
                    )}
                    {btnChildCompany === BTN_CHILD_COMPANY_UPDATE_DOING && (
                      <div className="col-8">
                        <Button
                          className="btn b-btn-light mb-2"
                          onClick={() =>
                            onClicChildCompanyCancel(BTN_UPDATE_CAN)
                          }
                          data-cy="子会社の更新をやめるボタン"
                        >
                          子会社の更新をやめる
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* 管理船舶一覧明細 */}
          {!companyData?.tradingFlg && (
            <div>
              <div className="row">
                <div
                  className="ag-theme-alpine col-6  b-header-row-white"
                  data-cy="管理船舶一覧グリッド"
                >
                  <AgGridReact
                    domLayout="autoHeight"
                    defaultColDef={defaultColDef}
                    columnDefs={manageShipColumnDefs}
                    rowData={manageShipDatas}
                    rowDragManaged={true}
                    onCellEditingStarted={onCellManergeShipEditingStarted}
                    onCellEditingStopped={onCellManergeShipEditingStopped}
                    rowSelection="multiple"
                    suppressRowClickSelection
                    singleClickEdit
                    ref={gridRefManageShip}
                    overlayNoRowsTemplate={noRowsTextManergeShip}
                  />
                </div>
                <div
                  className="ag-theme-alpine col-6  b-header-row-white"
                  style={{
                    marginLeft: "-0.5rem",
                  }}
                  data-cy="子会社一覧グリッド"
                >
                  {/* 子会社一覧明細 */}
                  <AgGridReact
                    domLayout="autoHeight"
                    defaultColDef={defaultColDef}
                    columnDefs={childCompanyColumnDefs}
                    onCellEditingStarted={onCellSubsidiarEditingStarted}
                    onCellEditingStopped={onCellSubsidiarValueEditingStopped}
                    rowData={subsidiaryDatas}
                    rowDragManaged={true}
                    rowSelection="multiple"
                    suppressRowClickSelection
                    singleClickEdit
                    ref={gridRefChildCompany}
                    overlayNoRowsTemplate={noRowsTextChildCompany}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  // モーダルfooter部レンダリング
  function modalFooter() {
    return (
      <>
        {/* 管理船舶更新ボタン */}
        {btnManergeShip === BTN_MANERGE_SHIP_UPDATE_DOING && (
          <div>
            <Button
              type="button"
              className="btn b-btn-primary ms-5"
              onClick={() => updateManergeShipDialog()}
              style={{ width: 150, marginLeft: "10rem" }}
              data-cy="管理船舶更新ボタン"
            >
              管理船舶更新
            </Button>
          </div>
        )}
        {/* 子会社更新ボタン */}
        {btnChildCompany === BTN_CHILD_COMPANY_UPDATE_DOING && (
          <div>
            <Button
              type="button"
              className="btn b-btn-primary ms-5"
              onClick={() => updateChildCompanyDialog()}
              style={{ width: 150, marginLeft: "10rem" }}
              data-cy="子会社更新ボタン"
            >
              子会社更新
            </Button>
          </div>
        )}
        <Button
          className="b-btn-close ms-3"
          onClick={handleClose}
          data-cy="Closeボタン"
        >
          Close
        </Button>
      </>
    );
  }

  // レンダリング
  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      scrollable
      data-cy="得意先詳細モーダル"
    >
      <Modal.Header closeButton>
        <Modal.Title>得意先詳細</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBody()}</Modal.Body>
      <Modal.Footer>{modalFooter()}</Modal.Footer>
    </Modal>
  );
});

export default CompanyDetail;
