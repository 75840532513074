/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { FaQuestionCircle } from "react-icons/fa";

import { replaceMessageArgs } from "../../util/ecUtil";

/** prop定義 */
type Props = {
  messageId?: string;
  args?: any[];
  position?: "right" | "left" | "up-right" | "up-left";
};

const TooltipIcon = React.memo((props: React.PropsWithChildren<Props>) => {
  const isLeft = props.position === "left" || props.position === "up-left";
  const isUp = props.position === "up-right" || props.position === "up-left";
  /** style定義 */
  const tooptipStyle = css`
    position: relative;
    div.tooltipicon-content {
      display: none;
      position: absolute;
      z-index: 10;
      width: max-content;
      ${isUp ? "bottom:25px;" : "top: 25px;"}
      ${isLeft ? "right: 25px;" : "left:25px;"}
      padding: 1rem;
      background-color: black;
      color: white;
      border-radius: 5px;
      opacity: 0.7;
    }
    &:hover div.tooltipicon-content {
      display: inline-block;
    }
  `;

  // メッセージファイルから取得するための関数
  const { t } = useTranslation();
  function getMessageText(props: Props) {
    if (props.messageId) {
      const baseText = t(`message.${props.messageId}`);
      return replaceMessageArgs(baseText, props.args);
    }
    return "";
  }
  const text = getMessageText(props);

  return (
    <>
      <span css={tooptipStyle} data-cy="ツールチップ">
        <IconContext.Provider value={{ color: "#ffc000", size: "25px" }}>
          <FaQuestionCircle />
        </IconContext.Provider>
        <div className="tooltipicon-content">
          {text && <label style={{ whiteSpace: "break-spaces" }}>{text}</label>}
          {props.children}
        </div>
      </span>
    </>
  );
});

export default TooltipIcon;
