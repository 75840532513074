import { useEffect } from "react";
import { Container } from "react-bootstrap";

import "../ag-grid-custom.css";
import SideMenu from "./home/SideMenu";

type Props = {
  component: React.ReactNode;
};

export function BaseLayout(props: Props) {
  //初回のみ実行
  useEffect(() => {
    /** 監視対象の要素オブジェクト */
    const elem: Node = document.querySelector("body")!;

    /** 監視時のオプション */
    const config = {
      childList: true, //子要素を監視対象に含む
    };
    /** 要素の変化監視をスタート */
    observer.observe(elem, config);
  }, []);

  const observer = new MutationObserver((mutationList) => {
    /** DOMの変化が起こった時の処理 */
    let modal: any;
    let modalBackDrop: any;
    mutationList.forEach((mutation) => {
      //ノードを取得
      const nodes: any = mutation.addedNodes;

      for (let i = 0; i < nodes.length; i++) {
        //modal要素を取得
        if (nodes[i].classList.contains("modal")) {
          modal = nodes[i];
          break;
        }
        //modal-backdrop(背景)要素を取得
        if (nodes[i].classList.contains("modal-backdrop")) {
          modalBackDrop = nodes[i];
          break;
        }
      }
    });
    //新しく開くモーダルのzIndexを設定する
    if (modal !== undefined && modalBackDrop !== undefined) {
      const modalNum = document.querySelectorAll(".modal").length;
      const addValue = 10 * (modalNum - 1);
      modal.style.zIndex = String(1055 + addValue);
      modalBackDrop.style.zIndex = String(1050 + addValue);
    }
  });

  return (
    <Container fluid className="px-0">
      <span
        style={{
          minHeight: "100%",
          width: `calc(100% - 72px)`,
          backgroundColor: "#E4E4E4",
          position: "absolute",
        }}
      >
        {props.component}
      </span>
      <span>
        <SideMenu />
      </span>
    </Container>
  );
}
