import axios from "axios";
import { useTranslation } from "react-i18next";
import { replaceMessageArgs } from "src/util/ecUtil";

import { useS3 } from "./useS3";

type ReportFile = {
  imoNo: string;
  wbs: string;
  handle: FileSystemFileHandle;
};
export const useDispatchReport = () => {
  /** サービスレポートのファイル名 */
  const SERVICE_REPORT_FILE_NAMES = ["Service_Report"];
  /** その他ファイルのファイル名 */
  const OTHER_FILE_NAMES = [
    "Check list",
    "CHECK LIST",
    "Check List",
    "check list",
  ];
  /** 工事レポートのファイル名（サービスレポート＋その他ファイル） */
  const REPORT_FILE_NAMES = SERVICE_REPORT_FILE_NAMES.concat(OTHER_FILE_NAMES);

  /** 選択される想定のフォルダ名 */
  const REQUIRE_DIRECTORY_NAME = "log";
  /** 不正なフォルダ選択時のメッセージID */
  const ERR_ID_INVALID_DIRECTORY = "E089";

  const s3 = useS3();

  // メッセージファイルから取得するための関数
  const { t } = useTranslation();

  /** フォルダを選択し、配下の工事レポートをS3にアップロード */
  async function upload() {
    try {
      // フォルダ選択ダイアログを表示
      const directoryHandle = await selectDirectory();
      // 選択フォルダ以下の工事レポートファイルをリスト化
      console.log("ファイルリスト取得開始:" + new Date(Date.now()).toString());
      const fileList = await getFileList(directoryHandle);
      console.log("ファイルリスト取得終了:" + new Date(Date.now()).toString());
      // S3と同期
      return await syncS3(fileList);
    } catch (error: any) {
      if (error instanceof DOMException) {
        // フォルダ選択をキャンセルした時に起きる例外は無視
        return false;
      } else if (
        error instanceof Error &&
        error.message === ERR_ID_INVALID_DIRECTORY
      ) {
        // www以外のフォルダの場合、エラーメッセージを組み立ててスロー
        const baseMessage = t(`message.${ERR_ID_INVALID_DIRECTORY}`);
        const message = replaceMessageArgs(baseMessage, [
          REQUIRE_DIRECTORY_NAME,
        ]);
        throw Error(message);
      } else {
        throw error;
      }
    }
  }

  /** サービスレポートダウンロード */
  function downloadServiceReports(imoNo: string, wbses: string[]) {
    donloadCore(imoNo, wbses, SERVICE_REPORT_FILE_NAMES);
  }

  /** その他ファイルダウンロード */
  function downloadOtherFiles(imoNo: string, wbses: string[]) {
    donloadCore(imoNo, wbses, OTHER_FILE_NAMES);
  }

  /** フォルダ選択ダイアログを表示 */
  async function selectDirectory() {
    const directoryHandle: FileSystemDirectoryHandle = await (
      window as any
    ).showDirectoryPicker();
    // wwwフォルダじゃなければエラー
    if (directoryHandle.name !== REQUIRE_DIRECTORY_NAME) {
      throw Error(ERR_ID_INVALID_DIRECTORY);
    }
    return directoryHandle;
  }

  /** 工事レポートファイル判定 */
  function isReportFile(dir: string, fileName: string) {
    // 想定のフォルダでなければレポートファイルでない
    // "/{imo}/{wbs}"となっていることの確認
    const dirPattern = /^\/[^/]+\/[^/]+$/;
    if (!dirPattern.test(dir)) {
      return false;
    }

    // 既定の文字列を含むpdfファイルの場合レポートファイルとみなす
    for (const validName of REPORT_FILE_NAMES) {
      if (fileName.endsWith(".pdf") && fileName.includes(validName)) {
        return true;
      }
    }
    return false;
  }

  /** 指定フォルダ以下のファイルをリスト化 */
  async function getFileList(
    directoryHandle: any,
    relativePath = ""
  ): Promise<ReportFile[]> {
    const result: ReportFile[] = [];
    // フォルダに含まれるファイルまたはフォルダごとにループ
    for await (const entry of directoryHandle.values()) {
      // フルパスを組み立てる
      const fullPath = `${relativePath}/${entry.name}`;

      if (entry.kind === "file") {
        // ファイルの場合は工事レポートだけを対象にリストに追加
        if (isReportFile(relativePath, entry.name)) {
          // IMOとWBSとファイルハンドラを保持
          const imoNo = relativePath.split("/")[1];
          const wbs = relativePath.split("/")[2];
          result.push({ imoNo, wbs, handle: entry });
        }
      } else if (entry.kind === "directory") {
        // フォルダの場合は再帰で配下の階層を探索
        const childFiles = await getFileList(entry, fullPath);
        result.push(...childFiles);
      }
    }
    // リストを返却
    return result;
  }

  /** S3へ同期 */
  async function syncS3(files: ReportFile[]) {
    // S3に保存済みのオブジェクトキーリスト
    let storedFileList = await getStoredFileList();

    // 共有フォルダのファイルごとループ
    for (let i = 0; i < files.length; i++) {
      const reportFile = files[i];
      const file = await reportFile.handle.getFile();

      // S3のパス...dispatch-report/[IMO]/[WBS]/[file-name].pdf
      const objectKey = `dispatch-report/${reportFile.imoNo}/${reportFile.wbs}/${file.name}`;

      // S3にあり、フォルダにある→なにもしない
      if (storedFileList.includes(objectKey)) {
        // S3のオブジェクトキーリストから除去しておく
        storedFileList = storedFileList.filter((it) => it !== objectKey);
      } else {
        // S3になく、フォルダにある→UPLOAD
        const encodeKey = encodeURI(objectKey);
        await s3.putS3File(encodeKey, file, false);
      }
    }

    // フォルダになくなっていたものを削除する
    for (const objectKey of storedFileList) {
      const encodeKey = encodeURI(objectKey);
      await s3.deleteS3File(encodeKey, false);
    }

    return true;
  }

  /** S3に登録されているファイルをリスト化 */
  async function getStoredFileList(imoNo?: string) {
    const response = await s3.getS3FileList(
      "dispatch-report/" + (imoNo ? imoNo + "/" : ""),
      false,
      true
    );
    return response.data;
  }

  /** ダウンロード主処理 */
  async function donloadCore(
    imoNo: string,
    wbses: string[],
    fileNames: string[]
  ) {
    // S3に保存済みのオブジェクトキーリスト
    let storedFileList = await getStoredFileList(imoNo);

    // imoNoでフィルタ
    storedFileList = storedFileList.filter((it) => it.split("/")[1] === imoNo);

    // wbsでフィルタ
    storedFileList = storedFileList.filter((it) =>
      wbses.includes(it.split("/")[2])
    );

    // fileNamesでフィルタしながらダウンロード
    for (let i = 0; i < storedFileList.length; i++) {
      const fileName = storedFileList[i].split("/")[3];
      for (let j = 0; j < fileNames.length; j++) {
        if (fileName.includes(fileNames[j])) {
          s3.getS3PresignedUrl("GET", storedFileList[i]).then((res) => {
            const presignedUrl = res.data;
            axios
              .get<Blob>(presignedUrl, { responseType: "blob" })
              .then((blobResponse) => {
                const fileName = storedFileList[i].substring(
                  storedFileList[i].lastIndexOf("/") + 1
                );
                const newFile: File = new File([blobResponse.data], fileName, {
                  type: blobResponse.headers["content-type"],
                });
                const a = document.createElement("a");
                a.download = newFile.name;
                a.href = URL.createObjectURL(newFile);
                a.click();
                a.remove();
              });
          });
        }
      }
    }
  }

  return {
    upload,
    downloadServiceReports,
    downloadOtherFiles,
  };
};
