import { useContext, useEffect, useState } from "react";

import { ApiCountContext } from "../../context/ApiCountProvider";

function LoadingSpinner() {
  const [visible, setVisible] = useState(false);

  // API通信状態変更イベントを受け取る
  const handleChangeRequestCount = (isBusy: boolean) => {
    setVisible(isBusy);
  };

  const apiCounter = useContext(ApiCountContext);
  useEffect(() => {
    apiCounter.addReceiver(handleChangeRequestCount);
  }, []);

  return visible ? (
    <div
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        opacity: 0.5,
        zIndex: 2000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      data-cy="API通信中アイコン"
    >
      <div
        className="spinner-border"
        style={{ width: "50px", height: "50px" }}
        role="status"
      ></div>
    </div>
  ) : (
    <></>
  );
}

export default LoadingSpinner;
