/** 実施年数領域 */
export const TIMINGS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30,
];

/** 実施年数領域(2.5年単位の対象) */
export const TIMING_MAIN = [2, 5, 7, 10, 12, 15, 17, 20, 22, 25, 27, 30];
export const TIMING_SUBS = [2, 7, 12, 17, 22, 27];

/** 集約表示(Code) */
export const DISPLAY_CODE = "0";
/** 集約表示(大分類) */
export const DISPLAY_LARGE = "1";
/** 集約表示(中分類) */
export const DISPLAY_MIDDLE = "2";

// 商品情報関連
export const MIDDLE_CLASS_VALUE = "middleClassName";
export const PRODUCT_GOODS_CODE = "productCode";
export const PRODUCT_GROUP_NAME = "productGroupName";
export const SPEC = "spec";
export const QUANTITY = "recommendQuantity";
export const DISCONTINUED_FLG = "discontinuedFlg";
export const REPLACEMENT_FLG = "replacementFlg";

// 実施状況関連
export const RECOMMEND = "recommend";
export const DONE = "done";
export const QUOTATION = "quotation";

/** 予定金額列 */
export const RECOMMEND_SUBTOTAL = "recommendSubtotal";
/** 実施金額列 */
export const DONE_SUBTOTAL = "doneSubtotal";
/** 見積金額列 */
export const QUOTATION_SUBTOTAL = "quotationSubtotal";

/** 点検内訳(両方) */
export const INSPECTION_DETAIL_BOTH = "1";
/** 点検内訳(定期点検のみ) */
export const INSPECTION_DETAIL_INSPECTION = "2";
/** 点検内訳(部品交換のみ) */
export const INSPECTION_DETAIL_PARTS = "3";

/** 自動化装置の料金 */
export const AUTO_MACHINE_COST = 50000;
/** ロガーの料金 */
export const DATA_LOGGER_COST = 100000;
/** ACB三菱(1式)の料金 */
export const ACB_MITUBISHI_COST = 100000;
/** ACB寺崎(1式)の料金 */
export const ACB_TERASAKI_COST = 300000;
