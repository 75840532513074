import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getBizError } from "src/util/ecUtil";

import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { VesselGetResponse } from "../../model/api/response/VesselGetResponse";

// 公開する関数の定義
export interface shipDetailHandles {
  show(imoNo: string | undefined): void;
}

/* 船舶詳細コンポーネント */
const ShipDetail = forwardRef<shipDetailHandles>((props, ref) => {
  //API使用宣言
  const api = useApi();
  // 船舶情報
  const [vesselData, setVesselData] = useState<VesselGetResponse>();
  //ダイアログ仕様宣言
  const showDialog = useDialog();

  // エリアごとのCSSクラス
  const areaClass = "p-1 bg-white m-0";

  // 公開する関数の実装
  useImperativeHandle(ref, () => ({
    show(imoNo: string | undefined) {
      // 船舶単一取得API実行
      api
        .get(`/api/v1/vessels/${imoNo}`)
        .then((it) => {
          setVesselData(it.data); //船舶情報
          setShow(true);
        })
        .catch((error) => {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          } else {
            showDialog({ id: "E026" });
          }
        });
    },
  }));

  //input-groupに割り当てるstyle
  const inputGroupStyle = {
    minHeight: "42px",
  };

  //見出しに割り当てるstyle
  const headlineStyle = {
    width: "170px",
  };

  // モーダルbody部レンダリング
  function modalBody() {
    return (
      <>
        <div
          className={areaClass}
          style={{ borderRadius: "10px 10px 10px 10px" }}
        >
          <div className="row">
            <div className="col-6">
              <div className="input-group" style={inputGroupStyle}>
                <span className="b-input-group-text" style={headlineStyle}>
                  IMO
                </span>
                <span className="p-2 form-control" data-cy="IMOラベル">
                  {vesselData?.imoNo}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="input-group" style={inputGroupStyle}>
                <span className="b-input-group-text" style={headlineStyle}>
                  船名
                </span>
                <span className="p-2 form-control" data-cy="船名ラベル">
                  {vesselData?.vesselName}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="input-group" style={inputGroupStyle}>
                <span className="b-input-group-text" style={headlineStyle}>
                  造船所
                </span>
                <span className="p-2 form-control" data-cy="造船所ラベル">
                  {vesselData?.vesselBuilder}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="input-group" style={inputGroupStyle}>
                <span className="b-input-group-text" style={headlineStyle}>
                  造船所番号
                </span>
                <span className="p-2 form-control" data-cy="造船所番号ラベル">
                  {vesselData?.yardNo}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="input-group" style={inputGroupStyle}>
                <span className="b-input-group-text" style={headlineStyle}>
                  船型
                </span>
                <span className="p-2 form-control" data-cy="船型ラベル">
                  {vesselData?.vesselType}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="input-group" style={inputGroupStyle}>
                <span className="b-input-group-text" style={headlineStyle}>
                  建造年
                </span>
                <span className="p-2 form-control" data-cy="建造年ラベル">
                  {vesselData?.year}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="input-group" style={inputGroupStyle}>
                <span className="b-input-group-text" style={headlineStyle}>
                  船籍
                </span>
                <span className="p-2 form-control" data-cy="船籍ラベル">
                  {vesselData?.flag}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="input-group" style={inputGroupStyle}>
                <span className="b-input-group-text" style={headlineStyle}>
                  船籍港
                </span>
                <span className="p-2 form-control" data-cy="船籍港ラベル">
                  {vesselData?.port}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="input-group" style={inputGroupStyle}>
                <span className="b-input-group-text" style={headlineStyle}>
                  船級
                </span>
                <span className="p-2 form-control" data-cy="船級ラベル">
                  {vesselData?.vesselClass}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="input-group" style={inputGroupStyle}>
                <span className="b-input-group-text" style={headlineStyle}>
                  Deadweight
                </span>
                <span className="p-2 form-control" data-cy="Deadweightラベル">
                  {vesselData?.deadWeight}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  // state
  const [show, setShow] = useState(false);

  // モーダルクローズハンドラ
  const handleClose = () => {
    setShow(false);
  };

  //レンダリング
  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      scrollable
      data-cy="船舶詳細モーダル"
    >
      <Modal.Header closeButton>
        <Modal.Title>船舶詳細</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBody()}</Modal.Body>
      <Modal.Footer>
        <Button
          className="b-btn-close"
          onClick={handleClose}
          data-cy="Closeボタン"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default ShipDetail;
