import {
  ICellEditorComp,
  ICellEditorParams,
  ValueFormatterParams,
  ValueSetterParams,
} from "ag-grid-community";

import { formatDate } from "./ecUtil";

export const agNumberFormatter = (
  params: ValueFormatterParams,
  /** フォーマットできない場合に返す文字列 */
  defaultText?: string
) => {
  if (
    params.value !== null &&
    typeof params.value !== "undefined" &&
    !isNaN(params.value)
  ) {
    const num = params.value as number;
    return num.toLocaleString();
  }
  if (defaultText) {
    return defaultText;
  } else {
    return "";
  }
};

export const agYmdFormatter = (params: ValueFormatterParams) => {
  const value = params.value as string;
  return formatDate(value, "YYYY-MM-DD");
};

export const agDateCountFormatter = (params: ValueFormatterParams) => {
  if (params.value && !isNaN(params.value)) {
    const num = params.value as number;
    return num.toLocaleString() + "日";
  }
  return "";
};

export const defaultColDef = {
  resizable: true,
};

// 日本円金額表示のフォーマット(￥#,###形式)
export const agJapanCurrencyFormatter = (params: ValueFormatterParams) => {
  if (params.value && !isNaN(params.value)) {
    const num = params.value as number;
    return num.toLocaleString(undefined, {
      style: "currency",
      currency: "JPY",
    });
  }
  return "";
};

//ag-gridの入力値チェック＆変換
export const agNumberSetter = (params: ValueSetterParams, name: any) => {
  let value = params.newValue;
  if (value === "" || value === undefined) {
    params.data[name] = "";
    return true;
  }
  //入力値が数字のみで構成されているか確認
  if (value.match(/^[0-9０-９]+$/)) {
    //数字のみの場合、全て半角に変換してセットする
    params.data[name] = value.replaceAll(/[０-９]/g, (value: string) => {
      return String.fromCharCode(value.charCodeAt(0) - 0xfee0);
    });
    return true;
  } else {
    //数字以外が含まれていれば値を初期化(=未入力の状態)にする。
    params.data[name] = "";
  }
  return true;
};

export class AgDatePicker implements ICellEditorComp {
  eInput!: HTMLInputElement;

  // gets called once before the renderer is used
  init(params: ICellEditorParams) {
    // create the cell
    this.eInput = document.createElement("input");
    this.eInput.type = "date";
    this.eInput.value = params.value
      ? formatDate(params.value, "YYYY-MM-DD")
      : "";

    this.eInput.classList.add("ag-input");
    this.eInput.style.height = "100%";
  }

  // gets called once when grid ready to insert the element
  getGui() {
    return this.eInput;
  }

  // focus and select can be done after the gui is attached
  afterGuiAttached() {
    this.eInput.focus();
    this.eInput.select();
  }

  // returns the new value after editing
  getValue() {
    return this.eInput.value;
  }

  // any cleanup we need to be done here
  destroy() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
  }

  // if true, then this editor will appear in a popup
  isPopup() {
    // and we could leave this method out also, false is the default
    return false;
  }
}
