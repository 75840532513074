import { StylesConfig } from "react-select";

export interface SelectOption<T> {
  /** キー値 */
  value: T;
  /** 表示値 */
  label: string | undefined;
  /** 削除フラグ */
  delFlg?: boolean;
  /** 任意の値 */
  appendix?: any;
}

/**コンボボックス単一選択用スタイル */
export const defaultSelectStyle: StylesConfig<any | SelectOption<any>, false> =
  {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F5F5F5",
      whiteSpace: "nowrap",
    }),
    menu: (provided) => ({
      ...provided,
      width: "max-content",
      zIndex: 3,
    }),
    option: (prvided, state) => ({
      ...prvided,
      backgroundColor: state.data.delFlg ? "silver" : prvided.backgroundColor,
    }),
  };

/**コンボボックス複数選択用スタイル */
export const defaultSelectStyleMulti: StylesConfig<SelectOption<any>, true> = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#F5F5F5",
    whiteSpace: "nowrap",
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
  }),
  option: (prvided, state) => ({
    ...prvided,
    backgroundColor: state.data.delFlg ? "silver" : prvided.backgroundColor,
  }),
};
