/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import axios, { AxiosResponse } from "axios";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useDropzone } from "react-dropzone";
import { IoCloseCircleOutline } from "react-icons/io5";

import { useDialog } from "../../context/DialogProvider";
import { useS3 } from "../../hook/useS3";

/** prop定義 */
type Props = {
  /** 編集可否 */
  editable: boolean;
  /** 登録可能最大数 */
  maxCount: number;
  /** 画像サイズ */
  imgWidth?: number;
};

// 公開する関数の定義
export interface S3UploaderHandles {
  init(s3Prefix: string, isPublic: boolean): void;
  save(): Promise<AxiosResponse<void, any>[]>;
  initAndSave(
    s3Prefix: string,
    isPublic: boolean
  ): Promise<AxiosResponse<void, any>[]>;
  hasChanged(): boolean;
}

// 添付ファイルの型
type FileType = {
  name: string;
  contentType: string;
  data: any;
  isUploaded: boolean;
  isDeleted: boolean;
  imageUrl: string;
};

/** style定義 */
const areaStyle = css`
  padding: 1rem;
  border-style: dashed;
  border-radius: 11px;
  color: gray;
  &:hover {
    cursor: pointer;
  }
  label:hover {
    cursor: pointer;
  }
`;

/** メイン関数 */
const S3Uploader = forwardRef<S3UploaderHandles, Props>((props: Props, ref) => {
  // ダイアログ使用宣言
  const showDialog = useDialog();

  const [s3Prefix, setS3Prefix] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [fileList, setFileList] = useState<FileType[]>([]);

  // ファイル選択時orドロップ時
  const onDrop = (files: File[]) => {
    handleSelectFile(files);
  };

  // react-dropzone用定義
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  // S3使用宣言
  const s3 = useS3();

  // 公開関数
  useImperativeHandle(ref, () => ({
    // 初期化
    init(s3Prefix: string, isPublic: boolean) {
      setS3Prefix(s3Prefix);
      setIsPublic(isPublic);
      if (!s3Prefix) {
        setFileList([]);
        return;
      }
      s3.getS3FileList(s3Prefix, isPublic).then((response) => {
        response.data.forEach((objectKey) => {
          if (isPublic) {
            const url = s3.getS3Path(objectKey, true);
            loadFile(url, objectKey);
          } else {
            // 署名付きURLを取得してからデータ取得
            s3.getS3PresignedUrl("GET", objectKey).then((res2) => {
              const presignedUrl = res2.data;
              loadFile(presignedUrl, objectKey);
            });
          }
        });
      });
    },
    save() {
      return saveCore(s3Prefix, isPublic);
    },
    initAndSave(s3Prefix: string, isPublic: boolean) {
      setS3Prefix(s3Prefix);
      setIsPublic(isPublic);
      return saveCore(s3Prefix, isPublic);
    },
    hasChanged() {
      return fileList.filter(
        (file) =>
          (file.isUploaded === false && file.isDeleted === false) ||
          (file.isUploaded === true && file.isDeleted === true)
      ).length > 0
        ? true
        : false;
    },
  }));

  // S3からファイルの実データを取得
  function loadFile(url: string, objectKey: string) {
    axios.get<Blob>(url, { responseType: "blob" }).then((blobResponse) => {
      const fileName = objectKey.substring(objectKey.lastIndexOf("/") + 1);
      const newFile: FileType = {
        name: fileName,
        contentType: blobResponse.headers["content-type"],
        data: blobResponse.data,
        isUploaded: true,
        isDeleted: false,
        imageUrl: getImageUrl(fileName, blobResponse.data),
      };
      setFileList((prev) => [...prev, newFile]);
    });
  }

  // ファイル選択
  function handleSelectFile(files: File[]) {
    // 追加・上書きするファイル
    const newFiles = files.map((file) => {
      const newFile: FileType = {
        name: file.name,
        contentType: file.type,
        data: file,
        isUploaded: false,
        isDeleted: false,
        imageUrl: getImageUrl(file.name, file),
      };
      return newFile;
    });
    // 選択されたファイルと同名のものを除去
    const oldFiles = fileList.filter(
      (oldFile) => !files.find((newFile) => newFile.name === oldFile.name)
    );

    const allFiles = [...oldFiles, ...newFiles];
    if (allFiles.filter((file) => !file.isDeleted).length > props.maxCount) {
      // 最大数を超えたらエラー
      showDialog({ id: "E053" });
      return;
    }
    // stateに保存
    setFileList([...oldFiles, ...newFiles]);
  }

  // サムネURL取得
  function getImageUrl(fileName: string, blob: Blob) {
    if (isImageFile(fileName)) {
      return URL.createObjectURL(blob);
    } else if (isPdfFile(fileName)) {
      return `${process.env.PUBLIC_URL}/icon-pdf.png`;
    } else {
      return `${process.env.PUBLIC_URL}/icon-file.png`;
    }
  }

  // 画像ファイル判定
  function isImageFile(path: string) {
    const imageExtensions = [".png", ".jpg", ".jpeg", ".gif"];
    const x = imageExtensions.filter((extenstion) => path.endsWith(extenstion));
    return x.length > 0;
  }
  // PDFファイル判定
  function isPdfFile(path: string) {
    return path.endsWith(".pdf");
  }

  // 添付ファイルをS3に登録
  function saveCore(s3Prefix: string, isPublic: boolean) {
    // 追加指定したファイルをS3にアップロード
    const putPromises = fileList
      .filter((file) => file.isUploaded === false && file.isDeleted === false)
      .map((file) => {
        const objectKey = `${s3Prefix}${file.name}`;
        return s3.putS3File(objectKey, file.data, isPublic);
      });
    // 削除指定したファイルをS3から削除
    const deletePromises = fileList
      .filter((file) => file.isUploaded === true && file.isDeleted === true)
      .map((file) => {
        const objectKey = `${s3Prefix}${file.name}`;
        return s3.deleteS3File(objectKey, isPublic);
      });
    return Promise.all([...putPromises, ...deletePromises]);
  }

  // ×ボタンレンダリング
  function renderDeleteButton(target: FileType) {
    return (
      <IoCloseCircleOutline
        size={"1.5rem"}
        style={{
          color: "black",
          backgroundColor: "white",
          position: "absolute",
          right: "5px",
          zIndex: 10,
        }}
        onClick={() => {
          const newFileList = () => {
            if (target.isUploaded) {
              // アップロード済みのファイルなら削除フラグを立てておく
              return fileList.map((file) => {
                return file.name !== target.name
                  ? file
                  : { ...file, isDeleted: true };
              });
            } else {
              // アップロードしてなければリストから除外する
              return fileList.filter((file) => file.name !== target.name);
            }
          };
          setFileList(newFileList());
        }}
      />
    );
  }

  // ファイルが0件の時のレンダリング
  function renderNoFile() {
    return <div style={{ color: "gray" }}>添付ファイルはありません。</div>;
  }

  // 対象ファイルがある時のレンダリング
  function renderFiles() {
    return (
      <div>
        {fileList
          .filter((it) => !it.isDeleted)
          .map((it) => {
            const onClick = () => {
              if (isImageFile(it.name) || isPdfFile(it.name)) {
                // 画像・PDFは別ウィンドウで開く
                const blob = new Blob([it.data], { type: it.contentType });
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl);
              } else {
                // その他はダウンロード
                const blob = new Blob([it.data], { type: it.contentType });
                const a = document.createElement("a");
                a.download = it.name;
                a.href = URL.createObjectURL(blob);
                a.click();
                a.remove();
              }
            };
            return (
              <a key={it.name} style={{ position: "relative" }} href="#">
                <img
                  src={it.imageUrl}
                  title={it.name}
                  alt={it.name}
                  style={{ height: "auto", width: props.imgWidth ?? 80 }}
                  onClick={onClick}
                  data-cy="添付ファイルサムネイル"
                />
                {props.editable && renderDeleteButton(it)}
              </a>
            );
          })}
      </div>
    );
  }
  // レンダリング
  return (
    <>
      {props.editable && (
        <div css={areaStyle} {...getRootProps()}>
          <input {...getInputProps()} data-cy="添付ファイル選択" />
          <div>ここをクリックするかファイルをドラッグして添付できます。</div>
        </div>
      )}
      <div className="p-1">
        {fileList.length === 0 ? renderNoFile() : renderFiles()}
      </div>
    </>
  );
});

export default S3Uploader;
