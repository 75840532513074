import { useState } from "react";

import { useAuthUserContext } from "../../context/AuthUser";
import { OrderStatus, QuotationStatus } from "../../util/Constant";
import { DEFAULT_TAX_RATE, calculateTax } from "../../util/TaxUtil";
import RequireMark from "../common/RequireMark";
import { OrderDetailChangeEvent, OrderDetailType } from "./OrderDetailHeader";

type Props = {
  readOnly: boolean;
  type: OrderDetailType;
  status: string;
  replySubtotalPrice: number | undefined;
  replyDiscountPrice: number | undefined;
  replyTax: number | undefined;
  replyTaxPrice: number | undefined;
  replyTotalPrice: number | undefined;
  showTempPrice: boolean; // 仮金額を表示するか
  tempSubtotalPrice: number;
  reQuotationFlg: boolean; // 再見積フラグ
  /** 値変更イベント */
  onChange: (e: OrderDetailChangeEvent) => void;
};

function OrderDetailPrice(props: Props) {
  // ユーザ情報
  const auth = useAuthUserContext();

  const totalPrice = props.replySubtotalPrice ?? 0;
  const discount = props.replyDiscountPrice;
  const totalWithDiscount = totalPrice - (discount ?? 0);
  const tax = props.replyTax;
  const taxPrice = props.replyTaxPrice ?? 0;
  const calculatedTotalPrice = props.replyTotalPrice ?? 0;
  const tempPriceTitle =
    props.type === OrderDetailType.Quotation ? "仮見積金額" : "仮注文金額";
  const tempSubtotalPriceWithTax =
    calculateTax(props.tempSubtotalPrice, DEFAULT_TAX_RATE) +
    props.tempSubtotalPrice;

  //state
  const [valueResetFlg, setValueResetFlg] = useState<boolean>(false); //入力値を初期化するフラグ。

  //+/-/./eが入力された場合の処理(1文字目が半角数字以外の場合onChangeが発火しないのでonKeyUpで代用)
  //1文字目が全角文字の場合はinput type = numberの仕様で弾いてくれるのでOK。
  function onKeyUp(e: any) {
    if (e.code.match(/Minus|Semicolon|Period|KeyE/)) {
      e.target.value = "";
      return;
    }
  }

  // テキストボックス変更
  function handleChangeText(e: React.BaseSyntheticEvent<any>) {
    const name = e.target.name;
    let value = e.target.value;
    const inputValue = e.nativeEvent.data; //全角で入力された文字1文字分。(変換ボタン押下時は入力分全てになる)

    //変換確定後の値に半角数字以外が存在する場合
    if (
      valueResetFlg === true &&
      (inputValue === null || !inputValue.match(/^[0-9]+$/))
    ) {
      value = "";
    }
    setValueResetFlg(false);

    //入力された文字が半角数字以外の場合、値初期化フラグをONにする。
    if (inputValue && !inputValue.match(/^[0-9]+$/)) {
      setValueResetFlg(true);
    }

    // 元画面に連携
    props.onChange({ name, value });
  }

  // 割引額表示可否判定
  function showsDiscount() {
    if (auth.user().bemacFlg) {
      // BEMACユーザは常に表示
      return true;
    } else {
      // 顧客ユーザは割引額ありの場合表示
      return (props.replyDiscountPrice ?? 0) > 0;
    }
  }

  // テキスト入力可否判定
  function enableText() {
    if (props.readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }
    if (props.type === OrderDetailType.Quotation) {
      // 見積の場合
      // 「作成中」「依頼中」の場合
      return [QuotationStatus.DRAFT, QuotationStatus.REQUESTED].includes(
        props.status
      );
    } else {
      // 注文の場合
      // 「注文中」、「部品手配中」、「完了」の場合
      return (
        props.status === OrderStatus.ORDERED ||
        props.status === OrderStatus.ARRANGING ||
        props.status === OrderStatus.COMPLETED
      );
    }
  }

  function tempPriceRenderer() {
    return (
      <>
        <div className="input-group">
          <span className="b-input-group-text w-50">{tempPriceTitle}</span>
          <span className="form-control text-end" data-cy="仮金額">
            {props.tempSubtotalPrice.toLocaleString()}
          </span>
        </div>
        <div className="input-group">
          <span className="b-input-group-text w-50">
            {tempPriceTitle}（税込）
          </span>
          <span className="form-control text-end" data-cy="仮金額（税込）">
            {tempSubtotalPriceWithTax.toLocaleString()}
          </span>
        </div>
      </>
    );
  }

  function priceAreaRenderer() {
    return (
      <>
        <div className="input-group">
          <span className="b-input-group-text w-50">小計（税抜）</span>
          <span className="form-control text-end" data-cy="小計（税抜）">
            {totalPrice.toLocaleString()}
          </span>
        </div>
        {showsDiscount() && (
          <>
            <div className="input-group">
              <span className="b-input-group-text w-50">
                割引額（税抜）
                {auth.user().bemacFlg && enableText() && <RequireMark />}
              </span>
              {auth.user().bemacFlg && enableText() ? (
                <input
                  type="number"
                  name="replyDiscountPrice"
                  className="form-control"
                  style={{ textAlign: "right", color: "red" }}
                  value={discount ?? ""}
                  onKeyUp={onKeyUp}
                  onChange={handleChangeText}
                  data-cy="割引額（税抜）テキスト"
                />
              ) : (
                <span
                  className={`form-control text-end ${
                    auth.user().bemacFlg ? "disabled" : ""
                  }`}
                  style={{ color: "red" }}
                  data-cy="割引額（税抜）"
                >
                  {discount && discount.toLocaleString()}
                </span>
              )}
            </div>
            <div className="input-group">
              <span className="b-input-group-text w-50">
                割引後小計（税抜）
              </span>
              <span
                className="form-control text-end"
                data-cy="割引後小計（税抜）"
              >
                {totalWithDiscount.toLocaleString()}
              </span>
            </div>
          </>
        )}
        <div className="input-group">
          <span className="b-input-group-text w-50">
            消費税率
            {auth.user().bemacFlg && enableText() && <RequireMark />}
          </span>
          {auth.user().bemacFlg ? (
            <input
              type="number"
              name="replyTax"
              className="form-control"
              style={{ textAlign: "right" }}
              value={tax ?? ""}
              disabled={!enableText()}
              onKeyUp={onKeyUp}
              onChange={handleChangeText}
              data-cy="消費税率テキスト"
            />
          ) : (
            <span className="form-control text-end" data-cy="消費税率">
              {tax}%
            </span>
          )}
        </div>
        <div className="input-group">
          <span className="b-input-group-text w-50">消費税額</span>
          <span className="form-control text-end" data-cy="消費税額">
            {taxPrice.toLocaleString()}
          </span>
        </div>
        <div className="input-group">
          <span className="b-input-group-text w-50">総計</span>
          <span className="form-control text-end" data-cy="総計">
            {calculatedTotalPrice.toLocaleString()}
          </span>
        </div>
      </>
    );
  }

  return (
    <div className="p-1 bg-white b-detail-items">
      {props.showTempPrice ? tempPriceRenderer() : priceAreaRenderer()}
    </div>
  );
}

export default OrderDetailPrice;
