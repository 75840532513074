import axios from "axios";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { IoCloseCircleOutline } from "react-icons/io5";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { useS3 } from "../../hook/useS3";
import { useSplashMessage } from "../../hook/useSplashMessage";
import { CompanyGetResponse } from "../../model/api/response/CompanyGetResponse";
import { InspectionResultPicturesGetResponse } from "../../model/api/response/InspectionResultPicturesGetResponse";
import { VesselGetResponse } from "../../model/api/response/VesselGetResponse";
import { getBizError } from "../../util/ecUtil";
import { TIMING_SUBS } from "../../util/lcm/LcmConst";
import { PictureListType } from "./InspectionResult";

// 公開する関数の定義
export interface InspectionResultPictureHandles {
  show(
    company: CompanyGetResponse | undefined,
    vessel: VesselGetResponse | undefined,
    companyCode: string,
    imoNo: string,
    timing: number,
    yearCheckValue: number
  ): void;
}

/** prop定義 */
type Props = {
  /** state操作関数 */
  setState: (pictureData: PictureListType[]) => void;
};

/** 定期点検写真コンポーネント */
const InspectionResultPicture = forwardRef<
  InspectionResultPictureHandles,
  Props
>((props: Props, ref) => {
  // 画像ファイルの型
  type FileType = {
    onDelete: boolean;
    file: File;
    uploadFlg: boolean;
    deleteFlg: boolean;
  };

  // state宣言
  const [beforePictureList, setBeforePictureList] = useState<FileType[]>([]);
  const [afterPictureList, setAfterPictureList] = useState<FileType[]>([]);
  const [inspectionPictureDataList, setInspectionPictureDataList] = useState<
    InspectionResultPicturesGetResponse[]
  >([]);
  const [vessel, setVessel] = useState<VesselGetResponse>();
  const [company, setCompany] = useState<CompanyGetResponse>();
  const [yearCheckValue, setYearCheckValue] = useState<number>();
  const [show, setShow] = useState(false);
  const [companyCode, setCompanyCode] = useState("");
  const [imoNo, setImoNo] = useState("");
  const [timing, setTiming] = useState("");
  const [formChanged, setFormChanged] = useState<boolean>(false);

  // 公開する関数の実装
  useImperativeHandle(ref, () => ({
    show(
      company: CompanyGetResponse | undefined,
      vessel: VesselGetResponse | undefined,
      companyCode: string,
      imoNo: string,
      timing: number,
      yearCheckValue: number
    ) {
      setCompany(company);
      setVessel(vessel);
      setShow(true);
      setCompanyCode(companyCode);
      setImoNo(imoNo);
      setTiming(timing.toString());
      setYearCheckValue(yearCheckValue);
      setBeforePictureList([]);
      setAfterPictureList([]);

      // 定期点検写真結果を取得
      getInsepectionResultPictureDataList(
        companyCode,
        imoNo,
        timing.toString()
      );

      // 該当年度の前写真を取得する
      getPictureList(companyCode, imoNo, timing.toString(), true);

      // 該当年度の後写真を取得する
      getPictureList(companyCode, imoNo, timing.toString(), false);
    },
  }));

  // API使用宣言
  const api = useApi();

  // ダイアログ使用宣言
  const showDialog = useDialog();

  // 権限情報
  const auth = useAuthUserContext();

  // S3使用宣言
  const s3 = useS3();

  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();

  // 写真スタイル
  const pictureStyle = {
    maxWidth: "100%",
    marginTop: "10px",
  };

  // モーダルクローズハンドラ
  const handleClose = () => {
    //レコメンド・明細・添付ファイルに変更があれば確認ダイアログを出す。
    if (formChanged) {
      showDialog({
        id: "I001",
        confirm: true,
        callback(isOk) {
          if (isOk) {
            setFormChanged(false);
            setShow(false);
          }
        },
      });
    } else {
      setFormChanged(false);
      setShow(false);
    }
  };

  // ヘッダ部見出しの幅
  const itemTitleStyle = { width: "8rem" };

  // 定期点検結果写真データリスト生成
  function getInsepectionResultPictureDataList(
    companyCode: string,
    imoNo: string,
    inspectionYearClassValue: string
  ) {
    api
      .get(
        `api/v1/vessels/${imoNo}/companies/${companyCode}/inspection-results/${inspectionYearClassValue}/pictures`
      )
      .then((response) => {
        const pictureData = response.data.map(
          (row: InspectionResultPicturesGetResponse) => {
            return row;
          }
        );
        setInspectionPictureDataList(pictureData);
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E073" });
        }
      });
  }

  // 該当年度の前画像データ取得
  function getPictureList(
    companyCode: string,
    imoNo: string,
    inspectionYearClassValue: string,
    beforeFlg: boolean //true:before
  ) {
    let objectKey;
    if (beforeFlg) {
      // 前分のデータ取得の場合
      objectKey = `inspection-result/before/${companyCode}-${imoNo}-${inspectionYearClassValue}/`;
    } else {
      // 後分のデータ取得の場合
      objectKey = `inspection-result/after/${companyCode}-${imoNo}-${inspectionYearClassValue}/`;
    }

    if (objectKey) {
      // 後分のデータ取得の場合
      s3.getS3FileList(objectKey, false).then((response) => {
        response.data.forEach((objectKeySub) => {
          // 署名付きURLを取得してからデータ取得
          s3.getS3PresignedUrl("GET", objectKeySub).then((res2) => {
            const presignedUrl = res2.data;
            loadFile(presignedUrl, objectKeySub, beforeFlg);
          });
        });
      });
    }
  }

  // S3からファイルの実データを取得
  function loadFile(url: string, objectKey: string, beforeFlg: boolean) {
    axios.get<Blob>(url, { responseType: "blob" }).then((blobResponse) => {
      const fileName = objectKey.substring(objectKey.lastIndexOf("/") + 1);
      const newFile: FileType = {
        onDelete: false,
        file: new File([blobResponse.data], fileName, {
          type: blobResponse.headers["content-type"],
        }),
        uploadFlg: true,
        deleteFlg: false,
      };
      if (beforeFlg) {
        setBeforePictureList((prev) => [...prev, newFile]);
      } else {
        setAfterPictureList((prev) => [...prev, newFile]);
      }
    });
  }

  // ×ボタンレンダリング
  function renderDeleteButton(beforeFlg: boolean, target?: FileType) {
    return (
      <IoCloseCircleOutline
        size={"2rem"}
        style={{
          color: "black",
          backgroundColor: "white",
          position: "absolute",
          right: "1px",
          top: "10px",
          zIndex: 10,
        }}
        onClick={() => {
          if (beforeFlg) {
            const newFileList = () => {
              if (target?.uploadFlg) {
                // アップロード済みのファイルなら削除フラグを立てておく
                return beforePictureList.map((fileList) => {
                  return fileList.file.name !== target.file.name
                    ? fileList
                    : { ...fileList, deleteFlg: true };
                });
              } else {
                // アップロードしてなければリストから除外する
                return beforePictureList.filter(
                  (it) => it.file.name !== target?.file.name
                );
              }
            };
            setBeforePictureList(newFileList());
          } else {
            const newFileList = () => {
              if (target?.uploadFlg) {
                // アップロード済みのファイルなら削除フラグを立てておく
                return afterPictureList.map((fileList) => {
                  return fileList.file.name !== target.file.name
                    ? fileList
                    : { ...fileList, deleteFlg: true };
                });
              } else {
                // アップロードしてなければリストから除外する
                return afterPictureList.filter(
                  (it) => it.file.name !== target?.file.name
                );
              }
            };
            setAfterPictureList(newFileList());
          }
          if (target) {
            target.onDelete = true;
          }

          const updateInspectiPictureDataList = [...inspectionPictureDataList];
          updateInspectiPictureDataList
            .filter(
              (it) =>
                it.beforeAfterClassValue === (beforeFlg ? "1" : "2") &&
                it.pictureFileName === target?.file.name
            )
            .forEach((sub) => {
              sub.deleteFlg = true;
            });

          //行単位で削除されている分表示番号を繰り上げる
          let displayNo = 0;
          for (let i = 0; i < 10; i++) {
            let checkFlg = true;
            const list = updateInspectiPictureDataList.filter(
              (it) => it.displayNo === i + 1 && it.deleteFlg === false
            );
            for (const sub of list) {
              if (checkFlg) {
                displayNo++;
                checkFlg = false;
              }
              sub.displayNo = displayNo;
            }
          }

          setInspectionPictureDataList(updateInspectiPictureDataList);

          // 更新チェック
          if (!formChanged) {
            setFormChanged(true);
          }
        }}
      />
    );
  }

  // BEFOREとAFTERの写真レンダリング
  function pictureRows() {
    const targetPitureList = inspectionPictureDataList
      .filter((it) => it.deleteFlg === false)
      .sort(
        (
          a: InspectionResultPicturesGetResponse,
          b: InspectionResultPicturesGetResponse
        ) => b.displayNo - a.displayNo
      );
    let maxCount =
      targetPitureList.length > 0 ? targetPitureList[0].displayNo : 0;

    if (maxCount < 10 && auth.isEngineer()) maxCount += 1;

    const list = [];

    for (let index = 0; index < maxCount; index++) {
      // 前後それぞれの対象写真のURLを取得する
      const beforeFileName = inspectionPictureDataList.find(
        (it) =>
          it.beforeAfterClassValue === "1" &&
          it.displayNo === index + 1 &&
          it.deleteFlg === false
      )?.pictureFileName;
      const afterFileName = inspectionPictureDataList.find(
        (it) =>
          it.beforeAfterClassValue === "2" &&
          it.displayNo === index + 1 &&
          it.deleteFlg === false
      )?.pictureFileName;

      const beforePicture = beforePictureList.find(
        (it) => it.file.name === beforeFileName && it.deleteFlg === false
      );
      const afterPicture = afterPictureList.find(
        (it) => it.file.name === afterFileName && it.deleteFlg === false
      );

      // 対象ファイルのURLを取得
      const blobBeforeUrl = beforePicture
        ? URL.createObjectURL(beforePicture.file)
        : undefined;
      const blobAfterUrl = afterPicture
        ? URL.createObjectURL(afterPicture.file)
        : undefined;

      const onBeforeClick = () => {
        if (beforePicture && !beforePicture.onDelete) {
          if (
            isImageFile(beforePicture.file.name) ||
            isPdfFile(beforePicture.file.name)
          ) {
            // 画像・PDFは別ウィンドウで開く
            const blobUrl = URL.createObjectURL(beforePicture.file);
            window.open(blobUrl);
          } else {
            // その他はダウンロード
            const a = document.createElement("a");
            a.download = beforePicture.file.name;
            a.href = URL.createObjectURL(beforePicture.file);
            a.click();
            a.remove();
          }
        }
      };

      const onAfterClick = () => {
        if (afterPicture && !afterPicture.onDelete) {
          if (
            isImageFile(afterPicture.file.name) ||
            isPdfFile(afterPicture.file.name)
          ) {
            // 画像・PDFは別ウィンドウで開く
            const blobUrl = URL.createObjectURL(afterPicture.file);
            window.open(blobUrl);
          } else {
            // その他はダウンロード
            const a = document.createElement("a");
            a.download = afterPicture.file.name;
            a.href = URL.createObjectURL(afterPicture.file);
            a.click();
            a.remove();
          }
        }
      };

      const renderer = (
        <div key={"picture-row-" + index} className="row" data-cy="データ行">
          <div className="input-group">
            <div
              className="col-1 p-2 border text-center d-flex align-items-center justify-content-center bg-white"
              data-cy="No"
            >
              {index + 1}
            </div>
            <div
              className="col p-2 border text-center d-flex align-items-center justify-content-center bg-white"
              data-cy="BEFORE"
            >
              {blobBeforeUrl ? (
                <a
                  key={beforePicture?.file.name}
                  style={{ position: "relative" }}
                  href="#"
                  onClick={onBeforeClick}
                >
                  <img
                    src={blobBeforeUrl}
                    style={pictureStyle}
                    title={beforePicture?.file.name}
                    alt={beforePicture?.file.name}
                    onMouseOver={(e) => toolTip(e)}
                    data-cy="BEFORE写真"
                  />
                  {auth.isEngineer() && renderDeleteButton(true, beforePicture)}
                </a>
              ) : (
                auth.isEngineer() && (
                  <>
                    <input
                      type="file"
                      id={"filePictureBefore" + index}
                      accept=".jpg,.gif,.png,image/gif,image/jpeg,image/png"
                      style={{ display: "none" }}
                      onChange={(e) => selectFile(e, index, true)}
                      onClick={(e: any) => {
                        e.target.value = "";
                      }}
                      multiple={false}
                      data-cy="BEFORE写真選択"
                    />

                    <Button
                      className="b-btn-primary"
                      onClick={() =>
                        document
                          .getElementById("filePictureBefore" + index)
                          ?.click()
                      }
                      data-cy="BEFOREファイルを選択ボタン"
                    >
                      ファイルを選択
                    </Button>
                  </>
                )
              )}
            </div>
            <div
              className="col p-2 border text-center d-flex align-items-center justify-content-center bg-white"
              data-cy="AFTER"
            >
              {blobAfterUrl ? (
                <a
                  key={afterPicture?.file.name}
                  style={{ position: "relative" }}
                  href="#"
                  onClick={onAfterClick}
                >
                  <img
                    src={blobAfterUrl}
                    style={pictureStyle}
                    title={afterPicture?.file.name}
                    alt={afterPicture?.file.name}
                    onMouseOver={(e) => toolTip(e)}
                    data-cy="AFTER写真"
                  />
                  {auth.isEngineer() && renderDeleteButton(false, afterPicture)}
                </a>
              ) : (
                auth.isEngineer() && (
                  <>
                    <input
                      type="file"
                      id={"filePictureAfter" + index}
                      accept=".jpg,.gif,.png,image/gif,image/jpeg,image/png"
                      style={{ display: "none" }}
                      onChange={(e) => selectFile(e, index, false)}
                      onClick={(e: any) => {
                        e.target.value = "";
                      }}
                      multiple={false}
                      data-cy="AFTER写真選択"
                    />
                    <Button
                      className="b-btn-primary"
                      onClick={() =>
                        document
                          .getElementById("filePictureAfter" + index)
                          ?.click()
                      }
                      data-cy="AFTERファイルを選択ボタン"
                    >
                      ファイルを選択
                    </Button>
                  </>
                )
              )}
            </div>
          </div>
        </div>
      );
      // divをリスト化
      list.push(renderer);
    }

    return list;
  }

  // 画像ファイル判定
  function isImageFile(path: string) {
    const imageExtensions = [".png", ".jpg", ".jpeg", ".gif"];
    const x = imageExtensions.filter((extenstion) => path.endsWith(extenstion));
    return x.length > 0;
  }

  // PDFファイル判定
  function isPdfFile(path: string) {
    return path.endsWith(".pdf");
  }

  //ファイルにマウスオーバー時の処理
  function toolTip(e: any) {
    return <div>{e.currentTarget.title}</div>;
  }

  // 画像ファイル選択処理
  function selectFile(
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    beforeFlg: boolean
  ) {
    // 取得したファイルを対象のグリッド用データに設定する。
    const newFileList: FileType[] = [];
    if (e.currentTarget.files) {
      for (let i = 0; i < e.currentTarget.files.length; i++) {
        newFileList.push({
          onDelete: false,
          file: e.currentTarget.files![i],
          uploadFlg: false,
          deleteFlg: false,
        });
      }
    }

    if (newFileList.length === 0) {
      return;
    }

    // ファイルリストに追加する
    if (beforeFlg) {
      setBeforePictureList([...beforePictureList, ...newFileList]);
    } else {
      setAfterPictureList([...afterPictureList, ...newFileList]);
    }

    // 定期点検写真データを更新
    const updateInspectionPictureDataList = [...inspectionPictureDataList];

    const newInspectionPictureData: InspectionResultPicturesGetResponse = {
      companyCode: companyCode,
      imoNo: imoNo,
      inspectionYearClassValue: timing,
      beforeAfterClassValue: beforeFlg ? "1" : "2",
      displayNo: index + 1,
      pictureFileName: newFileList[0].file.name,
      updateDateTime:
        inspectionPictureDataList.length > 0
          ? inspectionPictureDataList[0].updateDateTime
          : null,
      deleteFlg: false,
    } as InspectionResultPicturesGetResponse;

    // 既に同一表示順のデータが存在するかチェック
    // if (dataIndex === -1) {
    // 存在しなければ追加
    updateInspectionPictureDataList.push(newInspectionPictureData);

    setInspectionPictureDataList(updateInspectionPictureDataList);

    // 更新チェック
    if (!formChanged) {
      setFormChanged(true);
    }
  }

  // 登録ボタン
  function handleClickActionButton() {
    // 正常処理後の動作
    const saveFunc = () => {
      saveData();
    };

    showDialog({
      id: "I021",
      confirm: true,
      callback: (isOk) => {
        if (isOk) {
          saveFunc();
        }
      },
    });
  }

  // データ保存
  function saveData() {
    // 画像をS３にアップロード
    const uploadPromises = Promise.all([savePicture()]).then(() => {
      // 定期点検結果写真の登録値を編集

      const postRequest = inspectionPictureDataList.map((it) => {
        return {
          companyCode: it.companyCode,
          imoNo: it.imoNo,
          inspectionYearClassValue: it.inspectionYearClassValue,
          beforeAfterClassValue: it.beforeAfterClassValue,
          displayNo: it.displayNo,
          pictureFileName: it.pictureFileName,
          updateDateTime: it.updateDateTime,
          deleteFlg: it.deleteFlg,
        };
      });

      // 定期点検写真登録API実行
      api
        .post(
          `/api/v1/vessels/${imoNo}/companies/${companyCode}/inspection-results/${timing}/pictures`,
          postRequest
        )
        .then((it) => {
          splashMessage.show("I035");

          // 定期点検写真リストの後画像から表示番号が最小のデータを取得
          if (inspectionPictureDataList.length > 0) {
            //点検後の写真で表示Noの最小のファイル名を取得
            const targetPitureList = inspectionPictureDataList
              .filter(
                (it: InspectionResultPicturesGetResponse) =>
                  it.beforeAfterClassValue === "2"
              )
              .sort(
                (
                  a: InspectionResultPicturesGetResponse,
                  b: InspectionResultPicturesGetResponse
                ) => a.displayNo - b.displayNo
              );

            if (targetPitureList.length > 0) {
              const afterPicture = afterPictureList.find(
                (it) =>
                  it.file.name === targetPitureList[0].pictureFileName &&
                  it.deleteFlg === false
              );
              props.setState([
                {
                  companyCode: companyCode,
                  imoNo: imoNo,
                  inspectionYearClassValue: timing.toString(),
                  pictureList: afterPicture?.file,
                } as PictureListType,
              ]);
            } else {
              props.setState([]);
            }
          } else {
            props.setState([]);
          }
          setFormChanged(false);
          setShow(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            showDialog({ id: "E027" });
          } else {
            if (getBizError(error)) {
              //対応するエラーメッセージを表示
              showDialog({ error });
            } else {
              showDialog({ id: "E026" });
            }
          }
        });
    });
    return uploadPromises;
  }

  // 画像ァイルをS3に登録
  function savePicture() {
    // 既存ファイルをS3から削除
    const deletePromises: any[] = [];
    // 前画像の削除
    beforePictureList
      .filter((it) => it.deleteFlg === true && it.uploadFlg === true)
      .forEach((fileData) => {
        const objectKey = `inspection-result/before/${companyCode}-${imoNo}-${timing}/${fileData.file.name}`;
        deletePromises.push(s3.deleteS3File(objectKey, false));
      });

    // 後画像の削除
    afterPictureList
      .filter((it) => it.deleteFlg === true && it.uploadFlg === true)
      .forEach((fileData) => {
        const objectKey = `inspection-result/after/${companyCode}-${imoNo}-${timing}/${fileData.file.name}`;
        deletePromises.push(s3.deleteS3File(objectKey, false));
      });

    // 追加指定したファイルをS3に登録
    const putPromises: any[] = [];
    // 前画像の登録
    beforePictureList
      .filter((it) => it.deleteFlg === false && it.uploadFlg === false)
      .forEach((fileData) => {
        const objectKey = `inspection-result/before/${companyCode}-${imoNo}-${timing}/${fileData.file.name}`;
        putPromises.push(s3.putS3File(objectKey, fileData.file, false));
      });

    // 後画像の登録
    afterPictureList
      .filter((it) => it.deleteFlg === false && it.uploadFlg === false)
      .forEach((fileData) => {
        const objectKey = `inspection-result/after/${companyCode}-${imoNo}-${timing}/${fileData.file.name}`;
        putPromises.push(s3.putS3File(objectKey, fileData.file, false));
      });

    return Promise.all([...deletePromises, ...putPromises]);
  }

  // モーダルbody部レンダリング
  function modalBody() {
    return (
      <>
        <div className="row mb-2">
          <div className="col-4">
            <div className="input-group">
              <div className="b-input-group-text" style={itemTitleStyle}>
                実施年度
              </div>
              <span className="p-2 form-control" data-cy="実施年度">
                {yearCheckValue === 0 && TIMING_SUBS.includes(Number(timing))
                  ? Number(timing) + 0.5
                  : timing}
                年({Number(vessel ? vessel.year : 0) + Number(timing)}年)
              </span>
            </div>
          </div>
          <div className="col-4">
            <div className="input-group">
              <div className="b-input-group-text" style={itemTitleStyle}>
                得意先名
              </div>
              <span className="p-2 form-control" data-cy="得意先名">
                {company?.companyGetResponseSub1.companyName}
              </span>
            </div>
          </div>
          <div className="col-4">
            <div className="input-group">
              <div className="b-input-group-text" style={itemTitleStyle}>
                船名
              </div>
              <span className="p-2 form-control" data-cy="船名">
                {vessel?.vesselName}
              </span>
            </div>
          </div>
        </div>
        <div>
          {auth.isEngineer() && (
            <div className="row" data-cy="コメント行">
              <div className="col-8 p-2 text-start">
                <div>
                  <div
                    className=""
                    style={{ color: "red" }}
                    data-cy="定期点検結果写真コメント"
                  >
                    ※写真は実施年度ごとに最大10枚(BEFORE、AFTERで最大合計20枚)まで登録できます。
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row" data-cy="ヘッダ行">
            <div className="input-group ">
              <div className="col-1">
                <div
                  className="b-input-group-text d-flex align-items-center justify-content-center"
                  style={{
                    textAlign: "center",
                    borderRadius: "0.5rem 0 0 0",
                  }}
                >
                  No
                </div>
              </div>
              <div className="col">
                <div
                  className="b-input-group-text d-flex align-items-center justify-content-center"
                  style={{
                    textAlign: "center",
                    borderRadius: "0",
                  }}
                >
                  BEFORE
                </div>
              </div>
              <div className="col">
                <div
                  className="b-input-group-text d-flex align-items-center justify-content-center"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#E0463E",
                    borderRadius: "0 0.5rem 0 0",
                  }}
                >
                  AFTER
                </div>
              </div>
            </div>
          </div>
          {pictureRows()}
        </div>
      </>
    );
  }

  // モーダルfooter部レンダリング
  function modalFooter() {
    return (
      <>
        {auth.isEngineer() && (
          <Button
            className="b-btn-primary"
            onClick={handleClickActionButton}
            data-cy="登録ボタン"
          >
            登録
          </Button>
        )}
        <Button
          className="b-btn-close"
          onClick={handleClose}
          data-cy="Closeボタン"
        >
          Close
        </Button>
      </>
    );
  }

  // レンダリング
  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      scrollable
      data-cy="定期点検結果写真モーダル"
    >
      <Modal.Header closeButton>
        <Modal.Title>定期点検写真</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBody()}</Modal.Body>
      <Modal.Footer>{modalFooter()}</Modal.Footer>
    </Modal>
  );
});

export default InspectionResultPicture;
