import { useTranslation } from "react-i18next";

import { replaceMessageArgs } from "../util/ecUtil";

export const useSplashMessage = () => {
  //スプラッシュメッセージのstyle
  const msgStyle =
    "padding: 1em; background-color: gray; color: white; font-size:" +
    "2em; top: 50%; left: 50%; transform: translateY(-50%) translateX(-50%);" +
    " width: auto; height: auto; z-index: 1100;";

  // メッセージファイルから取得するための関数
  const { t } = useTranslation();

  const translateMessage = (messageId: string, args?: string[]) => {
    const baseText = t(`message.${messageId}`);
    return replaceMessageArgs(baseText, args);
  };

  function show(messageId: string, args?: string[]) {
    //スプラッシュメッセージを表示する処理
    const oldMsg = document.getElementById("splashMsg");
    if (oldMsg) {
      oldMsg.remove();
    }
    const newMsg = document.createElement("div");
    newMsg.className = "text-center position-fixed";
    newMsg.id = "splashMsg";
    newMsg.style.cssText = msgStyle;
    newMsg.innerText = translateMessage(messageId, args);
    newMsg.dataset.cy = "スプラッシュメッセージ";
    document.body.appendChild(newMsg);
    deleteMsg(newMsg);
  }

  //一定時間後、メッセージを削除する処理
  function deleteMsg(msg: HTMLDivElement) {
    setTimeout(() => msg.remove(), 1500);
  }

  return {
    show,
  };
};
