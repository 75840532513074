import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { CompanyGetResponse } from "src/model/api/response/CompanyGetResponse";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { CompanySuggestionsGetResponse } from "../../model/api/response/CompanySuggestionsGetResponse";
import { MaintenanceSchedulesGetResponse } from "../../model/api/response/MaintenanceSchedulesGetResponse";
import { RecommendsGetResponse } from "../../model/api/response/RecommendsGetResponse";
import { VesselGetResponse } from "../../model/api/response/VesselGetResponse";
import { VesselProductsGetResponse } from "../../model/api/response/VesselProductsGetResponse";
import { VesselSuggestionsGetResponse } from "../../model/api/response/VesselSuggestionsGetResponse";
import "../../react-tabs-custom.css";
import { RecommendStatusClassName, TextConst } from "../../util/Constant";
import { SelectOption, defaultSelectStyle } from "../../util/SelectUtil";
import { formatDate, getBizError } from "../../util/ecUtil";
import ImportShipParts from "./ImportShipParts";
import InspectionResult from "./InspectionResult";
import RecommendList from "./RecommendList";
import RepairCostSimulation from "./RepairCostSimulation";
import RepairSchedule from "./RepairSchedule";

/** 検索対象船舶未選択時*/
export const SELECT_VESSEL = "対象船舶を選択してください。";

function LcmTop() {
  // ユーザ情報
  const auth = useAuthUserContext();

  // API使用宣言
  const api = useApi();

  // ダイアログ使用宣言
  const showDialog = useDialog();

  // クエリパラメータ
  const searchQuery = useLocation().search;
  const query = new URLSearchParams(searchQuery);
  const queryImoNo = query.get("imono") ?? undefined; // IMO番号
  const queryCompanyCode = query.get("company-code") ?? undefined; // 得意先コード
  const queryShowContents = query.get("show-contents") ?? undefined; // 初期表示するタブ名
  // 表示年度リスト
  const yearCheck = [
    { value: 0, text: "2.5年単位" },
    { value: 1, text: "1年単位" },
  ];
  const [yearCheckValue, setYearCheckValue] = useState<number>(0);

  // 検索条件（コンボはundefinedが入る）
  type SearchValueType = {
    companyCode: string | undefined;
    imoNo: string | undefined;
  };

  type SearchValueLabelType = {
    companyName: string | undefined;
    vesselName: string | undefined;
  };

  const [vessel, setVessel] = useState<VesselGetResponse>();

  const defaultCompanyCode = () => {
    if (!auth.user().bemacFlg) {
      return auth.user().companyCode;
    }
    if (queryCompanyCode) {
      return queryCompanyCode;
    }

    return undefined;
  };

  const [searchValues, setSearchValues] = useState<SearchValueType>({
    companyCode: defaultCompanyCode(),
    imoNo: queryImoNo,
  });

  const [searchValueLabels, setSearchValueLabels] =
    useState<SearchValueLabelType>({
      companyName: auth.user().companyName,
      vesselName: "",
    });

  const [formChanged, setFormChanged] = useState<boolean>(false);

  //Bemac技師か否か
  const isBemacEngineer = auth.isEngineer();

  // state
  const [recommendData, setRecommendData] = useState<RecommendsGetResponse[]>(
    []
  ); //レコメンド
  const [tabNo, setTabNo] = useState<number>(); //タブ番号(開かれてるタブを判別するのに使用)
  const [showTab, setShowTab] = useState<number>(0); // 初期表示するタブ
  const [showFlg, setShowFlg] = useState<boolean>(); // 初期表示するタブ

  // メンテナンススケジュールリスト
  const [maintenaceScheduleDataList, setMaintenanceScheduleDataList] = useState<
    MaintenanceSchedulesGetResponse[]
  >([]);
  const [recommendGridText, setRecommendGridText] = useState<
    string | undefined
  >();

  // 交換推奨部品リスト
  const [vesselProductsGetResponse, setVesselProductsGetResponse] = useState<
    VesselProductsGetResponse[]
  >([]);

  // 子コンポ―へネントへの検索開始通知
  const [searchStartFlg, setSearchStartFlg] = useState(false);

  // 特定のタブへの遷移を表すフラグ
  const [directFlg, setDirectFlg] = useState(false);

  // 船名コンボ（船名キー入力ごとにAPI通信）
  const vesselLoadOptions = (inputValue: string) => {
    const companyCode = searchValues.companyCode ?? "";
    const vesselName = encodeURIComponent(inputValue);
    return api
      .get(
        `/api/v1/vessel-suggestions?companyCode=${companyCode}&vesselName=${vesselName}`
      )
      .then((it) => {
        return it.data.map((row: VesselSuggestionsGetResponse) => {
          return {
            label: row.shipName,
            value: row.imoNo,
          };
        });
      });
  };

  // 得意先コンボ（得意名キー入力ごとにAPI通信）
  const companyOptions = (inputValue: string) => {
    const companyName = encodeURIComponent(inputValue);
    return api
      .get(`/api/v1/company-suggestions?companyName=${companyName}`)
      .then((it) => {
        return it.data.map((row: CompanySuggestionsGetResponse) => {
          return {
            label: row.companyName,
            value: row.companyCode,
          };
        });
      });
  };

  // スタイル
  function statusButtonStyle(status: number) {
    // 選択中のステータスと一致していたらオレンジにする
    if (status === yearCheckValue) {
      return { backgroundColor: "#3778F4", color: "white" };
    } else {
      return { backgroundColor: "#E4E4E4", color: "black" };
    }
  }

  // 検索エリアコンボ変更
  function handleChangeSelect(
    name: string,
    e: SingleValue<SelectOption<string>>
  ) {
    // 選択値をセット
    const value = e?.value;
    setSearchValues({ ...searchValues, [name]: value });

    const label = e?.label;
    switch (name) {
      case "companyCode":
        setSearchValues({
          companyCode: value as string | undefined,
          imoNo: undefined,
        });
        searchVesselInfo(undefined);
        setSearchValueLabels({ companyName: label, vesselName: undefined });
        break;
      case "imoNo":
        setSearchValueLabels({ ...searchValueLabels, vesselName: label });
        // 対象船舶の情報を取得
        const imoNo = e?.value;
        searchVesselInfo(imoNo);
        break;
    }

    if (tabNo === 1) {
      searchPartsReplaceInfo(value as string | undefined);
    } else if (tabNo === 3) {
      searchRecommends(value);
    }
  }

  function searchPartsReplaceInfo(imoNo: string | undefined) {
    setSearchStartFlg(true);
    const promiseMaintenanceSchedule = api.get(
      `api/v1/vessels/${imoNo}/companies/${searchValues.companyCode}/maintenance-schedules`
    );

    const promiseVesselProduce = api.get(
      `/api/v1/vessels/${imoNo ? imoNo : "-1"}/products?companyCode=${
        searchValues.companyCode ? searchValues.companyCode : "-1"
      }`
    );

    Promise.all([promiseMaintenanceSchedule, promiseVesselProduce])
      .then((response) => {
        const maintenaceScheduleData = response[0].data.map(
          (row: MaintenanceSchedulesGetResponse) => {
            return row;
          }
        );

        const vesselProductData = response[1].data.map(
          (row: VesselProductsGetResponse) => {
            return row;
          }
        );
        setSearchStartFlg(false);
        setDirectFlg(false);
        setMaintenanceScheduleDataList(maintenaceScheduleData);
        setVesselProductsGetResponse(vesselProductData);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          showDialog({ id: "E073" });
        } else {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          } else {
            showDialog({ id: "E026" });
          }
        }
        setSearchStartFlg(false);
        setDirectFlg(false);
        setMaintenanceScheduleDataList([]);
        setVesselProductsGetResponse([]);
      });
  }

  //得意先コード・IMO番号で検索
  function searchRecommends(imoNo: string | undefined) {
    setSearchStartFlg(true);
    const queryParams: string[] = [];

    queryParams.push(`companyCode=${searchValues.companyCode ?? 0}`);

    queryParams.push(`imoNo=${imoNo ?? 0}`);

    const query = queryParams.join("&");

    //レコメンドリスト取得API実行
    api
      .get(`/api/v1/recommends?${query}`)
      .then((response) => {
        let result = response.data.map((it: RecommendsGetResponse) => {
          return {
            recommendId: it.recommendId,
            statusClassName: it.statusClassName,
            title: it.title,
            recommendDateTime: it.recommendDateTime
              ? formatDate(it.recommendDateTime, "YYYY/MM/DD")
              : "",
            dispatchPlace: it.dispatchPlace,
            dispatchDate: it.dispatchDate
              ? formatDate(it.dispatchDate, "YYYY/MM/DD")
              : "",
            quotationTotalPrice: it.quotationTotalPrice,
            orderTotalPrice: it.orderTotalPrice,
            cancelTotalPrice: it.cancelTotalPrice,
          };
        });
        //Bemac技師でない場合
        if (!isBemacEngineer) {
          result = result.filter(
            (it: RecommendsGetResponse) =>
              it.statusClassName !== RecommendStatusClassName.DRAFT
          );
        }
        // 検索結果をセット
        setSearchStartFlg(false);
        setRecommendData(result);
        if (!response.data.length) {
          //グリッドテキスト変更
          setRecommendGridText(TextConst.GRID_NO_DATA);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          showDialog({ id: "E073" });
        } else {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          } else {
            showDialog({ id: "E026" });
          }
        }
      });
  }

  // TabPanelスタイル
  const tabPanelStyle = {
    height: "calc(100vh - 310px)",
    width: "100%",
    overflow: "auto",
  };

  //タブ切り替え時にサンプルデータをセットしなおす処理
  function onSelectTabs(index: any) {
    const tabChange = (index: any) => {
      setTabNo(index);
      setShowTab(index);
      switch (index) {
        case 0:
          return;
        case 1:
          searchPartsReplaceInfo(searchValues.imoNo);
          return;
        case 2:
        case 3:
          return searchRecommends(searchValues.imoNo);
        case 4:
      }
    };

    if (formChanged) {
      showDialog({
        id: "I001",
        confirm: true,
        callback(isOk) {
          if (isOk) {
            setFormChanged(false);
            tabChange(index);
          }
        },
      });
    } else {
      setFormChanged(false);
      tabChange(index);
    }
  }

  function searchVesselInfo(imoNo?: string) {
    if (imoNo) {
      // 船舶情報検索
      api
        .get(`/api/v1/vessels/${imoNo}`)
        .then((response) => {
          setVessel(response.data);
          setSearchValueLabels((prev) => ({
            ...prev,
            vesselName: response.data.vesselName,
          }));
          setShowFlg(true);
        })
        .catch((error) => {
          setSearchValueLabels({ ...searchValueLabels, vesselName: "" });
          setShowFlg(true);
        });
    } else {
      setVessel(undefined);
      setSearchValueLabels({ ...searchValueLabels, vesselName: "" });
      setShowFlg(true);
    }
  }

  // 初回レンダリングのみ実行
  useEffect(() => {
    if (queryCompanyCode) {
      api
        .get<CompanyGetResponse>(
          `/api/v1/companies/${queryCompanyCode}?searchMode=0`
        )
        .then((response) => {
          setSearchValueLabels((prev) => ({
            ...prev,
            companyName: response.data.companyGetResponseSub1.companyName,
          }));
        })
        .catch((error) => {
          showDialog({ error });
        });
    }
    if (queryShowContents) {
      switch (queryShowContents) {
        case "repair-schedule":
          setTabNo(1);
          setShowTab(1);
          setDirectFlg(true);
          break;
        case "inspection-result":
          setTabNo(2);
          setShowTab(2);
          break;
        case "recommend":
          setTabNo(3);
          setShowTab(3);
          searchRecommends(searchValues.imoNo);
          break;
      }
    }
    searchVesselInfo(queryImoNo);
  }, []);

  return (
    <>
      {showFlg && (
        <div data-cy="LCMトップ">
          <div className="title-area-style">
            <span>LCM</span>
          </div>
          <div style={{ width: "95%", marginLeft: "30px" }}>
            <div className="my-3">
              <div
                className="row"
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                }}
              >
                <div className="col-4" style={{ padding: "4px 0px 4px 6px" }}>
                  <div className="input-group" data-cy="得意先コンボ">
                    <span
                      className="b-input-group-text"
                      style={{ width: "120px", height: "52px" }}
                    >
                      得意先名
                    </span>
                    {(auth.user().bemacFlg || auth.user().suFlg) && (
                      <span className="form-control">
                        <AsyncSelect
                          value={
                            !searchValues.companyCode
                              ? null
                              : {
                                  value: searchValues.companyCode,
                                  label: searchValueLabels.companyName,
                                }
                          }
                          styles={defaultSelectStyle}
                          placeholder={TextConst.COMBO_PLACEHOLDER}
                          isClearable
                          cacheOptions
                          defaultOptions
                          loadOptions={companyOptions}
                          onChange={(e) => {
                            handleChangeSelect("companyCode", e);
                          }}
                        />
                      </span>
                    )}
                    {!auth.user().bemacFlg && !auth.user().suFlg && (
                      <span
                        className="form-control"
                        data-cy="得意先名"
                        style={{ lineHeight: "38px" }}
                      >
                        {searchValueLabels.companyName}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-4" style={{ padding: "4px 0px 4px 6px" }}>
                  <div className="input-group" data-cy="船名コンボ">
                    <span
                      className="b-input-group-text"
                      style={{ width: "120px" }}
                    >
                      船名
                    </span>
                    <span className="form-control">
                      <AsyncSelect
                        value={
                          !searchValues.imoNo
                            ? null
                            : {
                                value: searchValues.imoNo,
                                label: searchValueLabels.vesselName,
                              }
                        }
                        styles={defaultSelectStyle}
                        placeholder={TextConst.COMBO_PLACEHOLDER}
                        isClearable
                        cacheOptions
                        defaultOptions
                        key={searchValues.companyCode}
                        loadOptions={vesselLoadOptions}
                        onChange={(e) => {
                          handleChangeSelect("imoNo", e);
                        }}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-2" style={{ padding: "4px 0px 4px 6px" }}>
                  <div className="input-group">
                    <span
                      className="b-input-group-text"
                      style={{ width: "120px", height: "52px" }}
                    >
                      船番
                    </span>
                    <span
                      className="form-control"
                      data-cy="船番"
                      style={{ lineHeight: "38px" }}
                    >
                      {vessel?.yardNo}
                    </span>
                  </div>
                </div>
                <div className="col-2" style={{ padding: "4px 6px 4px 6px" }}>
                  <div className="input-group">
                    <span
                      className="b-input-group-text"
                      style={{ width: "120px", height: "52px" }}
                    >
                      竣工年
                    </span>
                    <span
                      className="form-control"
                      data-cy="竣工年"
                      style={{ lineHeight: "38px" }}
                    >
                      {vessel?.year}
                    </span>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center mt-2">
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  表示年度
                </span>
                <div className="ms-2">
                  {yearCheck.map((it) => {
                    return (
                      <input
                        key={it.value}
                        type="button"
                        className="btn rounded-pill me-2"
                        style={statusButtonStyle(it.value)}
                        value={it.text}
                        onClick={(e) => setYearCheckValue(it.value)}
                        data-cy="表示年度ボタン"
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <Tabs
              data-cy="LCMタブ"
              onSelect={(lastTab) => {
                onSelectTabs(lastTab);
              }}
              selectedIndex={showTab}
            >
              <TabList data-cy="LCMタブリスト">
                <Tab data-cy="コスト推移タブ">Cost simulation</Tab>
                <Tab data-cy="部品交換リストタブ">Schedule</Tab>
                <Tab data-cy="定期点検結果タブ">Inspection result</Tab>
                <Tab data-cy="レコメンドタブ">Recommend item</Tab>
                {auth.isBemacAdmin() && (
                  <Tab data-cy="交換推奨部品登録タブ">交換推奨部品登録</Tab>
                )}
              </TabList>

              <TabPanel style={tabPanelStyle} data-cy="コスト推移タブパネル">
                <RepairCostSimulation
                  imoNo={searchValues.imoNo}
                  companyCode={searchValues.companyCode}
                  yearCheckValue={yearCheckValue}
                />
              </TabPanel>
              <TabPanel
                style={tabPanelStyle}
                data-cy="部品交換リストタブパネル"
              >
                <RepairSchedule
                  isDefaultTab={queryShowContents === "repair-schedule"}
                  companyCode={searchValues.companyCode}
                  companyName={searchValueLabels.companyName}
                  imoNo={searchValues.imoNo}
                  vesselName={searchValueLabels.vesselName}
                  vesselBuilder={vessel?.vesselBuilder}
                  yardNo={vessel?.yardNo}
                  maintenaceScheduleDataList={maintenaceScheduleDataList}
                  vesselProductsGetResponse={vesselProductsGetResponse}
                  search={() => searchPartsReplaceInfo(searchValues.imoNo)}
                  searchStartFlg={searchStartFlg}
                  directFlg={directFlg}
                  setFormChanged={(state: boolean) => setFormChanged(state)}
                  yearCheckValue={yearCheckValue}
                />
              </TabPanel>
              <TabPanel style={tabPanelStyle} data-cy="定期点検結果タブパネル">
                <InspectionResult
                  companyCode={searchValues.companyCode}
                  imoNo={searchValues.imoNo}
                  setFormChanged={(state: boolean) => setFormChanged(state)}
                  yearCheckValue={yearCheckValue}
                />
              </TabPanel>
              <TabPanel style={tabPanelStyle} data-cy="レコメンドタブパネル">
                <RecommendList
                  isDefaultTab={queryShowContents === "recommend"}
                  gridData={recommendData}
                  gridText={recommendGridText}
                  search={() => searchRecommends(searchValues.imoNo)}
                  searchValues={searchValues}
                  searchValueLabels={searchValueLabels}
                  searchStartFlg={searchStartFlg}
                />
              </TabPanel>
              {auth.isBemacAdmin() && (
                <TabPanel
                  style={tabPanelStyle}
                  data-cy="交換推奨部品登録タブパネル"
                >
                  <ImportShipParts
                    imoNo={searchValues.imoNo}
                    vesselName={searchValueLabels.vesselName}
                    yearCheckValue={yearCheckValue}
                  />
                </TabPanel>
              )}
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
}

export default LcmTop;
