import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { FiMail } from "react-icons/fi";
import { TextConst } from "src/util/Constant";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { useSplashMessage } from "../../hook/useSplashMessage";
import { ServiceNewsGetResponse } from "../../model/api/response/ServiceNewsGetResponse";
import { defaultColDef } from "../../util/AgGridUtil";
import { formatDate, getBizError } from "../../util/ecUtil";
import PageSizeSelector from "../common/PageSizeSelector";
import ServiceNewsDetail, {
  MODE_BROWSE,
  MODE_MASTER_NEW,
  MODE_MASTER_UPDATE,
  ServiceNewsDetailHandles,
} from "./ServiceNewsDetail";

/**サービスニュースのコンポーネント */
function ServiceNewsList(props: { isModal?: boolean }) {
  // 参照
  const gridRef = useRef<AgGridReact>(null); // グリッド
  const serviceNewsDetailRef = useRef<ServiceNewsDetailHandles>(null); // サービスニュース詳細

  // API使用宣言
  const api = useApi();
  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();
  //ダイアログ使用宣言
  const showDialog = useDialog();
  //ユーザID
  const userId = useAuthUserContext().user().userId;

  // 表示するサービスニュース
  const [serviceNewsData, setServiceNewsData] = useState<
    ServiceNewsGetResponse[]
  >([]);

  // 次画面用パラメータ
  const screenParam = props.isModal ? MODE_BROWSE : MODE_MASTER_UPDATE;

  // 初回のみ実行
  useEffect(() => {
    search();
  }, []);

  function search() {
    //表示モードの設定
    let showMode = 1; //管理
    if (props.isModal) {
      showMode = 0; //サービスニュースリストをモーダルで開いて要る場合は、閲覧モード
    }
    //サービスニュースリスト取得API
    api
      .get(`/api/v1/service-news?mode=${showMode}`)
      .then((response) => {
        const result = response.data.map((it: ServiceNewsGetResponse) => {
          return {
            serviceNewsId: it.serviceNewsId,
            sortNo: it.sortNo,
            title: it.title,
            startDate: it.startDate,
            endDate: it.endDate,
            url: it.url,
            isReadFlg: it.isReadFlg,
          };
        });
        setServiceNewsData(result);
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E026" });
        }
      });
  }

  // モードによって画面幅を変える
  let width = "w-75";
  if (props.isModal) {
    width = "w-100";
  }

  //削除処理
  function newsDelete() {
    // 選択された行を抽出
    const serviceNewsNoList = gridRef.current?.api
      .getSelectedRows()
      .map((row) => row.serviceNewsId);

    const count = serviceNewsNoList ? serviceNewsNoList.length : 0;

    //  1件も選択されてなければエラー
    if (count === 0) {
      showDialog({ id: "E023" });
      return;
    }

    // 確認ダイアログを表示
    showDialog({
      id: "I003",
      args: [count],
      confirm: true,
      callback: (isOk) => {
        if (isOk) {
          var request = {
            serviceNewsNoList,
          };
          // API実行
          api
            .post(`/api/v1/service-news:delete`, request)
            .then((response) => {
              splashMessage.show("I036");
              // 再検索
              search();
            })
            .catch((error) => {
              if (getBizError(error)) {
                //対応するエラーメッセージを表示
                showDialog({ error });
              } else {
                showDialog({ id: "E059" });
              }
            });
        }
      },
    });
  }

  //既読登録
  function readSubmit(isReadFlg: boolean, serviceNewsId: string) {
    // 閲覧モードの場合のみ、既読テーブルへ登録する
    if (!isReadFlg && props.isModal) {
      //サービスニュース既読登録API実行
      api
        .post(`api/v1/users/${userId}/service-news-read/${serviceNewsId}`)
        .then((it) => {})
        .catch((error) => {
          showDialog({
            id: error,
          });
        });
    }
  }

  //サービスニュース詳細が閉じたときの処理
  function handleCloseServiceNewsDetail() {
    search();
  }

  // グリッドの列定義
  const columnDefs = [
    {
      headerName: "",
      field: "serviceNewsId",
      hide: true,
    },
    {
      headerName: "",
      colId: "select",
      width: 100,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      hide: props.isModal,
    },
    {
      headerName: "",
      width: 100,
      field: "sortNo",
      hide: !props.isModal,
    },
    {
      headerName: "件名",
      field: "title",
      width: 480,
      sortable: true,
      cellRenderer: (params: any) => {
        return (
          <a
            href="#"
            className="text-link"
            onClick={() => {
              readSubmit(params.data.isReadFlg, params.data.serviceNewsId);
              serviceNewsDetailRef.current?.show(
                params.data.serviceNewsId,
                screenParam
              );
            }}
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: "掲載開始日",
      field: "startDate",
      width: 335,
      sortable: true,
      cellRenderer: (params: any) => {
        return formatDate(params.data.startDate, "YYYY/MM/DD");
      },
      hide: props.isModal,
    },
    {
      headerName: "掲載終了日",
      field: "endDate",
      width: 335,
      sortable: true,
      cellRenderer: (params: any) => {
        return formatDate(params.data.endDate, "YYYY/MM/DD");
      },
      hide: props.isModal,
    },
    {
      headerName: "未読/既読",
      field: "isReadFlg",
      width: 470,
      sortable: true,
      cellRenderer: (params: any) => {
        const readFlg = params.data.isReadFlg;
        if (readFlg === false) {
          return (
            <IconContext.Provider value={{ color: "#FFC000", size: "20px" }}>
              <span style={{ color: "#FFC000", fontSize: "1.1em" }}>未読</span>
              <FiMail className="ms-2" />
            </IconContext.Provider>
          );
        }
      },
      hide: !props.isModal,
    },
  ];

  // レンダリング
  return (
    <>
      <div className="b-container-list">
        {props.isModal ? (
          <></>
        ) : (
          <div className="title-area-style">
            <span>ServiceNews一覧</span>
          </div>
        )}
        <div
          className="ag-theme-alpine b-ag-deleted-row b-header-row-white"
          style={{ position: "relative", margin: "10px 30px 0px 30px" }}
          data-cy="サービスニュース一覧グリッド"
        >
          <AgGridReact
            domLayout="autoHeight"
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={serviceNewsData}
            pagination={true}
            paginationPageSize={10}
            ref={gridRef}
            rowSelection="multiple"
            overlayNoRowsTemplate={TextConst.GRID_NO_DATA}
          />
          <PageSizeSelector grid={gridRef.current} firstPageSize={10} />
        </div>
        {props.isModal ? (
          <></>
        ) : (
          <div className="text-center mt-2">
            <input
              type="button"
              className="btn b-btn-primary"
              value="ServiceNews登録"
              onClick={() =>
                serviceNewsDetailRef.current?.show(undefined, MODE_MASTER_NEW)
              }
              data-cy="登録ボタン"
            />
            <input
              type="button"
              className="btn b-btn-delete ms-3"
              value="削除"
              style={{ width: "120px" }}
              onClick={newsDelete}
              data-cy="削除ボタン"
            />
          </div>
        )}
        <ServiceNewsDetail
          onClosed={handleCloseServiceNewsDetail}
          ref={serviceNewsDetailRef}
        />
      </div>
    </>
  );
}

export default ServiceNewsList;
