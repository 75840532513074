import axios from "axios";

import useApi from "./useApi";

type HTTP_METHOD = "GET" | "PUT" | "DELETE";

const ENDPOINT = process.env.REACT_APP_S3_ENDPOINT;
const PUBLIC_BUCKET = process.env.REACT_APP_S3_PUBLIC_BUCKET;
const PRIVATE_BUCKET = process.env.REACT_APP_S3_PRIVATE_BUCKET;

export const useS3 = () => {
  const api = useApi();

  /**
   * S3のファイルURLとなるフルパスを取得
   * @param objectKey オブジェクトキー（例："sample/file1.txt"）
   * @param isPublic 公開バケットを対象とするかどうか（任意パラメータ、デフォルトfalse）
   * @returns S3のファイルURLとなるフルパス
   */
  function getS3Path(objectKey: string, isPublic?: boolean) {
    if (isPublic) {
      return `${ENDPOINT}/${PUBLIC_BUCKET}/${objectKey}`;
    } else {
      return `${ENDPOINT}/${PRIVATE_BUCKET}/${objectKey}`;
    }
  }

  /**
   * S3のファイルリスト取得
   * @param prefix ファイルパスのプリフィクス（例："sample/"）
   * @param isPublic 公開バケットを対象とするかどうか（任意パラメータ、デフォルトfalse）
   * @param withSubs サブフォルダも含むどうか（任意パラメータ、デフォルトfalse）
   */
  function getS3FileList(
    prefix: string,
    isPublic?: boolean,
    withSubs?: boolean
  ) {
    const isSec: boolean = !isPublic;
    const withSubParam = withSubs ? "&with_subs=true" : "";
    return api.get<string[]>(
      `/api/v1/aws/s3-objects?prefix=${prefix}&is_sec=${isSec}${withSubParam}`
    );
  }

  /**
   * 署名付きURL取得
   * @param method 取得目的のHTTPメソッド
   * @param objectKey オブジェクトキー
   * @param isPublic 公開バケットを対象とするかどうか（任意パラメータ、デフォルトfalse）
   */
  function getS3PresignedUrl(
    method: HTTP_METHOD,
    objectKey: string,
    isPublic?: boolean
  ) {
    const isSec: boolean = !isPublic;
    return api.get<string>(
      `/api/v1/aws/generate-presigned-url?method=${method}&key=${objectKey}&is_sec=${isSec}`
    );
  }

  /**
   * S3ファイル登録
   * @param objectKey オブジェクトキー
   * @param file ファイル
   * @param isPublic 公開バケットを対象とするかどうか（任意パラメータ、デフォルトfalse）
   */
  async function putS3File(objectKey: string, file: File, isPublic?: boolean) {
    // 署名付きURLを取得
    const response = await getS3PresignedUrl("PUT", objectKey, isPublic);
    // ファイル登録
    return axios.put<void>(response.data, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
  }

  /**
   * S3ファイル削除
   * @param objectKey オブジェクトキー
   * @param isPublic 公開バケットを対象とするかどうか（任意パラメータ、デフォルトfalse）
   */
  async function deleteS3File(objectKey: string, isPublic?: boolean) {
    // 署名付きURLを取得
    const response = await getS3PresignedUrl("DELETE", objectKey, isPublic);
    // ファイル削除
    return axios.delete<void>(response.data);
  }

  return {
    getS3Path,
    getS3FileList,
    getS3PresignedUrl,
    putS3File,
    deleteS3File,
  };
};
