const STORED_URL_KEY = "storedQuery";

function loadStoredUrls() {
  const storedValue = sessionStorage.getItem(STORED_URL_KEY) ?? "[]";
  return JSON.parse(storedValue) as string[];
}

export function storeUrl(url: string) {
  if (containsStoredUrls(url)) {
    return;
  }
  const urls = loadStoredUrls();
  urls.push(url);
  const json = JSON.stringify(urls);
  sessionStorage.setItem(STORED_URL_KEY, json);
}

export function containsStoredUrls(url: string) {
  const storedUrls = loadStoredUrls();
  return storedUrls.includes(url);
}
