import { createContext } from "react";

export class ApiCounter {
  private num = 0;

  increment() {
    this.num++;
    this.notificationChangedRequestCount();
  }

  decrement() {
    this.num--;
    this.notificationChangedRequestCount();
  }

  // 通信中かどうかの状態変更を通知するfunction
  receivers: ((isBusy: boolean) => void)[] = [];
  addReceiver(receiver: (isBusy: boolean) => void) {
    this.receivers.push(receiver);
  }
  // 通知する
  notificationChangedRequestCount() {
    this.receivers.forEach((it) => {
      it(this.num > 0);
    });
  }
}
const counter = new ApiCounter();
const ApiCountContext = createContext(counter);

export { ApiCountContext };
