import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import messageJaJson from "./locales/ja/message.json";

i18n.use(initReactI18next).init({
  fallbackLng: "ja",
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    ja: { translation: messageJaJson },
  },
});

export default i18n;
