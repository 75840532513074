import React from "react";

import { ApiCountContext, ApiCounter } from "./ApiCountProvider";
import { AuthUserProvider } from "./AuthUser";
import { DialogProvider } from "./DialogProvider";

type Props = {
  children: React.ReactNode;
};
export const Providers: React.FC<Props> = (props) => {
  const apiCounter = new ApiCounter();

  return (
    <>
      <ApiCountContext.Provider value={apiCounter}>
        <DialogProvider>
          <AuthUserProvider>{props.children}</AuthUserProvider>
        </DialogProvider>
      </ApiCountContext.Provider>
    </>
  );
};
