import { AgGridReact } from "ag-grid-react";
import { useState } from "react";

interface Props {
  grid: AgGridReact | null;
  firstPageSize?: number | 20;
}

function PageSizeSelector(props: Props) {
  const [size, setSize] = useState(props?.firstPageSize ?? 20);
  function onChangePageSize(value: number) {
    setSize(value);
    props.grid?.api.paginationSetPageSize(value);
  }

  return (
    <div style={{ position: "absolute", bottom: "12px", left: "10px" }}>
      <span style={{ fontSize: "14px" }}>表示件数：</span>
      <select
        onChange={(e) => onChangePageSize(Number(e.target.value))}
        defaultValue={size}
        data-cy="グリッド表示件数設定プルダウン"
      >
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
      </select>
    </div>
  );
}

export default PageSizeSelector;
