/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { useAuthUserContext } from "../../context/AuthUser";
import { TopSummaryGetResponse } from "../../model/api/response/TopSummaryGetResponse";
import { VesselSummaryGetResponse } from "../../model/api/response/VesselSummaryGetResponse";
import { OrderStatus, QuotationStatus } from "../../util/Constant";
import { CompQuoataionStatus } from "../order/OrderList";

type Props = {
  summary: TopSummaryGetResponse | VesselSummaryGetResponse | undefined; //個船情報 or Top情報
  height: string; //TaskListの高さ
  imoNo: string; // IMO
};

function TaskList(props: Props) {
  //ユーザ情報
  const authUser = useAuthUserContext().user();

  //TaskListのcss
  const taskListCss = css`
    :hover {
      .label {
        background-color: #e1e3ea;
      }
    }
  `;

  const taskStyle: React.CSSProperties = {
    backgroundColor: "#F4F5F8",
    width: "100%",
    height: "50%",
    position: "relative",
    left: "25px",
    borderRadius: "0 5px 5px 0px",
    boxSizing: "border-box",
  };

  //Taskを表示する
  const task = (name: string, id: string, num: number) => {
    return (
      <div
        className="row mt-2 "
        style={taskStyle}
        css={taskListCss}
        data-cy="タスク情報"
      >
        <div className="col-8 d-flex align-items-center label">{name}</div>
        {countBadge(num, id)}
      </div>
    );
  };

  //見積、注文の件数を表示する
  function countBadge(count: number, id: string) {
    return (
      <Nav.Item className="col-4 m-0 p-0">
        <Nav.Link
          as={NavLink}
          to={countBadgeUrl(count, id)}
          className="w-100 h-100 p-0 d-flex align-items-center justify-content-center"
          style={
            count
              ? id.match("bemacTaskList")
                ? {
                    backgroundColor: "#498EEA",
                    width: "70px",
                    borderRadius: "0 5px 5px 0",
                  }
                : {
                    backgroundColor: "#21B77D",
                    width: "70px",
                    borderRadius: "0 5px 5px 0",
                  }
              : {
                  backgroundColor: "#A3A6AD",
                  width: "70px",
                  borderRadius: "0 5px 5px 0",
                }
          }
          data-cy="タスク件数リンク"
        >
          {count}件
        </Nav.Link>
      </Nav.Item>
    );
  }

  //見積・注文件数部分クリック時のURL
  const countBadgeUrl = (count: number, id: string): string => {
    switch (id) {
      case "customerTaskListQuotation":
        return (
          `/quotation-list?imono=${props.imoNo}` +
          (count === 0
            ? ""
            : !authUser.bemacFlg
            ? `&status=${QuotationStatus.DRAFT},${QuotationStatus.ANSWERED}`
            : `&status=${QuotationStatus.ANSWERED}`)
        );

      case "customerTaskListOrder":
        return (
          `/order-list?imono=${props.imoNo}` +
          (count === 0
            ? ""
            : `&status=${OrderStatus.DRAFT},${OrderStatus.REQUESTED_CHANGE}`)
        );

      case "customerTaskListRequestCompNum":
        return (
          `/order-list?imono=${props.imoNo}` +
          (count === 0 ? "" : `&comp-status=${CompQuoataionStatus.REQ_COMP}`)
        );

      case "bemacTaskListQuotation":
        return (
          `/quotation-list?imono=${props.imoNo}` +
          (count === 0
            ? ""
            : authUser.bemacFlg
            ? `&status=${QuotationStatus.DRAFT},${QuotationStatus.REQUESTED}`
            : `&status=${QuotationStatus.REQUESTED}`)
        );

      case "bemacTaskListUnAnsweredOrder":
        return (
          `/order-list?imono=${props.imoNo}` +
          (count === 0
            ? ""
            : `&status=${OrderStatus.ORDERED},${OrderStatus.REQUESTED_CANCEL}`)
        );
      case "bemacTaskListArrangingOrder":
        return (
          `/order-list?imono=${props.imoNo}` +
          (count === 0 ? "" : `&status=${OrderStatus.ARRANGING}`)
        );

      case "bemacTaskListNotCompNum":
        return (
          `/order-list?imono=${props.imoNo}` +
          (count === 0 ? "" : `&comp-status=${CompQuoataionStatus.NOT_COMP}`)
        );
      default:
        return "";
    }
  };

  return (
    <div
      className={`row my-1 ${!props.imoNo ? "ms-1" : ""}`}
      style={{
        backgroundColor: "white",
        borderRadius: "15px",
        height: props.height,
        width: "100%",
        boxShadow: "6px 10px 20px #0000000D",
      }}
    >
      {/* CUSTOMERカード*/}
      <div className="m-0 col-6" style={{ padding: "1% 3% 1% 0%" }}>
        <div className="p-2 fs-5 d-flex justify-content-between">
          <span
            style={{
              fontSize: "80%",
              fontWeight: "bold",
              whiteSpace: "nowrap",
            }}
          >
            TASK LIST
          </span>
          <span
            style={{
              color: "#21B77D",
            }}
          >
            CUSTOMER
          </span>
        </div>
        <div style={{ height: "45%" }}>
          {task(
            "見積",
            "customerTaskListQuotation",
            props.summary?.customerTaskListQuotationNum!
          )}
          {task(
            "注文",
            "customerTaskListOrder",
            props.summary?.customerTaskListOrderNum!
          )}
          {task(
            "御請求書",
            "customerTaskListRequestCompNum",
            props.summary?.customerTaskListRequestCompNum!
          )}
        </div>
      </div>

      {/* BEMACカード */}
      <div className="m-0 col-6" style={{ padding: "1% 5% 1% 0%" }}>
        <div
          className="p-2 fs-5 d-flex justify-content-end"
          style={{
            color: "#498EEA",
          }}
          id="bemacTaskList"
        >
          <span>BEMAC</span>
        </div>
        <div style={{ height: !authUser.bemacFlg ? "45%" : "32%" }}>
          {task(
            "見積未回答",
            "bemacTaskListQuotation",
            props.summary?.bemacTaskListQuotationNum!
          )}
          {task(
            "注文未回答",
            "bemacTaskListUnAnsweredOrder",
            props.summary?.bemacTaskListUnAnsweredOrderNum!
          )}
          {task(
            "手配中",
            "bemacTaskListArrangingOrder",
            props.summary?.bemacTaskListArrangingOrderNum!
          )}
          {authUser.bemacFlg &&
            task(
              "完工見積",
              "bemacTaskListNotCompNum",
              props.summary?.bemacTaskListNotCompNum!
            )}
        </div>
      </div>
    </div>
  );
}

export default TaskList;
