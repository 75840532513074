import { AgGridReact } from "ag-grid-react";
import { useState } from "react";
import { ChangeEvent, useEffect, useRef } from "react";
import { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { useDialog } from "src/context/DialogProvider";
import { OrderGetResponse } from "src/model/api/response/OrderGetResponse";
import { LcmCalendarStatus, TextConst } from "src/util/Constant";

import { useAuthUserContext } from "../../context/AuthUser";
import useApi from "../../hook/useApi";
import { CompanySuggestionsGetResponse } from "../../model/api/response/CompanySuggestionsGetResponse";
import { LcmCalendarsGetResponse } from "../../model/api/response/LcmCalendarsGetResponse";
import { defaultColDef } from "../../util/AgGridUtil";
import { SelectOption, defaultSelectStyle } from "../../util/SelectUtil";
import { formatDate, getBizError } from "../../util/ecUtil";
import PageSizeSelector from "../common/PageSizeSelector";
import SearchArea from "../common/SearchArea";
import OrderDetail, { OrderDetailHandles } from "../order/OrderDetail";
import QuotationDetail, {
  QuotationDetailHandles,
} from "../order/QuotationDetail";

/** メンテナンス一覧コンポーネント */
function LcmCalendarList() {
  // 参照
  const gridRef = useRef<AgGridReact<LcmCalendarsGetResponse>>(null); // グリッド
  const quotationDetailRef = useRef<QuotationDetailHandles>(null);
  const orderDetailRef = useRef<OrderDetailHandles>(null);

  // API使用宣言
  const api = useApi();
  // ユーザ情報
  const auth = useAuthUserContext();

  // 検索条件（コンボはundefinedが入る）
  type SearchValueType = {
    imoNo: string | undefined;
    yardNo: string | undefined;
    companyCode: string | undefined;
    vesselName: string | undefined;
  };
  const [searchValues, setSearchValues] = useState<SearchValueType>({
    imoNo: "",
    yardNo: "",
    vesselName: "",
    companyCode: "",
  });

  // 得意先コンボ（得意名キー入力ごとにAPI通信）
  const companyOptions = (inputValue: string) => {
    const companyName = encodeURIComponent(inputValue);
    return api
      .get(
        `/api/v1/company-suggestions?companyName=${companyName}&searchDelCompanyFlg=true`
      )
      .then((it) => {
        return it.data.map((row: CompanySuggestionsGetResponse) => {
          return {
            label: row.companyName,
            value: row.companyCode,
          };
        });
      });
  };

  // 検索エリアテキストボックス変更
  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.value;
    // 入力値をセット
    setSearchValues({ ...searchValues, [name]: value });
  }

  // 検索エリアコンボ変更
  function handleChangeSelect(
    name: string,
    e: SingleValue<SelectOption<string | number>>
  ) {
    // 選択値をセット
    const value = e?.value;
    setSearchValues((prev) => {
      return { ...prev, [name]: value };
    });
  }

  // 検索ボタンクリック
  function handleClickSearchButton() {
    search();
  }

  // 注文情報
  const [orderData, setOrderData] = useState<OrderGetResponse>();

  // state
  // 検索前のグリッドのテキスト
  const [noRowsText, setNoRowsText] = useState(TextConst.GRID_INITIAL);

  //ダイアログ仕様宣言
  const showDialog = useDialog();

  // 一覧データ
  const [data, setData] = useState<LcmCalendarsGetResponse[]>([]);

  // 検索条件アコーディオン開閉状態
  const [showConditionAccordion, setShowConditionAccordion] = useState(true);

  // 検索処理
  function search(afterProc?: (data: LcmCalendarsGetResponse[]) => void) {
    const queryParams: string[] = [];
    //検索条件を設定
    if (searchValues.imoNo) {
      queryParams.push(`imoNo=${searchValues.imoNo}`);
    }
    if (searchValues.vesselName) {
      queryParams.push(`vesselName=${searchValues.vesselName}`);
    }
    if (searchValues.yardNo) {
      queryParams.push(`yardNo=${searchValues.yardNo}`);
    }
    if (searchValues.companyCode) {
      queryParams.push(`companyCode=${searchValues.companyCode}`);
    }
    const query = queryParams.join("&");
    //LCMカレンダー一覧取得APIを呼び出し
    api
      .get<LcmCalendarsGetResponse[]>(`/api/v1/lcm-calendars?${query}`)
      .then((response) => {
        // グリッドテキスト変更
        setNoRowsText(TextConst.GRID_NO_DATA);
        // 検索結果をセット
        setData(response.data);
        // 検索条件アコーディオンを開閉を設定
        if (response.data.length > 0) {
          setShowConditionAccordion(false);
        } else {
          setShowConditionAccordion(true);
        }
        // 後処理が指定されていたら実行
        if (afterProc) {
          afterProc(response.data);
        }
      })
      .catch((error) => {
        // エラー時の処理
        if (error.response.status !== 404) {
          showDialog({ error });
        }
      });
  }

  // 初回のみ実行
  useEffect(() => {
    search();
  }, []);

  // ドックスケジュールの表示
  function dockSchedule(dockScheduleStart: Date, dockScheduleEnd: Date) {
    if (dockScheduleStart !== null && dockScheduleEnd !== null) {
      return (
        formatDate(dockScheduleStart, "YYYY/MM/DD") +
        "～" +
        formatDate(dockScheduleEnd, "YYYY/MM/DD")
      );
    } else if (dockScheduleStart !== null && dockScheduleEnd === null) {
      return formatDate(dockScheduleStart, "YYYY/MM/DD") + "～";
    } else if (dockScheduleStart === null && dockScheduleEnd !== null) {
      return "～" + formatDate(dockScheduleEnd, "YYYY/MM/DD");
    } else {
      return "未定";
    }
  }

  // グリッドの列定義
  const columnDefs = [
    {
      headerName: "",
      children: [
        {
          headerName: "ドックスケジュール",
          field: "dockSchedule",
          width: 220,
          cellStyle: { textAlign: "left" },
          sortable: true,
          headerClass: ["b-header-cell-left"],
          cellRenderer: (params: any) => {
            return (
              <>
                {dockSchedule(
                  params.data?.dockScheduleStart,
                  params.data?.dockScheduleEnd
                )}
              </>
            );
          },
        },
        {
          headerName: "船名",
          field: "vesselName",
          width: 220,
          sortable: true,
          headerClass: ["b-header-cell-left"],
        },
        {
          headerName: "会社名",
          field: "companyName",
          width: 220,
          cellStyle: { textAlign: "left" },
          sortable: true,
          headerClass: ["b-header-cell-left"],
          hide: !auth.user().bemacFlg && !auth.user().suFlg,
        },
        {
          headerName: "お客様ご担当者",
          field: "customerName",
          width: 140,
          cellStyle: { textAlign: "left" },
          sortable: true,
          headerClass: ["b-header-cell-left"],
          cellRenderer: customerNameCell,
        },
      ],
    },
    {
      headerName: "ステータス",
      children: [
        {
          headerName: "計画中",
          field: "planed",
          width: 80,
          cellStyle: { textAlign: "center" },
          sortable: true,
          headerClass: ["b-header-cell-left"],
          cellRenderer: (params: any) => {
            return (
              <>
                {params.data?.lcmStatus === LcmCalendarStatus.PLAN ||
                params.data?.lcmStatus === LcmCalendarStatus.QUOTATION ||
                params.data?.lcmStatus === LcmCalendarStatus.NON_ORDER ||
                params.data?.lcmStatus === LcmCalendarStatus.ORDER ||
                params.data?.lcmStatus === LcmCalendarStatus.DELIVERY ||
                params.data?.lcmStatus === LcmCalendarStatus.COMPLETE
                  ? "●"
                  : ""}
              </>
            );
          },
        },
        {
          headerName: "引合中",
          field: "answered",
          width: 80,
          cellStyle: { textAlign: "center" },
          sortable: true,
          headerClass: ["b-header-cell-left"],
          hide: !auth.user().bemacFlg,
          cellRenderer: (params: any) => {
            return (
              <>
                {params.data?.lcmStatus !== LcmCalendarStatus.PLAN &&
                  params.data?.quotationNo && (
                    <a
                      href="#"
                      className="text-link"
                      onClick={() =>
                        onClickQuotationLink(params.data.quotationNo)
                      }
                    >
                      ●
                    </a>
                  )}
              </>
            );
          },
        },
        {
          headerName: auth.user().bemacFlg ? "受注済" : "発注済",
          field: "order",
          width: 80,
          cellStyle: { textAlign: "center" },
          sortable: true,
          headerClass: ["b-header-cell-left"],
          cellRenderer: (params: any) => {
            return (
              <>
                {params.data?.lcmStatus === LcmCalendarStatus.ORDER &&
                  params.data?.orderNo && (
                    <a
                      href="#"
                      className="text-link"
                      onClick={() => onClickOrderLink(params.data.orderNo)}
                    >
                      ●
                    </a>
                  )}
                {params.data?.lcmStatus !== LcmCalendarStatus.ORDER &&
                  (params.data?.lcmStatus === LcmCalendarStatus.DELIVERY ||
                  params.data?.lcmStatus === LcmCalendarStatus.COMPLETE
                    ? "●"
                    : params.data?.lcmStatus === LcmCalendarStatus.NON_ORDER
                    ? "-"
                    : "")}
              </>
            );
          },
        },
        {
          headerName: "発送済",
          field: "delivery",
          width: 80,
          cellStyle: { textAlign: "center" },
          sortable: true,
          headerClass: ["b-header-cell-left"],
          cellRenderer: (params: any) => {
            return (
              <>
                {params.data?.lcmStatus === LcmCalendarStatus.DELIVERY &&
                  params.data?.orderNo && (
                    <a
                      href="#"
                      className="text-link"
                      onClick={() => onClickOrderLink(params.data.orderNo)}
                    >
                      ●
                    </a>
                  )}
                {params.data?.lcmStatus !== LcmCalendarStatus.DELIVERY &&
                  (params.data?.lcmStatus === LcmCalendarStatus.COMPLETE
                    ? "●"
                    : "")}
              </>
            );
          },
        },
        {
          headerName: "工事済",
          field: "dispatch",
          width: 80,
          cellStyle: { textAlign: "center" },
          sortable: true,
          headerClass: ["b-header-cell-left"],
          cellRenderer: (params: any) => {
            return (
              <>
                {params.data?.lcmStatus === LcmCalendarStatus.COMPLETE &&
                  params.data?.orderNo && (
                    <a
                      href="#"
                      className="text-link"
                      onClick={() => onClickOrderLink(params.data.orderNo)}
                    >
                      ●
                    </a>
                  )}
              </>
            );
          },
        },
      ],
    },
    {
      headerName: "",
      children: [
        {
          headerName: "ドック場所",
          field: "dockName",
          width: 220,
          cellStyle: { textAlign: "left" },
          sortable: true,
          headerClass: ["b-header-cell-left"],
        },
        {
          headerName: "BEMAC担当者",
          field: "replyUserName",
          cellStyle: { textAlign: "left" },
          sortable: true,
          headerClass: ["b-header-cell-left"],
          cellRenderer: replyUserNameCell,
        },
      ],
    },
  ];

  const colSingle =
    auth.user().bemacFlg || auth.user().suFlg ? "col-3 d-flex" : "col-4 d-flex";

  // お客様ご担当者の表示分け
  function customerNameCell(params: any) {
    switch (params.data?.lcmStatus) {
      case LcmCalendarStatus.QUOTATION:
        return params.data?.quotationUserName;
      case LcmCalendarStatus.ORDER:
      case LcmCalendarStatus.DELIVERY:
      case LcmCalendarStatus.COMPLETE:
        return params.data?.orderUserName;
      case LcmCalendarStatus.NON_ORDER:
        return params.data?.orderNo
          ? params.data?.orderUserName
          : params.data?.quotationUserName;
      default:
        return undefined;
    }
  }

  // BEMAC担当者の表示分け
  function replyUserNameCell(params: any) {
    switch (params.data?.lcmStatus) {
      case LcmCalendarStatus.QUOTATION:
        return params.data?.quotationReplyUserName;
      case LcmCalendarStatus.ORDER:
      case LcmCalendarStatus.DELIVERY:
      case LcmCalendarStatus.COMPLETE:
        return params.data?.orderReplyUserName;
      case LcmCalendarStatus.NON_ORDER:
        return params.data?.orderNo
          ? params.data?.orderReplyUserName
          : params.data?.quotationReplyUserName;
      default:
        return undefined;
    }
  }
  // 検索条件エリア
  function searchArea() {
    return (
      <div>
        <div className="row">
          <div className={colSingle}>
            <span className="col-form-label px-2 text-nowrap">IMO</span>
            <input
              type="text"
              name="imoNo"
              className="form-control border-0"
              placeholder={TextConst.INPUT_PLACEHOLDER}
              maxLength={12}
              onChange={handleChangeInput}
              data-cy="IMOテキスト"
            />
          </div>
          <div className={colSingle}>
            <span className="col-form-label px-2 text-nowrap">造船所番号</span>
            <input
              type="text"
              name="yardNo"
              className="form-control border-0"
              placeholder={TextConst.INPUT_PLACEHOLDER}
              maxLength={20}
              onChange={handleChangeInput}
              data-cy="造船所番号テキスト"
            />
          </div>
          <div className={colSingle}>
            <span className="col-form-label px-2 text-nowrap">船名</span>
            <input
              type="text"
              name="vesselName"
              className="form-control border-0"
              placeholder={TextConst.INPUT_PLACEHOLDER}
              maxLength={100}
              onChange={handleChangeInput}
              data-cy="船名テキスト"
            />
          </div>
          {(auth.user().bemacFlg || auth.user().suFlg) && (
            <div className={colSingle}>
              <span className="col-form-label px-2 text-nowrap">会社名</span>
              <span className="flex-grow-1" data-cy="会社名コンボ">
                <AsyncSelect
                  styles={defaultSelectStyle}
                  placeholder={TextConst.COMBO_PLACEHOLDER}
                  isClearable
                  cacheOptions
                  defaultOptions
                  loadOptions={companyOptions}
                  onChange={(e) => {
                    handleChangeSelect("companyCode", e);
                  }}
                />
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  //見積リンク押下時の処理
  function onClickQuotationLink(quotationNo: number) {
    // 該当の見積が存在するか確認
    // 見積番号が設定されていたらAPIから初期化する
    api
      .get(`/api/v1/quotations/${quotationNo}`)
      .then(() => {
        quotationDetailRef.current?.show({
          quotationNo: quotationNo,
        });
      })
      .catch((error) => {
        // エラー時の処理
        if (error.response.status === 404) {
          //エラーメッセージの表示
          showDialog({ id: "E077" });
        } else {
          showDialog({ error });
        }
      });
  }

  //注文番号リンク押下時の処理
  function onClickOrderLink(orderNo: number) {
    // 該当の注文が存在するか確認
    // 見積番号が設定されていたらAPIから初期化する
    api
      .get(`/api/v1/orders/${orderNo}`)
      .then(() => {
        orderDetailRef.current?.show({ orderNo: orderNo });
      })
      .catch((error) => {
        // エラー時の処理
        if (error.response.status === 404) {
          //エラーメッセージの表示
          showDialog({ id: "E078" });
        } else {
          showDialog({ error });
        }
      });
  }

  // 見積・注文詳細画面保存ボタンクリック
  function handleClickSaveButton() {
    // 再検索
    search();
  }

  // レンダリング
  return (
    <div>
      <div className="title-area-style">
        <span>メンテナンス一覧</span>
      </div>
      <SearchArea
        show={showConditionAccordion}
        onChangeShow={(value) => {
          setShowConditionAccordion(value);
        }}
      >
        {searchArea()}
        <div className="text-center">
          <input
            type="button"
            className="btn b-btn-primary"
            value={"検索"}
            onClick={handleClickSearchButton}
            data-cy="検索ボタン"
          />
        </div>
      </SearchArea>

      <div
        className="ag-theme-alpine b-header-row-white"
        style={{ position: "relative", margin: "10px 30px 0px 30px" }}
        data-cy="LCMカレンダー一覧グリッド"
      >
        <AgGridReact
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={data}
          pagination={true}
          paginationPageSize={20}
          ref={gridRef}
          rowSelection="multiple"
          overlayNoRowsTemplate={noRowsText}
        />
        <PageSizeSelector grid={gridRef.current} />
      </div>
      <QuotationDetail
        ref={quotationDetailRef}
        onClickSaveButton={handleClickSaveButton}
      />
      <OrderDetail
        ref={orderDetailRef}
        onClickSaveButton={handleClickSaveButton}
      />
    </div>
  );
}

export default LcmCalendarList;
