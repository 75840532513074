import { BaseLayout } from "../component/BaseLayout";
import { AuthGuard } from "./AuthGuard";

type Props = {
  component: React.ReactNode;
};

function AuthGuardRoute(props: Props) {
  return (
    <AuthGuard
      component={<BaseLayout component={props.component} />}
      redirect="/login"
    />
  );
}

export default AuthGuardRoute;
