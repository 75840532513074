import React, { createContext, useContext, useState } from "react";

import { UserInfo } from "../type/UserInfo";
import { BemacUserClassValue } from "../util/Constant";
import {
  clearStorageLogin,
  hasUserInfo,
  isWithinExpirationDate,
  loadUserInfo,
  saveUserInfo,
} from "../util/LoginUtil";

export type AuthUserContextType = {
  user: () => UserInfo;
  hasAuth: () => boolean;
  signin: (user: UserInfo, callback?: () => void) => void;
  signout: (callback: () => void) => void;
  isEngineer: () => boolean;
  isBemacAdmin: () => boolean;
  isCustomerAdmin: () => boolean;
};

const AuthUserContext = createContext<AuthUserContextType>(
  {} as AuthUserContextType
);

export const useAuthUserContext = (): AuthUserContextType => {
  return useContext<AuthUserContextType>(AuthUserContext);
};

type Props = {
  children: React.ReactNode;
};

export const AuthUserProvider = (props: Props) => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(() => {
    if (hasUserInfo()) {
      return loadUserInfo();
    } else {
      return null;
    }
  });

  const user = () => {
    return userInfo!;
  };

  const hasAuth = () => {
    return isWithinExpirationDate() && userInfo !== null;
  };

  const signin = (newUser: UserInfo, callback?: () => void) => {
    saveUserInfo(newUser);
    setUserInfo(newUser);
    if (callback) {
      callback();
    }
  };

  const signout = (callback: () => void) => {
    clearStorageLogin();
    setUserInfo(null);
    callback();
  };

  const isEngineer = () => {
    if (userInfo) {
      return [
        BemacUserClassValue.ENGINEER,
        BemacUserClassValue.ENGINEER_MANAGER,
      ].includes(userInfo.bemacUserClassValue);
    } else {
      return false;
    }
  };

  const isBemacAdmin = () => {
    if (userInfo) {
      return userInfo.adminFlg && userInfo.bemacFlg;
    } else {
      return false;
    }
  };

  const isCustomerAdmin = () => {
    if (userInfo) {
      return userInfo.adminFlg && !userInfo.bemacFlg;
    } else {
      return false;
    }
  };

  const value: AuthUserContextType = {
    user,
    hasAuth,
    signin,
    signout,
    isEngineer,
    isBemacAdmin,
    isCustomerAdmin,
  };
  return (
    <AuthUserContext.Provider value={value}>
      {props.children}
    </AuthUserContext.Provider>
  );
};
