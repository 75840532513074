import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, useEffect, useRef, useState } from "react";

import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { useSplashMessage } from "../../hook/useSplashMessage";
import { ClassValuesGetResponse } from "../../model/api/response/ClassValuesGetResponse";
import { VesselsGetResponse } from "../../model/api/response/VesselsGetResponse";
import { defaultColDef } from "../../util/AgGridUtil";
import { TextConst } from "../../util/Constant";
import { getBizError } from "../../util/ecUtil";
import PageSizeSelector from "../common/PageSizeSelector";
import SearchArea from "../common/SearchArea";
import TooltipIcon from "../common/TooltipIcon";
import ShipDetail, { shipDetailHandles } from "./ShipDetail";

/** 船舶一覧コンポーネント */
function ShipList(props: { selectable?: boolean; isModal?: boolean }) {
  //state
  const [data, setData] = useState<VesselsGetResponse[]>([]); // 表示する船舶リスト
  const [showConditionAccordion, setShowConditionAccordion] = useState(true); // 検索条件アコーディオン開閉状態
  const [lastImportDateTime, setLastImportDateTime] = useState();

  // 検索前のグリッドのテキスト
  const [noRowsText, setNoRowsText] = useState(TextConst.GRID_INITIAL);

  //参照
  const gridRef = useRef<AgGridReact>(null); //明細
  const shipDetailRef = useRef<shipDetailHandles>(null); //船舶詳細

  // API使用宣言
  const api = useApi();
  //ダイアログ使用宣言
  const showDialog = useDialog();
  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();

  // 検索条件
  type SearchValueType = {
    imoNo: string;
    vesselName: string;
    vesselBuilder: string;
    startYear: string;
    endYear: string;
  };
  const [searchValues, setSearchValues] = useState<SearchValueType>({
    imoNo: "",
    vesselName: "",
    vesselBuilder: "",
    startYear: "",
    endYear: "",
  });

  //グリッドの列定義
  const columnDefs = [
    {
      headerName: "IMO",
      field: "imoShipNo",
      width: 150,
      sortable: true,
      cellRenderer: (params: any) => {
        return (
          <a
            href="#"
            className="text-link"
            onClick={() => shipDetailRef.current?.show(params.value)}
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: "船名",
      field: "vesselName",
      width: 350,
      sortable: true,
    },
    {
      headerName: "船番",
      field: "yardNo",
      width: 150,
      sortable: true,
    },
    {
      headerName: "竣工年",
      field: "year",
      width: 150,
      sortable: true,
    },
    {
      headerName: "造船所",
      field: "shipBuilder",
      width: 450,
      sortable: true,
    },
  ];

  // 初回レンダリング
  useEffect(() => {
    setImportDateTime();
  }, []);

  // 検索エリアテキストボックス変更
  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.value;
    // 入力値をセット
    setSearchValues({ ...searchValues, [name]: value });
  }

  // 検索処理
  function search() {
    // 検索条件を設定
    const queryParams: string[] = [];
    if (searchValues.imoNo) {
      queryParams.push(`imoNo=${searchValues.imoNo}`);
    }
    if (searchValues.vesselName) {
      queryParams.push(`vesselName=${searchValues.vesselName}`);
    }
    if (searchValues.vesselBuilder) {
      queryParams.push(`vesselBuilder=${searchValues.vesselBuilder}`);
    }
    if (searchValues.startYear) {
      queryParams.push(`startYear=${searchValues.startYear}`);
    }
    if (searchValues.endYear) {
      queryParams.push(`endYear=${searchValues.endYear}`);
    }
    const query = queryParams.join("&");
    api
      .get(`/api/v1/vessels?${query}`)
      .then((response) => {
        const result = response.data.map((it: VesselsGetResponse) => {
          return {
            imoShipNo: it.imoNo,
            vesselName: it.vesselName,
            vesselBuilder: it.vesselBuilder,
            yardNo: it.yardNo,
            year: it.year,
            groupOwner: it.groupOwner,
            registeredOwner: it.regesteredOwner,
            shipBuilder: it.vesselBuilder,
          };
        });
        // グリッドテキスト変更
        setNoRowsText(TextConst.GRID_NO_DATA);
        // 検索結果をセット
        setData(result);
        // 検索条件アコーディオンを閉じる
        if (response.data.length > 0) {
          setShowConditionAccordion(false);
        }
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E073" });
        }
      });
  }

  // CSV取り込み成功日時取得
  function setImportDateTime() {
    api
      .get(`/api/v1/class/csv_upload_datetime/values`)
      .then((response) => {
        const importDateTime = response.data.filter(
          (it: ClassValuesGetResponse) => it.classValue === "VESSEL"
        )[0].className;
        setLastImportDateTime(importDateTime);
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E010" });
        }
      });
  }

  //検索ボタンクリック
  function onClickSearchButton() {
    search();
  }

  //ファイルを選択し、開くを押した後の処理
  function selectFile(e: React.ChangeEvent<HTMLInputElement>) {
    let formData = new FormData();
    formData.append("vesselsCsv", e.currentTarget.files![0]);
    api
      .post(`/api/v1/vessels-csv`, formData)
      .then((it) => {
        splashMessage.show("I044");
        setImportDateTime();
        search();
      })
      .catch((error) => {
        // csvエラーファイルが返ってきているかどうか;
        if (error.response) {
          if (error.response.data !== "") {
            //文字化け回避
            const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
            const csv = new Blob([bom, error.response.data], {
              type: "text/csv",
            });
            //aタグ作成
            const a = document.createElement("a");
            // ダウンロードされるファイル名
            a.download = "エラーファイル_船舶マスタ取込.csv";
            a.href = URL.createObjectURL(csv);
            // ダウンロード開始
            a.click();
          } else {
            if (getBizError(error)) {
              //対応するエラーメッセージを表示
              showDialog({ error });
            } else {
              showDialog({ id: "E010" });
            }
          }
        } else {
          showDialog({ id: "E010" });
          return;
        }
      });
  }

  //レンダリング
  return (
    <>
      <div className="b-container-list">
        <div className="title-area-style">
          <span>船舶一覧</span>
        </div>
        <SearchArea
          show={showConditionAccordion}
          toolTip={<TooltipIcon messageId="T001" args={[100, "IMO"]} />}
          onChangeShow={(value) => {
            setShowConditionAccordion(value);
          }}
        >
          <div className="row">
            <div className="col d-flex">
              <span className="col-form-label px-2">IMO</span>
              <input
                type="text"
                name="imoNo"
                className="form-control border-0"
                placeholder={TextConst.INPUT_PLACEHOLDER}
                maxLength={10}
                onChange={handleChangeInput}
                data-cy="IMOテキスト"
              />
            </div>
            <div className="col d-flex">
              <span className="text-nowrap col-form-label px-2">船名</span>
              <input
                type="text"
                name="vesselName"
                className="form-control border-0"
                placeholder={TextConst.INPUT_PLACEHOLDER}
                maxLength={100}
                onChange={handleChangeInput}
                data-cy="船名テキスト"
              />
            </div>
            <div className="col d-flex">
              <span className="text-nowrap col-form-label px-2">造船所</span>
              <input
                type="text"
                name="vesselBuilder"
                className="form-control border-0"
                placeholder={TextConst.INPUT_PLACEHOLDER}
                maxLength={50}
                onChange={handleChangeInput}
                data-cy="造船所テキスト"
              ></input>
            </div>
            <div className="col-4 d-flex">
              <span className="text-nowrap col-form-label px-2">竣工年</span>
              <div className="input-group">
                <input
                  type="number"
                  name="startYear"
                  className="form-control border-0"
                  placeholder={"年"}
                  onChange={handleChangeInput}
                  data-cy="竣工年はじまりテキスト"
                ></input>
                <span className="input-group-text border-0">~</span>
                <input
                  type="number"
                  name="endYear"
                  className="form-control border-0"
                  placeholder={"年"}
                  onChange={handleChangeInput}
                  data-cy="竣工年おわりテキスト"
                ></input>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn b-btn-primary"
              onClick={() => onClickSearchButton()}
              data-cy="検索ボタン"
            >
              検索
            </button>
          </div>
        </SearchArea>

        <div
          className="ag-theme-alpine b-ag-deleted-row b-header-row-white"
          style={{ position: "relative", margin: "10px 30px 0px 30px" }}
          data-cy="船舶一覧グリッド"
        >
          <AgGridReact
            domLayout="autoHeight"
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={data}
            pagination
            paginationPageSize={20}
            ref={gridRef}
            overlayNoRowsTemplate={noRowsText}
          ></AgGridReact>
          <PageSizeSelector grid={gridRef.current} />
        </div>

        <div
          className="text-end"
          style={{ color: "gray", marginRight: "250px" }}
        >
          <span>前回CSV取込成功日時 : </span>
          <span data-cy="前回取込日時">{lastImportDateTime}</span>
        </div>

        <input
          type="file"
          id="file"
          accept=".csv"
          style={{ display: "none" }}
          onChange={selectFile}
          onClick={(e: any) => {
            e.target.value = "";
          }}
          data-cy="CSVファイル選択"
        />

        <div className="text-center">
          <button
            type="button"
            className="btn b-btn-primary mt-5 mb-0 mx-auto"
            onClick={() => document.getElementById("file")?.click()}
            data-cy="CSV取込ボタン"
          >
            CSV取込
          </button>
        </div>
        <ShipDetail ref={shipDetailRef} />
      </div>
    </>
  );
}

export default ShipList;
