import { ReactNode } from "react";
import { Card, Collapse, Stack } from "react-bootstrap";

type Props = {
  children: ReactNode;
  className?: string;
  show: boolean;
  toolTip?: ReactNode;
  onChangeShow: (show: boolean) => void;
  title?: string;
};
function RepairScheduleAccordionArea(props: Props) {
  const switchButtonStyle = {
    transform: props.show ? "rotate(180deg)" : "",
    transition: "transform 150ms ease",
    backgroundColor: "#f4f4f4",
  };
  const title = props.title;
  return (
    <div className={props.className + " mx-auto"}>
      <Card style={{ borderRadius: "10px 10px 10px 10px " }}>
        <Card.Header
          className={
            (props.show ? "b-radius-top" : "b-radius") + " hover-pointer"
          }
          style={{
            backgroundColor: "#f4f4f4",
          }}
          onClick={(e) => {
            props.onChangeShow(!props.show);
          }}
        >
          <Stack direction="horizontal">
            <div>
              {title}
              {props.toolTip ?? ""}
            </div>
            <div className="ms-auto">
              <div style={switchButtonStyle}>▼</div>
            </div>
          </Stack>
        </Card.Header>
        <Card.Body className="p-0 bg-white b-radius-bottom">
          <Collapse in={props.show}>
            <div>
              <div className="py-0 px-0">{props.children}</div>
            </div>
          </Collapse>
        </Card.Body>
      </Card>
    </div>
  );
}

export default RepairScheduleAccordionArea;
