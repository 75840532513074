import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import dayjs from "dayjs";
import { CSSProperties, useEffect, useRef } from "react";
import { VesselTroublesGetResponse } from "src/model/api/response/VesselTroublesGetResponse";
import { VesselVisitsGetResponse } from "src/model/api/response/VesselVisitsGetResponse";

import "../../react-splide-custom.css";

type Props = {
  /** 訪船履歴 */
  visits: VesselVisitsGetResponse[];
  /** 不具合対応履歴 */
  troubles: VesselTroublesGetResponse[];
  /** 選択中の年 */
  selectedYear: number | undefined;
  /** 年を選択した時のコールバック関数 */
  onScrolledYear: (year: number) => void;
};

function MaintenanceHistoryListYearTabs(props: Props) {
  const yearButtonStyle: CSSProperties = {
    display: "flex",
    color: "white",
    backgroundColor: "#b7bdc7",
    width: "100px",
    height: "30px",
    alignItems: "center",
    justifyContent: "center",
  };
  const yearButtonActiveStyle: CSSProperties = {
    ...yearButtonStyle,
    color: "white",
    backgroundColor: "#fd6b00",
  };

  // 参照
  const refSplide = useRef<Splide>(null);

  // タブ切り換えイベント
  function handleScrolledYear(index: number) {
    // 選択された年を特定
    const years = getYearList(props.visits, props.troubles);
    const selectedYear = years[index];
    // propsの関数を呼び出し
    props.onScrolledYear(selectedYear);
  }

  /**
   * 年のリストを取得
   * <p>
   * 今年を先頭として、訪船履歴または不具合対応履歴の最小の年までをつなげたリストにする
   * </p>
   */
  function getYearList(
    visits: VesselVisitsGetResponse[],
    troubles: VesselTroublesGetResponse[]
  ) {
    // 年の最小値
    const minYear = getMinimumYear(visits, troubles);

    const thisYear = dayjs().year();
    const yearCount = thisYear - minYear + 1;
    const yearsList = [...Array(yearCount)].map((it, index) => {
      return thisYear - index;
    });
    return yearsList;
  }

  /** 最小の年を取得 */
  function getMinimumYear(
    visits: VesselVisitsGetResponse[],
    troubles: VesselTroublesGetResponse[]
  ): number {
    // 最小値（今年を初期値とする）
    let min = dayjs().year();

    // 訪船履歴リストの出港日と不具合対応履歴リストの不具合一報日をリストにする
    visits
      .map((it) => it.estimatedTimeDeparture)
      .concat(troubles.map((it) => it.troubleNoticeDate))
      // nullを無視
      .filter((it) => it !== null)
      // 1件ごと処理
      .forEach((date) => {
        // 年を取り出し
        const year = dayjs(date).year();
        // 小さいほうを保持
        min = Math.min(min, year);
      });

    return min;
  }

  // 訪船履歴、不具合対応履歴に変更があったら先頭タブを選択しなおす
  useEffect(() => {
    const thisYear = dayjs().year();
    props.onScrolledYear(thisYear);
    refSplide.current?.go(0);
  }, [props.visits, props.troubles]);

  /** メインレンダー */
  return (
    <div className="custom-splide-container">
      <Splide
        ref={refSplide}
        options={{
          gap: "0",
          pagination: false,
          autoWidth: true,
          rewind: true,
          isNavigation: true,
          updateOnMove: true,
        }}
        onMoved={(_, index) => handleScrolledYear(index)}
      >
        {getYearList(props.visits, props.troubles).map((year) => {
          return (
            <SplideSlide key={year}>
              <div
                style={
                  year === props.selectedYear
                    ? yearButtonActiveStyle
                    : yearButtonStyle
                }
              >
                {year}
              </div>
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
}

export default MaintenanceHistoryListYearTabs;
