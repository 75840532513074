/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { useLocation, useNavigate } from "react-router-dom";

import {
  AuthUserContextType,
  useAuthUserContext,
} from "../../context/AuthUser";
import useApi from "../../hook/useApi";
import LoginResponse from "../../model/api/LoginResponse";
import { UserInfo } from "../../type/UserInfo";
import { saveToken } from "../../util/LoginUtil";
import { convertSha256, getBizError } from "../../util/ecUtil";

/** style定義 */
const inputStyle = css`
  font-size: 1.3vw;
  &::placeholder {
    color: gray;
  }
`;
const eyeStyle: React.CSSProperties = {
  position: "absolute",
  marginTop: "-35px",
  right: "13%",
};

const loginButtonCss = css`
  padding: 3.5%;
  margin: 5.5% 0;
  border: none;
  :not(.active) {
    background-color: #bcbcbc;
  }
  :hover {
    background-color: #001f88;
  }
`;

type CustomLocation = {
  state: { from: { pathname: string; search: string } };
};

type LoginNavigateParam = {
  initialMessage?: string;
};

function Login() {
  const { t } = useTranslation();

  const [mailAddress, setMailAddress] = useState(""); //メールアドレス
  const [password, setPassword] = useState(""); //パスワード
  const [visiblePassword, setVisiblePassword] = useState(false); //パスワード可視フラグ
  const [isActiveLoginButton, setIsActiveLoginButton] =
    useState<boolean>(false); //ログインボタン有効化フラグ

  const navigate = useNavigate();
  const location: CustomLocation = useLocation() as CustomLocation;
  const fromPathName: string = location.state?.from?.pathname ?? "/";
  const fromSearch: string = location.state?.from?.search ?? "";
  const authUser: AuthUserContextType = useAuthUserContext();

  const navigateParams = useLocation().state as LoginNavigateParam | null;
  //メッセージ
  const [message, setMessage] = useState(() => {
    return navigateParams?.initialMessage ?? "";
  });

  // API使用宣言
  const api = useApi();

  //ID(メールアドレス)入力値変更時のみ実行
  useEffect(() => {
    const elem = document.getElementById("login");
    if (mailAddress === "") {
      setIsActiveLoginButton(false);
      elem?.classList.remove("active");
    } else {
      setIsActiveLoginButton(true);
      elem?.classList.add("active");
    }
  }, [mailAddress]);

  // レスポンスヘッダからユーザ情報を読み取る
  function readUserInfoFromResponse(response: AxiosResponse) {
    const authHeader = response.headers["x-b-auth"];
    const authJson = decodeURIComponent(authHeader);
    const auth: LoginResponse = JSON.parse(authJson);
    return convertLoginReponseToUserInfo(auth);
  }

  // ログインレスポンスからUserInfoへ変換する
  function convertLoginReponseToUserInfo(auth: LoginResponse): UserInfo {
    const user: UserInfo = {
      userId: auth.userId,
      mailAddress: auth.mailAddress,
      userName: decodeURI(auth.userName),
      companyCode: auth.companyCode,
      companyName: decodeURI(auth.companyName),
      bemacFlg: auth.bemacFlg,
      bemacUserClassValue: auth.bemacUserClassValue,
      adminFlg: auth.adminFlg,
      suFlg: auth.suFlg,
      tradingFlg: auth.tradingFlg,
    };

    return user;
  }

  // ログイン処理
  const signin = () => {
    setMessage("");

    // 入力されたパスワードをハッシュ化
    const hashedPassword = convertSha256(password);
    const request = {
      mailAddress,
      password: hashedPassword,
    };
    api
      .post("/api/v1/login", request)
      .then((response) => {
        // JWTトークンを保存
        const token = response.headers["authorization"];
        saveToken(token);

        // ユーザ情報を取り出し
        const user = readUserInfoFromResponse(response);
        // ユーザ情報を保存してリダイレクト
        authUser.signin(user, () => {
          navigate(fromPathName + fromSearch, { replace: true });
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          const text = t("message.E028");
          setMessage(text);
        } else {
          const bizError = getBizError(e);
          if (bizError) {
            const text = bizError.text;
            setMessage(text);
          }
        }
      });
  };

  return (
    <div
      className="vh-100 vw-100"
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/login-background-image.png"
        })`,
        backgroundSize: "cover",
      }}
    >
      {/* 左上Bemacロゴ */}
      <img
        src={`${process.env.PUBLIC_URL}/BEMAC.png`}
        alt="BEMAC.png"
        style={{ width: "85px", height: "20px", margin: "25px 35px" }}
      />

      <div
        style={{
          width: "60%",
          position: "absolute",
          top: "32%",
        }}
      >
        {/* 背景MaSSAロゴ */}
        <img
          src={`${process.env.PUBLIC_URL}/MaSSA-white.png`}
          alt="MaSSA-logo"
          style={{
            position: "absolute",
            width: "30%",
            left: "0",
            right: "0",
            margin: "auto",
          }}
        />

        {/* メッセージ */}
        {message && (
          <div
            className="p-1"
            style={{
              backgroundColor: "rgba(255,255,255,0.8)",
              position: "absolute",
              width: "80%",
              fontSize: "100%",
              left: "0",
              right: "0",
              margin: "23% auto",
              textAlign: "center",
            }}
          >
            <label
              style={{
                color: "black",
                whiteSpace: "pre-wrap",
                fontSize: "1.3vw",
              }}
            >
              {message}
            </label>
          </div>
        )}
      </div>

      {/* ログイン */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          width: "33%",
          height: "79%",
          margin: "0 0 0 61%",
          borderRadius: "23px",
        }}
      >
        <div
          style={{
            paddingTop: "28%",
            width: "60%",
            textAlign: "center",
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: "24px" }}>LOG IN</div>
          <div style={{ margin: "10% 0" }}>
            <div
              style={{
                color: "#909090",
                textAlign: "start",
                fontSize: "0.8vw",
              }}
            >
              ID（mail address）
            </div>
            <input
              type="email"
              className="form-control border-0"
              css={inputStyle}
              placeholder="mail"
              value={mailAddress}
              onChange={(e) => setMailAddress(e.target.value)}
              id="mailAddress"
              autoComplete="username"
              data-cy="ログインメールアドレステキスト"
            />
          </div>
          <div style={{ margin: "10% 0" }}>
            <div
              style={{
                color: "#909090",
                textAlign: "start",
                fontSize: "0.8vw",
              }}
            >
              PASSWARD
            </div>
            <div>
              <input
                type={visiblePassword ? "text" : "password"}
                className="form-control border-0"
                css={inputStyle}
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                data-cy="ログインパスワードテキスト"
              />
              <span
                style={eyeStyle}
                onClick={(e) => setVisiblePassword(!visiblePassword)}
              >
                {visiblePassword ? (
                  <VscEye size={25} />
                ) : (
                  <VscEyeClosed size={25} />
                )}
              </span>
            </div>
          </div>
          <Button
            className="btn btn-primary w-100"
            id="login"
            css={loginButtonCss}
            style={{ margin: "5.5% 0" }}
            onClick={signin}
            disabled={!isActiveLoginButton}
            data-cy="ログインボタン"
          >
            LOG IN
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Login;
