import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { useDialog } from "../../context/DialogProvider";
import { ProductsGetResponse } from "../../model/api/response/ProductsGetResponse";
import ProductList from "./ProductList";

// 公開する関数の定義
export interface ProductListModalHandles {
  show(): void;
}

type Props = {
  onDecide?: (products: ProductsGetResponse[]) => void;
  onlyLcmCosts?: boolean;
  imoNo?: string;
};

/** 商品一覧モーダルコンポーネント */
const ProductListModal = forwardRef<ProductListModalHandles, Props>(
  (props, ref) => {
    // 公開する関数の実装
    useImperativeHandle(ref, () => ({
      show() {
        setShow(true);
      },
    }));

    // state
    const [show, setShow] = useState(false);
    const [selectedList, setSelectedList] = useState<ProductsGetResponse[]>([]); // 選択済み商品リスト

    //ダイアログ使用宣言
    const showDialog = useDialog();

    // モーダルクローズハンドラ
    const handleClose = () => {
      console.log(selectedList.length);
      if (selectedList.length) {
        showDialog({
          id: "I018",
          confirm: true,
          callback(isOk) {
            if (isOk) {
              setSelectedList([]);
              setShow(false);
            }
          },
        });
      } else {
        setShow(false);
      }
    };

    // 商品一覧コンポーネントの商品選択イベント
    function handleSelect(products: ProductsGetResponse[]) {
      // 商品一覧で選択商品が変更されるたびにstateに保持する
      setSelectedList(products);
    }

    // 、明細に追加ボタンクリック
    function onClickonDecideButton() {
      if (props.onDecide) {
        props.onDecide(selectedList);
        setSelectedList([]);
      }
      setShow(false);
    }

    // レンダリング
    return (
      <Modal
        show={show}
        onHide={handleClose}
        scrollable
        fullscreen
        data-cy="商品一覧モーダル"
      >
        <Modal.Header closeButton>
          <Modal.Title>商品一覧</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {
            <ProductList
              selectable
              isModal
              onlyLcmCosts={props.onlyLcmCosts}
              imoNo={props.imoNo}
              onSelect={handleSelect}
            />
          }
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="b-btn-primary"
            onClick={(e) => onClickonDecideButton()}
            data-cy="明細に追加ボタン"
          >
            明細に追加
          </Button>
          <Button
            className="b-btn-close"
            onClick={handleClose}
            data-cy="Closeボタン"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default ProductListModal;
