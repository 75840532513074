import { MaintenanceSchedulesGetResponse } from "src/model/api/response/MaintenanceSchedulesGetResponse";

import {
  GridMaintenanceCostType,
  GridRecommendProductType,
  RowTypes,
} from "../../component/lcm/RepairSchedule";
import { OrderStatus, QuotationStatus } from "../../util/Constant";
import {
  DONE,
  INSPECTION_DETAIL_BOTH,
  INSPECTION_DETAIL_INSPECTION,
  QUOTATION,
  RECOMMEND,
} from "./LcmConst";

// 定期点検金額データ取得
export function getGridMaintenanceCostList(
  maintenaceScheduleDataList: MaintenanceSchedulesGetResponse[],
  recommendPriceSum: GridRecommendProductType,
  imoNo: string | undefined,
  bemacFlag: boolean
) {
  const gridMaintenanceCostList: GridMaintenanceCostType[] = [];

  if (!imoNo) {
    return gridMaintenanceCostList;
  }

  gridMaintenanceCostList.push({
    rowType: RowTypes.InspectionCost,
    rowTitle: "定期点検基本料金",
    recommend1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.InspectionCost,
      RECOMMEND,
      bemacFlag
    ),
    done1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    done30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.InspectionCost,
      DONE,
      bemacFlag
    ),
    quotation1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
    quotation30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.InspectionCost,
      QUOTATION,
      bemacFlag
    ),
  });

  gridMaintenanceCostList.push({
    rowType: RowTypes.TotalWorkCost,
    rowTitle: "作業費用計",
    recommend1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    recommend30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.TotalWorkCost,
      RECOMMEND,
      bemacFlag
    ),
    done1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    done30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.TotalWorkCost,
      DONE,
      bemacFlag
    ),
    quotation1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
    quotation30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.TotalWorkCost,
      QUOTATION,
      bemacFlag
    ),
  });

  gridMaintenanceCostList.push({
    rowType: RowTypes.PartsCost,
    rowTitle: "部品交換費用計",
    recommend1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal1
    ),
    recommend2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal2
    ),
    recommend3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal3
    ),
    recommend4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal4
    ),
    recommend5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal5
    ),
    recommend6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal6
    ),
    recommend7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal7
    ),
    recommend8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal8
    ),
    recommend9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal9
    ),
    recommend10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal10
    ),
    recommend11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal11
    ),
    recommend12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal12
    ),
    recommend13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal13
    ),
    recommend14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal14
    ),
    recommend15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal15
    ),
    recommend16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal16
    ),
    recommend17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal17
    ),
    recommend18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal18
    ),
    recommend19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal19
    ),
    recommend20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal20
    ),
    recommend21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal21
    ),
    recommend22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal22
    ),
    recommend23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal23
    ),
    recommend24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal24
    ),
    recommend25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal25
    ),
    recommend26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal26
    ),
    recommend27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal27
    ),
    recommend28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal28
    ),
    recommend29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal29
    ),
    recommend30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.PartsCost,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal30
    ),
    done1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    done30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.PartsCost,
      DONE,
      bemacFlag
    ),
    quotation1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
    quotation30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.PartsCost,
      QUOTATION,
      bemacFlag
    ),
  });

  gridMaintenanceCostList.push({
    rowType: RowTypes.InspectionDetail,
    rowTitle: "点検内訳",
    recommend1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    recommend30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.InspectionDetail,
      RECOMMEND,
      bemacFlag
    ),
    done1: undefined,
    done2: undefined,
    done3: undefined,
    done4: undefined,
    done5: undefined,
    done6: undefined,
    done7: undefined,
    done8: undefined,
    done9: undefined,
    done10: undefined,
    done11: undefined,
    done12: undefined,
    done13: undefined,
    done14: undefined,
    done15: undefined,
    done16: undefined,
    done17: undefined,
    done18: undefined,
    done19: undefined,
    done20: undefined,
    done21: undefined,
    done22: undefined,
    done23: undefined,
    done24: undefined,
    done25: undefined,
    done26: undefined,
    done27: undefined,
    done28: undefined,
    done29: undefined,
    done30: undefined,
    quotation1: undefined,
    quotation2: undefined,
    quotation3: undefined,
    quotation4: undefined,
    quotation5: undefined,
    quotation6: undefined,
    quotation7: undefined,
    quotation8: undefined,
    quotation9: undefined,
    quotation10: undefined,
    quotation11: undefined,
    quotation12: undefined,
    quotation13: undefined,
    quotation14: undefined,
    quotation15: undefined,
    quotation16: undefined,
    quotation17: undefined,
    quotation18: undefined,
    quotation19: undefined,
    quotation20: undefined,
    quotation21: undefined,
    quotation22: undefined,
    quotation23: undefined,
    quotation24: undefined,
    quotation25: undefined,
    quotation26: undefined,
    quotation27: undefined,
    quotation28: undefined,
    quotation29: undefined,
    quotation30: undefined,
  });

  gridMaintenanceCostList.push({
    rowType: RowTypes.Summary,
    rowTitle: "総計",
    recommend1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal2
    ),
    recommend3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal5
    ),
    recommend6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal7
    ),
    recommend8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal10
    ),
    recommend11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal12
    ),
    recommend13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal15
    ),
    recommend16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal17
    ),
    recommend18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal20
    ),
    recommend21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal22
    ),
    recommend23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal25
    ),
    recommend26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal27
    ),
    recommend28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag
    ),
    recommend30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.Summary,
      RECOMMEND,
      bemacFlag,
      recommendPriceSum.recommendSubtotal30
    ),
    done1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    done30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.Summary,
      DONE,
      bemacFlag
    ),
    quotation1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
    quotation30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.Summary,
      QUOTATION,
      bemacFlag
    ),
  });

  gridMaintenanceCostList.push({
    rowType: RowTypes.Expenses,
    rowTitle: "経費",
    recommend1: undefined,
    recommend2: undefined,
    recommend3: undefined,
    recommend4: undefined,
    recommend5: undefined,
    recommend6: undefined,
    recommend7: undefined,
    recommend8: undefined,
    recommend9: undefined,
    recommend10: undefined,
    recommend11: undefined,
    recommend12: undefined,
    recommend13: undefined,
    recommend14: undefined,
    recommend15: undefined,
    recommend16: undefined,
    recommend17: undefined,
    recommend18: undefined,
    recommend19: undefined,
    recommend20: undefined,
    recommend21: undefined,
    recommend22: undefined,
    recommend23: undefined,
    recommend24: undefined,
    recommend25: undefined,
    recommend26: undefined,
    recommend27: undefined,
    recommend28: undefined,
    recommend29: undefined,
    recommend30: undefined,
    done1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    done30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.Expenses,
      DONE,
      bemacFlag
    ),
    quotation1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),

    quotation17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
    quotation30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.Expenses,
      QUOTATION,
      bemacFlag
    ),
  });

  gridMaintenanceCostList.push({
    rowType: RowTypes.TotalSummary,
    rowTitle: "総計(経費込)",
    recommend1: undefined,
    recommend2: undefined,
    recommend3: undefined,
    recommend4: undefined,
    recommend5: undefined,
    recommend6: undefined,
    recommend7: undefined,
    recommend8: undefined,
    recommend9: undefined,
    recommend10: undefined,
    recommend11: undefined,
    recommend12: undefined,
    recommend13: undefined,
    recommend14: undefined,
    recommend15: undefined,
    recommend16: undefined,
    recommend17: undefined,
    recommend18: undefined,
    recommend19: undefined,
    recommend20: undefined,
    recommend21: undefined,
    recommend22: undefined,
    recommend23: undefined,
    recommend24: undefined,
    recommend25: undefined,
    recommend26: undefined,
    recommend27: undefined,
    recommend28: undefined,
    recommend29: undefined,
    recommend30: undefined,
    done1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    done30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.TotalSummary,
      DONE,
      bemacFlag
    ),
    quotation1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),

    quotation17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
    quotation30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.TotalSummary,
      QUOTATION,
      bemacFlag
    ),
  });

  gridMaintenanceCostList.push({
    rowType: RowTypes.QuotationOrder,
    rowTitle: "見積・注文",
    recommend1: undefined,
    recommend2: undefined,
    recommend3: undefined,
    recommend4: undefined,
    recommend5: undefined,
    recommend6: undefined,
    recommend7: undefined,
    recommend8: undefined,
    recommend9: undefined,
    recommend10: undefined,
    recommend11: undefined,
    recommend12: undefined,
    recommend13: undefined,
    recommend14: undefined,
    recommend15: undefined,
    recommend16: undefined,
    recommend17: undefined,
    recommend18: undefined,
    recommend19: undefined,
    recommend20: undefined,
    recommend21: undefined,
    recommend22: undefined,
    recommend23: undefined,
    recommend24: undefined,
    recommend25: undefined,
    recommend26: undefined,
    recommend27: undefined,
    recommend28: undefined,
    recommend29: undefined,
    recommend30: undefined,
    done1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    done30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.QuotationOrder,
      DONE,
      bemacFlag
    ),
    quotation1: getMaintenanceCostData(
      maintenaceScheduleDataList,
      1,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation2: getMaintenanceCostData(
      maintenaceScheduleDataList,
      2,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation3: getMaintenanceCostData(
      maintenaceScheduleDataList,
      3,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation4: getMaintenanceCostData(
      maintenaceScheduleDataList,
      4,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation5: getMaintenanceCostData(
      maintenaceScheduleDataList,
      5,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation6: getMaintenanceCostData(
      maintenaceScheduleDataList,
      6,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation7: getMaintenanceCostData(
      maintenaceScheduleDataList,
      7,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation8: getMaintenanceCostData(
      maintenaceScheduleDataList,
      8,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation9: getMaintenanceCostData(
      maintenaceScheduleDataList,
      9,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation10: getMaintenanceCostData(
      maintenaceScheduleDataList,
      10,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation11: getMaintenanceCostData(
      maintenaceScheduleDataList,
      11,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation12: getMaintenanceCostData(
      maintenaceScheduleDataList,
      12,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation13: getMaintenanceCostData(
      maintenaceScheduleDataList,
      13,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation14: getMaintenanceCostData(
      maintenaceScheduleDataList,
      14,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation15: getMaintenanceCostData(
      maintenaceScheduleDataList,
      15,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation16: getMaintenanceCostData(
      maintenaceScheduleDataList,
      16,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation17: getMaintenanceCostData(
      maintenaceScheduleDataList,
      17,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation18: getMaintenanceCostData(
      maintenaceScheduleDataList,
      18,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation19: getMaintenanceCostData(
      maintenaceScheduleDataList,
      19,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation20: getMaintenanceCostData(
      maintenaceScheduleDataList,
      20,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation21: getMaintenanceCostData(
      maintenaceScheduleDataList,
      21,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation22: getMaintenanceCostData(
      maintenaceScheduleDataList,
      22,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation23: getMaintenanceCostData(
      maintenaceScheduleDataList,
      23,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation24: getMaintenanceCostData(
      maintenaceScheduleDataList,
      24,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation25: getMaintenanceCostData(
      maintenaceScheduleDataList,
      25,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation26: getMaintenanceCostData(
      maintenaceScheduleDataList,
      26,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation27: getMaintenanceCostData(
      maintenaceScheduleDataList,
      27,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation28: getMaintenanceCostData(
      maintenaceScheduleDataList,
      28,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation29: getMaintenanceCostData(
      maintenaceScheduleDataList,
      29,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
    quotation30: getMaintenanceCostData(
      maintenaceScheduleDataList,
      30,
      RowTypes.QuotationOrder,
      QUOTATION,
      bemacFlag
    ),
  });

  return gridMaintenanceCostList;
}

function getMaintenanceCostData(
  maintenaceScheduleDataList: MaintenanceSchedulesGetResponse[],
  timing: number,
  rowType: RowTypes,
  dataType: string,
  bemacFlg: boolean,
  recomendPrice?: number
) {
  const maintenaceScheduleData = maintenaceScheduleDataList.find((it) => {
    return Number(it.inspectionYearClassValue) === timing;
  });
  switch (rowType) {
    case RowTypes.InspectionCost:
      if (dataType === RECOMMEND) {
        return maintenaceScheduleData?.inspectionCost ?? undefined;
      } else if (
        dataType === DONE &&
        maintenaceScheduleData &&
        maintenaceScheduleData.orderStatusClassValue === OrderStatus.COMPLETED
      ) {
        return maintenaceScheduleData?.doneInspectionCost ?? undefined;
      } else if (
        dataType === QUOTATION &&
        (bemacFlg ||
          maintenaceScheduleData?.quotationStatusClassValue !==
            QuotationStatus.DRAFT)
      ) {
        return maintenaceScheduleData?.quotationInspectionCost ?? undefined;
      } else {
        return undefined;
      }

    case RowTypes.TotalWorkCost:
      // 作業費用計
      // 注文は基本予定と一致するので、予定の値を設定する
      var workCost = 0;
      if (dataType === RECOMMEND) {
        workCost =
          (maintenaceScheduleData?.planTotalWorkCost
            ? maintenaceScheduleData?.planTotalWorkCost
            : 0) +
          (maintenaceScheduleData?.planTotalWorkCostWeekOut
            ? maintenaceScheduleData?.planTotalWorkCostWeekOut
            : 0) +
          (maintenaceScheduleData?.planTotalWorkCostHol
            ? maintenaceScheduleData?.planTotalWorkCostHol
            : 0) +
          (maintenaceScheduleData?.planTotalWorkCostHolOut
            ? maintenaceScheduleData?.planTotalWorkCostHolOut
            : 0) +
          (maintenaceScheduleData?.planTotalWorkCostForeign
            ? maintenaceScheduleData?.planTotalWorkCostForeign
            : 0);
      } else if (
        dataType === DONE &&
        maintenaceScheduleData &&
        maintenaceScheduleData.orderStatusClassValue === OrderStatus.COMPLETED
      ) {
        workCost =
          (maintenaceScheduleData?.doneTotalWorkCost
            ? maintenaceScheduleData?.doneTotalWorkCost
            : 0) +
          (maintenaceScheduleData?.doneTotalWorkCostWeekOut
            ? maintenaceScheduleData?.doneTotalWorkCostWeekOut
            : 0) +
          (maintenaceScheduleData?.doneTotalWorkCostHol
            ? maintenaceScheduleData?.doneTotalWorkCostHol
            : 0) +
          (maintenaceScheduleData?.doneTotalWorkCostHolOut
            ? maintenaceScheduleData?.doneTotalWorkCostHolOut
            : 0) +
          (maintenaceScheduleData?.doneTotalWorkCostForeign
            ? maintenaceScheduleData?.doneTotalWorkCostForeign
            : 0);
      } else if (
        dataType === QUOTATION &&
        (bemacFlg ||
          maintenaceScheduleData?.quotationStatusClassValue !==
            QuotationStatus.DRAFT)
      ) {
        workCost =
          (maintenaceScheduleData?.quotationTotalWorkCost
            ? maintenaceScheduleData?.quotationTotalWorkCost
            : 0) +
          (maintenaceScheduleData?.quotationTotalWorkCostWeekOut
            ? maintenaceScheduleData?.quotationTotalWorkCostWeekOut
            : 0) +
          (maintenaceScheduleData?.quotationTotalWorkCostHol
            ? maintenaceScheduleData?.quotationTotalWorkCostHol
            : 0) +
          (maintenaceScheduleData?.quotationTotalWorkCostHolOut
            ? maintenaceScheduleData?.quotationTotalWorkCostHolOut
            : 0) +
          (maintenaceScheduleData?.quotationTotalWorkCostForeign
            ? maintenaceScheduleData?.quotationTotalWorkCostForeign
            : 0);
      }
      return workCost !== 0 ? workCost : undefined;
    case RowTypes.PartsCost:
      // 部品交換費用計
      if (dataType === RECOMMEND) {
        return recomendPrice !== 0 ? recomendPrice : undefined;
      } else if (
        dataType === DONE &&
        maintenaceScheduleData &&
        maintenaceScheduleData.orderStatusClassValue === OrderStatus.COMPLETED
      ) {
        return maintenaceScheduleData?.donePartsCost ?? undefined;
      } else if (
        dataType === QUOTATION &&
        (bemacFlg ||
          maintenaceScheduleData?.quotationStatusClassValue !==
            QuotationStatus.DRAFT)
      ) {
        return maintenaceScheduleData?.quotationPartsCost ?? undefined;
      } else {
        return undefined;
      }
    case RowTypes.InspectionDetail:
      // 点検内訳
      if (dataType === RECOMMEND) {
        return maintenaceScheduleData?.inspecitonDetailClassValue ?? undefined;
      } else {
        return undefined;
      }
    case RowTypes.Summary:
      // 総計
      if (dataType === RECOMMEND) {
        let returnVal = undefined;
        if (maintenaceScheduleData) {
          returnVal =
            maintenaceScheduleData.inspecitonDetailClassValue ===
            INSPECTION_DETAIL_BOTH
              ? (maintenaceScheduleData.inspectionCost ?? 0) +
                (maintenaceScheduleData.planTotalWorkCost ?? 0) +
                (maintenaceScheduleData.planTotalWorkCostWeekOut ?? 0) +
                (maintenaceScheduleData.planTotalWorkCostHol ?? 0) +
                (maintenaceScheduleData.planTotalWorkCostHolOut ?? 0) +
                (maintenaceScheduleData.planTotalWorkCostForeign ?? 0) +
                (recomendPrice ?? 0)
              : maintenaceScheduleData.inspecitonDetailClassValue ===
                INSPECTION_DETAIL_INSPECTION
              ? maintenaceScheduleData.inspectionCost ?? 0
              : (maintenaceScheduleData.planTotalWorkCost ?? 0) +
                (maintenaceScheduleData.planTotalWorkCostWeekOut ?? 0) +
                (maintenaceScheduleData.planTotalWorkCostHol ?? 0) +
                (maintenaceScheduleData.planTotalWorkCostHolOut ?? 0) +
                (maintenaceScheduleData.planTotalWorkCostForeign ?? 0) +
                (recomendPrice ?? 0);
        }
        return returnVal;
      } else if (
        dataType === DONE &&
        maintenaceScheduleData &&
        maintenaceScheduleData.orderStatusClassValue === OrderStatus.COMPLETED
      ) {
        let returnVal = undefined;
        if (maintenaceScheduleData && maintenaceScheduleData.orderNo) {
          returnVal =
            maintenaceScheduleData.inspecitonDetailClassValue ===
            INSPECTION_DETAIL_BOTH
              ? (maintenaceScheduleData.doneInspectionCost ?? 0) +
                (maintenaceScheduleData.doneTotalWorkCost ?? 0) +
                (maintenaceScheduleData.doneTotalWorkCostWeekOut ?? 0) +
                (maintenaceScheduleData.doneTotalWorkCostHol ?? 0) +
                (maintenaceScheduleData.doneTotalWorkCostHolOut ?? 0) +
                (maintenaceScheduleData.doneTotalWorkCostForeign ?? 0) +
                (maintenaceScheduleData.donePartsCost ?? 0)
              : maintenaceScheduleData.inspecitonDetailClassValue ===
                INSPECTION_DETAIL_INSPECTION
              ? maintenaceScheduleData.doneInspectionCost ?? 0
              : (maintenaceScheduleData.doneTotalWorkCost ?? 0) +
                (maintenaceScheduleData.doneTotalWorkCostWeekOut ?? 0) +
                (maintenaceScheduleData.doneTotalWorkCostHol ?? 0) +
                (maintenaceScheduleData.doneTotalWorkCostHolOut ?? 0) +
                (maintenaceScheduleData.doneTotalWorkCostForeign ?? 0) +
                (maintenaceScheduleData.donePartsCost ?? 0);
        }
        return returnVal;
      } else if (
        dataType === QUOTATION &&
        (bemacFlg ||
          maintenaceScheduleData?.quotationStatusClassValue !==
            QuotationStatus.DRAFT)
      ) {
        let returnVal = undefined;
        if (maintenaceScheduleData) {
          returnVal =
            maintenaceScheduleData.inspecitonDetailClassValue ===
            INSPECTION_DETAIL_BOTH
              ? (maintenaceScheduleData?.quotationInspectionCost ?? 0) +
                (maintenaceScheduleData?.quotationTotalWorkCost ?? 0) +
                (maintenaceScheduleData?.quotationTotalWorkCostWeekOut ?? 0) +
                (maintenaceScheduleData?.quotationTotalWorkCostHol ?? 0) +
                (maintenaceScheduleData?.quotationTotalWorkCostHolOut ?? 0) +
                (maintenaceScheduleData?.quotationTotalWorkCostForeign ?? 0) +
                (maintenaceScheduleData?.quotationPartsCost ?? 0)
              : maintenaceScheduleData.inspecitonDetailClassValue ===
                INSPECTION_DETAIL_INSPECTION
              ? maintenaceScheduleData?.quotationInspectionCost ?? 0
              : (maintenaceScheduleData?.quotationTotalWorkCost ?? 0) +
                (maintenaceScheduleData?.quotationTotalWorkCostWeekOut ?? 0) +
                (maintenaceScheduleData?.quotationTotalWorkCostHol ?? 0) +
                (maintenaceScheduleData?.quotationTotalWorkCostHolOut ?? 0) +
                (maintenaceScheduleData?.quotationTotalWorkCostForeign ?? 0) +
                (maintenaceScheduleData?.quotationPartsCost ?? 0);
        }
        return returnVal;
      } else {
        return undefined;
      }
    case RowTypes.Expenses:
      // 経費
      if (
        dataType === DONE &&
        maintenaceScheduleData &&
        maintenaceScheduleData.orderStatusClassValue === OrderStatus.COMPLETED
      ) {
        return maintenaceScheduleData?.doneExpenses ?? undefined;
      } else if (
        dataType === QUOTATION &&
        (bemacFlg ||
          maintenaceScheduleData?.quotationStatusClassValue !==
            QuotationStatus.DRAFT)
      ) {
        return maintenaceScheduleData?.quotationExpenses ?? undefined;
      } else {
        return undefined;
      }
    case RowTypes.TotalSummary:
      // 総計(経費込み)
      if (
        dataType === DONE &&
        maintenaceScheduleData &&
        maintenaceScheduleData.orderStatusClassValue === OrderStatus.COMPLETED
      ) {
        return maintenaceScheduleData.inspecitonDetailClassValue ===
          INSPECTION_DETAIL_BOTH
          ? (maintenaceScheduleData.doneInspectionCost ?? 0) +
              (maintenaceScheduleData.doneTotalWorkCost ?? 0) +
              (maintenaceScheduleData.doneTotalWorkCostWeekOut ?? 0) +
              (maintenaceScheduleData.doneTotalWorkCostHol ?? 0) +
              (maintenaceScheduleData.doneTotalWorkCostHolOut ?? 0) +
              (maintenaceScheduleData.doneTotalWorkCostForeign ?? 0) +
              (maintenaceScheduleData.donePartsCost ?? 0) +
              (maintenaceScheduleData.doneExpenses ?? 0)
          : maintenaceScheduleData.inspecitonDetailClassValue ===
            INSPECTION_DETAIL_INSPECTION
          ? (maintenaceScheduleData.doneInspectionCost ?? 0) +
            (maintenaceScheduleData.doneExpenses ?? 0)
          : (maintenaceScheduleData.doneTotalWorkCost ?? 0) +
            (maintenaceScheduleData.doneTotalWorkCostWeekOut ?? 0) +
            (maintenaceScheduleData.doneTotalWorkCostHol ?? 0) +
            (maintenaceScheduleData.doneTotalWorkCostHolOut ?? 0) +
            (maintenaceScheduleData.doneTotalWorkCostForeign ?? 0) +
            (maintenaceScheduleData.donePartsCost ?? 0) +
            (maintenaceScheduleData.doneExpenses ?? 0);
      } else if (
        dataType === QUOTATION &&
        maintenaceScheduleData &&
        (bemacFlg ||
          maintenaceScheduleData.quotationStatusClassValue !==
            QuotationStatus.DRAFT)
      ) {
        return maintenaceScheduleData.inspecitonDetailClassValue ===
          INSPECTION_DETAIL_BOTH
          ? (maintenaceScheduleData.quotationInspectionCost ?? 0) +
              (maintenaceScheduleData?.quotationTotalWorkCost ?? 0) +
              (maintenaceScheduleData?.quotationTotalWorkCostWeekOut ?? 0) +
              (maintenaceScheduleData?.quotationTotalWorkCostHol ?? 0) +
              (maintenaceScheduleData?.quotationTotalWorkCostHolOut ?? 0) +
              (maintenaceScheduleData?.quotationTotalWorkCostForeign ?? 0) +
              (maintenaceScheduleData.quotationPartsCost ?? 0) +
              (maintenaceScheduleData.quotationExpenses ?? 0)
          : maintenaceScheduleData.inspecitonDetailClassValue ===
            INSPECTION_DETAIL_INSPECTION
          ? (maintenaceScheduleData.quotationInspectionCost ?? 0) +
            (maintenaceScheduleData.quotationExpenses ?? 0)
          : (maintenaceScheduleData?.quotationTotalWorkCost ?? 0) +
            (maintenaceScheduleData?.quotationTotalWorkCostWeekOut ?? 0) +
            (maintenaceScheduleData?.quotationTotalWorkCostHol ?? 0) +
            (maintenaceScheduleData?.quotationTotalWorkCostHolOut ?? 0) +
            (maintenaceScheduleData?.quotationTotalWorkCostForeign ?? 0) +
            (maintenaceScheduleData.quotationPartsCost ?? 0) +
            (maintenaceScheduleData.quotationExpenses ?? 0);
      } else {
        return undefined;
      }
    case RowTypes.QuotationOrder:
      // 見積・注文
      if (dataType === DONE) {
        return maintenaceScheduleData?.orderNo ?? undefined;
      } else if (dataType === QUOTATION) {
        return (
          maintenaceScheduleDataList.find((it) => {
            return Number(it.inspectionYearClassValue) === timing;
          })?.quotationNo ?? undefined
        );
      } else {
        return undefined;
      }
  }
}
