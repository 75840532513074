import { createContext, useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ErrorResponse from "../model/api/ErrorResponse";
import { getBizError, replaceMessageArgs } from "../util/ecUtil";

export type DialogParam = {
  /** メッセージID */
  id?: string;
  /** 埋め込む値 */
  args?: any[];
  /** 設定されていれば表示、空ならIDから取得したテキストを表示 */
  text?: string;
  /** エラーオブジェクト */
  error?: any;
  /** trueの場合OK/Cancel表示 */
  confirm?: boolean;
  /** ダイアログclose時に実行する関数 */
  callback?: (isOk: boolean) => void;
  /** ダイアログのフルスクリーン表示 */
  fullScreenFlg?: boolean;
};

// Contextを生成
const DialogContext = createContext((p: DialogParam) => {});
DialogContext.displayName = "AlertContext";

// 外部から呼び出せるようexport
export const useDialog = () => {
  return useContext(DialogContext);
};

// Provider宣言
export function DialogProvider(props: { children: React.ReactNode }) {
  // state
  const [param, setParam] = useState<DialogParam>({ id: "" });
  const [bizError, setBizError] = useState<ErrorResponse>();
  const [show, setShow] = useState(false);

  // メッセージファイルから取得するための関数
  const { t } = useTranslation();

  // メイン関数
  const value = (params: DialogParam) => {
    // ログイン画面でのダイアログ表示を抑止する
    if (window.location.href.endsWith("/login")) {
      return;
    }
    setBizError(undefined);
    setParam(params);
    if (params.error) {
      const error = getBizError(params.error);
      if (error) {
        setBizError(error);
      }
    }
    setShow(true);
  };

  // モーダルクローズハンドラ
  const handleClose = (isOk: boolean) => {
    setShow(false);
    // callbackが指定されていたら実行する
    if (param.callback) {
      param.callback(isOk);
    }
  };

  // 表示するメッセージ本文を返す
  const getMessage = () => {
    if (bizError) {
      // 業務エラーが設定済みならそのまま返す
      return bizError.text;
    } else if (param.text) {
      // textが設定済みならそのまま返す
      return param.text;
    } else {
      const baseText = t(`message.${param.id}`);
      return replaceMessageArgs(baseText, param.args);
    }
  };

  // レンダリング
  return (
    <DialogContext.Provider value={value}>
      {props.children}
      <Modal
        show={show}
        onHide={() => handleClose(false)}
        centered
        fullscreen={param.fullScreenFlg ? true : ""}
        data-cy="ダイアログモーダル"
      >
        <Modal.Body className="bg-white">
          <label data-cy="ダイアログメッセージID">
            {bizError ? bizError.id : param.id}
          </label>
          <br />
          <label
            data-cy="ダイアログテキスト"
            style={{ whiteSpace: "break-spaces" }}
          >
            {getMessage()}
          </label>
        </Modal.Body>
        <Modal.Footer className="justify-content-center bg-white">
          {param.confirm ? (
            <>
              <Button
                className="b-btn-light"
                onClick={() => handleClose(false)}
                data-cy="ダイアログCancelボタン"
              >
                Cancel
              </Button>
              <Button
                className="b-btn-primary m-0 ms-3"
                onClick={() => handleClose(true)}
                data-cy="ダイアログOKボタン"
              >
                OK
              </Button>
            </>
          ) : (
            <Button
              className="b-btn-secondary"
              onClick={() => handleClose(false)}
              data-cy="ダイアログOKボタン"
            >
              OK
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </DialogContext.Provider>
  );
}
