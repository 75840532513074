import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";

import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { useSplashMessage } from "../../hook/useSplashMessage";
import { VesselProductsGetResponse } from "../../model/api/response/VesselProductsGetResponse";
import { defaultColDef } from "../../util/AgGridUtil";
import { TextConst } from "../../util/Constant";
import { formatDate, getBizError } from "../../util/ecUtil";
import PageSizeSelector from "../common/PageSizeSelector";
import { SELECT_VESSEL } from "./LcmTop";

type Props = {
  imoNo: string | undefined;
  vesselName: string | undefined;
  yearCheckValue: number;
};

// 交換推奨部品一覧データの型
type MVesselPruductsType = {
  no: number;
  imo: string;
  largeClass: string;
  middleClass?: string;
  productCode: string;
  productName?: string;
  productType: string;
  quantity: number;
  oneYear: string;
  twoYear: string;
  twoFiveYear: string;
  threeYear: string;
  fourYear: string;
  fiveYear: string;
  sixYear: string;
  sevenYear: string;
  sevenFiveYear: string;
  eightYear: string;
  nineYear: string;
  tenYear: string;
  elevenYear: string;
  twelveYear: string;
  twelveFiveYear: string;
  thirteenYear: string;
  fourteenYear: string;
  fifteenYear: string;
  sixteenYear: string;
  seventeenYear: string;
  seventeenFiveYear: string;
  eighteenYear: string;
  nineteenYear: string;
  twentyYear: string;
  twentyOneYear: string;
  twentyTwoYear: string;
  twentyTwoFiveYear: string;
  twentyThreeYear: string;
  twentyFourYear: string;
  twentyFiveYear: string;
  twentySixYear: string;
  twentySevenYear: string;
  twentySevenFiveYear: string;
  twentyEightYear: string;
  twentyNineYear: string;
  thirtyYear: string;
};

/** 交換推奨部品登録コンポーネント */
function ImportShipParts(props: Props) {
  // 参照
  const gridRef = useRef<AgGridReact>(null);

  // API使用宣言
  const api = useApi();

  //ダイアログ仕様宣言
  const showDialog = useDialog();

  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();

  // IMO
  const [imoNo, setImoNo] = useState<string>();

  // 表示年度切替
  const [yearCheckValue, setYearCheckValue] = useState<number>();

  // 一覧データ
  const [gridData, setGridData] = useState<MVesselPruductsType[] | null>([]);

  // 前回取込ファイル名
  const [lastImportFileName, setlastImportFileName] = useState<string>();

  // 前回取込日時
  const [lastImportDateTime, setLastImportDateTime] = useState<string>();

  // 更新日時
  const [updateDateTime, setUpdateDateTime] = useState<string>();

  // 交換推奨部品取込ボタンの活性/非活性のstate
  const [importButtonState, setImportButtonState] = useState(false); //CSV出力ボタンの活性・非活性のstate

  // 取込済交換推奨部品削除ボタンの活性/非活性のstate
  const [deleteButtonState, setDeleteButtonState] = useState(false); //CSV出力ボタンの活性・非活性のstate

  // 交換推奨部品グリッド0件時のテキスト
  const [noRowsImportVesselParts, setNoRowsImportVesselParts] =
    useState(SELECT_VESSEL);

  // 前回取込ファイル名と日時の文字の太さ
  const styles = {
    text1: {
      fontWeight: "bold",
    },
  };

  // グリッドの列定義
  const columnDefs = [
    {
      headerName: "No",
      field: "no",
      width: 60,
      sortable: true,
    },
    {
      headerName: "IMO",
      field: "imo",
      width: 100,
      sortable: true,
    },

    {
      headerName: "大分類",
      field: "largeClass",
      width: 90,
      sortable: true,
    },
    {
      headerName: "中分類",
      field: "middleClass",
      width: 90,
      sortable: true,
    },
    {
      headerName: "品目コード",
      field: "productCode",
      width: 120,
      sortable: true,
    },
    {
      headerName: "品名",
      field: "productName",
      width: 150,
      sortable: true,
    },
    {
      headerName: "型式",
      field: "productType",
      width: 270,
      sortable: true,
    },
    {
      headerName: "数量",
      field: "quantity",
      width: 80,
      sortable: true,
      cellStyle: { textAlign: "right" },
    },
    {
      headerName: "1年",
      field: "oneYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "2年",
      field: "twoYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "2.5年",
      field: "twoFiveYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 1,
    },
    {
      headerName: "3年",
      field: "threeYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "4年",
      field: "fourYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "5年",
      field: "fiveYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "6年",
      field: "sixYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "7年",
      field: "sevenYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "7.5年",
      field: "sevenFiveYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 1,
    },
    {
      headerName: "8年",
      field: "eightYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "9年",
      field: "nineYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "10年",
      field: "tenYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "11年",
      field: "elevenYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "12年",
      field: "twelveYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "12.5年",
      field: "twelveFiveYear",
      width: 85,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 1,
    },
    {
      headerName: "13年",
      field: "thirteenYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "14年",
      field: "fourteenYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "15年",
      field: "fifteenYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "16年",
      field: "sixteenYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "17年",
      field: "seventeenYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "17.5年",
      field: "seventeenFiveYear",
      width: 85,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 1,
    },
    {
      headerName: "18年",
      field: "eighteenYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "19年",
      field: "nineteenYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "20年",
      field: "twentyYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "21年",
      field: "twentyOneYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "22年",
      field: "twentyTwoYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "22.5年",
      field: "twentyTwoFiveYear",
      width: 85,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 1,
    },
    {
      headerName: "23年",
      field: "twentyThreeYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "24年",
      field: "twentyFourYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "25年",
      field: "twentyFiveYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "26年",
      field: "twentySixYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "27年",
      field: "twentySevenYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "27.5年",
      field: "twentySevenFiveYear",
      width: 85,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 1,
    },
    {
      headerName: "28年",
      field: "twentyEightYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "29年",
      field: "twentyNineYear",
      width: 75,
      sortable: true,
      cellStyle: { textAlign: "center" },
      hide: yearCheckValue === 0,
    },
    {
      headerName: "30年",
      field: "thirtyYear",
      width: 80,
      sortable: true,
      cellStyle: { textAlign: "center" },
    },
  ];

  // props更新時実行
  useEffect(() => {
    if (props.imoNo !== imoNo || props.yearCheckValue !== yearCheckValue) {
      if (props.imoNo) {
        setImportButtonState(true);
      } else {
        setImportButtonState(false);
      }
      setImoNo(props.imoNo);
      setYearCheckValue(props.yearCheckValue);
      search();
    }
  }, [props]);

  // 検索処理
  function search() {
    let fileName: string;
    let updateDateTime: string;
    let lastImportDateTime: string;
    setGridData(null);
    setDeleteButtonState(false);
    api
      .get(`/api/v1/vessels/${props.imoNo ? props.imoNo : "-1"}/products`)
      .then((response) => {
        const result = response.data.map((it: VesselProductsGetResponse) => {
          setDeleteButtonState(true);
          fileName = it.fileName;
          updateDateTime = it.updateDateTime;
          return {
            no: it.detailNo,
            imo: it.imoNo,
            largeClass: it.largeClassName,
            middleClass: it.middleClassName,
            productCode: it.productCode,
            productName: it.productGroupName,
            productType: it.spec,
            quantity: it.recommendQuantity,
            oneYear: it.vesselProductsGetResponseSub[0].inspectionYearFlg
              ? "●"
              : "",
            twoYear: it.vesselProductsGetResponseSub[1].inspectionYearFlg
              ? "●"
              : "",
            twoFiveYear:
              it.vesselProductsGetResponseSub[1].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[2].inspectionYearFlg
                ? "●"
                : "",
            threeYear: it.vesselProductsGetResponseSub[2].inspectionYearFlg
              ? "●"
              : "",
            fourYear: it.vesselProductsGetResponseSub[3].inspectionYearFlg
              ? "●"
              : "",
            fiveYear: it.vesselProductsGetResponseSub[4].inspectionYearFlg
              ? "●"
              : "",
            sixYear: it.vesselProductsGetResponseSub[5].inspectionYearFlg
              ? "●"
              : "",
            sevenYear: it.vesselProductsGetResponseSub[6].inspectionYearFlg
              ? "●"
              : "",
            sevenFiveYear:
              it.vesselProductsGetResponseSub[6].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[7].inspectionYearFlg
                ? "●"
                : "",
            eightYear: it.vesselProductsGetResponseSub[7].inspectionYearFlg
              ? "●"
              : "",
            nineYear: it.vesselProductsGetResponseSub[8].inspectionYearFlg
              ? "●"
              : "",
            tenYear: it.vesselProductsGetResponseSub[9].inspectionYearFlg
              ? "●"
              : "",
            elevenYear: it.vesselProductsGetResponseSub[10].inspectionYearFlg
              ? "●"
              : "",
            twelveYear: it.vesselProductsGetResponseSub[11].inspectionYearFlg
              ? "●"
              : "",
            twelveFiveYear:
              it.vesselProductsGetResponseSub[11].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[12].inspectionYearFlg
                ? "●"
                : "",
            thirteenYear: it.vesselProductsGetResponseSub[12].inspectionYearFlg
              ? "●"
              : "",
            fourteenYear: it.vesselProductsGetResponseSub[13].inspectionYearFlg
              ? "●"
              : "",
            fifteenYear: it.vesselProductsGetResponseSub[14].inspectionYearFlg
              ? "●"
              : "",
            sixteenYear: it.vesselProductsGetResponseSub[15].inspectionYearFlg
              ? "●"
              : "",
            seventeenYear: it.vesselProductsGetResponseSub[16].inspectionYearFlg
              ? "●"
              : "",
            seventeenFiveYear:
              it.vesselProductsGetResponseSub[16].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[17].inspectionYearFlg
                ? "●"
                : "",
            eighteenYear: it.vesselProductsGetResponseSub[17].inspectionYearFlg
              ? "●"
              : "",
            nineteenYear: it.vesselProductsGetResponseSub[18].inspectionYearFlg
              ? "●"
              : "",
            twentyYear: it.vesselProductsGetResponseSub[19].inspectionYearFlg
              ? "●"
              : "",
            twentyOneYear: it.vesselProductsGetResponseSub[20].inspectionYearFlg
              ? "●"
              : "",
            twentyTwoYear: it.vesselProductsGetResponseSub[21].inspectionYearFlg
              ? "●"
              : "",
            twentyTwoFiveYear:
              it.vesselProductsGetResponseSub[21].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[22].inspectionYearFlg
                ? "●"
                : "",
            twentyThreeYear: it.vesselProductsGetResponseSub[22]
              .inspectionYearFlg
              ? "●"
              : "",
            twentyFourYear: it.vesselProductsGetResponseSub[23]
              .inspectionYearFlg
              ? "●"
              : "",
            twentyFiveYear: it.vesselProductsGetResponseSub[24]
              .inspectionYearFlg
              ? "●"
              : "",
            twentySixYear: it.vesselProductsGetResponseSub[25].inspectionYearFlg
              ? "●"
              : "",
            twentySevenYear: it.vesselProductsGetResponseSub[26]
              .inspectionYearFlg
              ? "●"
              : "",
            twentySevenFiveYear:
              it.vesselProductsGetResponseSub[26].inspectionYearFlg ||
              it.vesselProductsGetResponseSub[27].inspectionYearFlg
                ? "●"
                : "",
            twentyEightYear: it.vesselProductsGetResponseSub[27]
              .inspectionYearFlg
              ? "●"
              : "",
            twentyNineYear: it.vesselProductsGetResponseSub[28]
              .inspectionYearFlg
              ? "●"
              : "",
            thirtyYear: it.vesselProductsGetResponseSub[29].inspectionYearFlg
              ? "●"
              : "",
          };
        });

        // 検索結果をセット
        setGridData(result);
        setNoRowsImportVesselParts(
          props.imoNo ? TextConst.GRID_NO_DATA : SELECT_VESSEL
        );
        setlastImportFileName(fileName);
        setUpdateDateTime(updateDateTime);
        if (updateDateTime) {
          let dt = new Date(updateDateTime.replace("+00:00", "") + "Z");
          lastImportDateTime =
            formatDate(dt, "YYYY/MM/DD") +
            " " +
            ("0" + dt.getHours()).slice(-2) +
            ":" +
            ("0" + dt.getMinutes()).slice(-2) +
            ":" +
            ("0" + dt.getSeconds()).slice(-2);
        }
        setLastImportDateTime(lastImportDateTime);
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E073" });
        }
      });
  }

  //　CSV取込処理
  function selectFile(e: React.ChangeEvent<HTMLInputElement>) {
    let formData = new FormData();
    formData.append("vesselProductsCsv", e.currentTarget.files![0]);
    api
      .post(`/api/v1/vessels/${props.imoNo}/products-csv`, formData)
      .then((it) => {
        splashMessage.show("I044");
        search();
      })
      .catch((error) => {
        //csvエラーファイルが返ってきているかどうか
        if (error.response.data !== "") {
          //文字化け回避
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
          const csv = new Blob([bom, error.response.data], {
            type: "text/csv",
          });
          //aタグ作成
          const a = document.createElement("a");
          // ダウンロードされるファイル名
          a.download =
            "エラーファイル_" +
            props.imoNo +
            "_" +
            props.vesselName +
            "_交換推奨部品取込.csv";
          a.href = URL.createObjectURL(csv);
          // ダウンロード開始
          a.click();
        } else {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          } else {
            showDialog({ id: "E010" });
          }
        }
      });
  }

  //　削除処理
  function deleteVesselProducs() {
    api
      .delete(
        `/api/v1/vessels/${
          props.imoNo
        }/products?updateDateTime=${encodeURIComponent(updateDateTime ?? "")}`
      )
      .then((it) => {
        splashMessage.show("I036");
        search();
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E059" });
        }
      });
  }

  // レンダリング
  return (
    <>
      <div style={{ marginTop: "24px", marginBottom: "10px" }}>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          取込済交換推奨部品一覧
        </span>
      </div>
      <div className="w-75 mx-auto my-1">
        <div className="row">
          <div className="col-6">
            <span style={styles.text1}>前回取込ファイル名　　</span>
            <span data-cy="前回取込ファイル名">{lastImportFileName}</span>
          </div>
          <div className="col-5">
            <span style={styles.text1}>前回取込日時　　</span>
            <span data-cy="前回取込日時">{lastImportDateTime}</span>
          </div>
        </div>
      </div>
      <div
        className={"ag-theme-alpine b-ag-deleted-row b-header-row-white"}
        style={{ position: "relative" }}
        data-cy="交換推奨部品グリッド"
      >
        <AgGridReact
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={gridData}
          overlayNoRowsTemplate={noRowsImportVesselParts}
          pagination
          paginationPageSize={20}
          ref={gridRef}
        />
        <PageSizeSelector grid={gridRef.current} />
      </div>
      {/* ボタン */}
      <input
        type="file"
        id="file"
        accept=".csv"
        style={{ display: "none" }}
        onChange={selectFile}
        onClick={(e: any) => {
          e.target.value = "";
        }}
        data-cy="交換推奨部品ファイル選択"
      />
      <div className="text-center my-2">
        <button
          type="button"
          className="btn b-btn-primary"
          onClick={() => document.getElementById("file")?.click()}
          disabled={!importButtonState ? true : false}
          data-cy="交換推奨部品取込ボタン"
        >
          交換推奨部品取込
        </button>
        <input
          type="button"
          className="btn b-btn-delete ms-3"
          value="取込済交換推奨部品削除"
          disabled={!deleteButtonState ? true : false}
          onClick={() =>
            showDialog({
              id: "I017",
              confirm: true,
              callback: (isOk) => {
                if (isOk) {
                  deleteVesselProducs();
                }
              },
            })
          }
          style={{ width: 200 }}
          data-cy="取込済交換推奨部品削除ボタン"
        />
      </div>
    </>
  );
}

export default ImportShipParts;
