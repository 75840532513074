import { ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, RefObject, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useAuthUserContext } from "src/context/AuthUser";
import { OrderStatus, QuotationStatus, TextConst } from "src/util/Constant";
import { SelectOption, defaultSelectStyle } from "src/util/SelectUtil";
import { formatDate } from "src/util/ecUtil";

import { GridMaintenanceScheduleType } from "./RepairSchedule";
import RepairScheduleAccordionArea from "./RepairScheduleAccordionArea";

type Props = {
  imoNo: string | undefined;
  allSettingMaintenanceScheduleFlg: boolean;
  gridMaintenaceScheduleList: GridMaintenanceScheduleType[] | null;
  noRowsText: string;
  dockCountryOptions: SelectOption<string>[];
  inspectionDetailOptions: SelectOption<string>[];

  gridRefMaitenanceSchedule: RefObject<AgGridReact<any>>;

  setAllSettingMaintenanceScheduleFlg: (value: boolean) => void;

  editableRecommendProductsArea: () => boolean;
  editableMaintenanceScheduleArea: () => boolean;
  handleClickInpectionSaveButton: () => void;
  handleChangeSelect: (
    name: string | undefined,
    e: SingleValue<SelectOption<string | undefined>>,
    rowIndex: number,
    timing: number
  ) => void;
  handleChangeInput: (
    name: string | undefined,
    e: ChangeEvent<HTMLInputElement>,
    rowIndex: number
  ) => void;
  handleChangeDate: (
    name: string | undefined,
    e: ChangeEvent<HTMLInputElement>,
    rowIndex: number
  ) => void;
  handleChangeTextArea: (
    name: string | undefined,
    e: ChangeEvent<HTMLTextAreaElement>,
    rowIndex: number
  ) => void;
  handleChangeToggle: (
    name: string | undefined,
    e: ChangeEvent<HTMLInputElement>,
    rowIndex: number
  ) => void;
  yearCheckValue: number;
};

// 折り返しスタイル
const wrapStyle: React.CSSProperties = {
  lineHeight: "normal",
  whiteSpace: "normal",
};

function RepairScheduleMaintenanceSchedule(props: Props) {
  // 権限情報
  const auth = useAuthUserContext();

  // 定期点検・部品交換情報のアコーディオン開閉状態
  const [showScheduleAccordion, setScheduleAccordion] = useState(true);

  // 定期点検・部品交換情報エリアのラベルのレンダラー
  function labelCellRenderer(params: ICellRendererParams) {
    let val = "";

    switch (params.colDef?.field) {
      case "inspectionCost":
        // 定期点検基本料金の場合
        if (params.data.inspectionCost) {
          val = params.data.inspectionCost;
        }
        break;
      case "planTotalWorkCost":
        // 作業費用計の場合
        if (params.data.planTotalWorkCost) {
          val = params.data.planTotalWorkCost;
        }
        break;
      case "planTotalWorkCostWeekOut":
        // 作業費用計(平日時間外)の場合
        if (params.data.planTotalWorkCostWeekOut) {
          val = params.data.planTotalWorkCostWeekOut;
        }
        break;
      case "planTotalWorkCostHol":
        // 作業費用計(休日)の場合
        if (params.data.planTotalWorkCostHol) {
          val = params.data.planTotalWorkCostHol;
        }
        break;
      case "planTotalWorkCostHolOut":
        // 作業費用計(休日時間外)の場合
        if (params.data.planTotalWorkCostHolOut) {
          val = params.data.planTotalWorkCostHolOut;
        }
        break;
      case "planTotalWorkCostForeign":
        // 作業費用計(海外)の場合
        if (params.data.planTotalWorkCostForeign) {
          val = params.data.planTotalWorkCostForeign;
        }
        break;
      default:
        break;
    }
    return (
      <>
        {(!auth.user().bemacFlg || !auth.isEngineer()) && (
          <div className="d-flex align-items-center">
            <div
              className="form-control my-3"
              style={{ height: "2.35rem" }}
              data-cy={
                params.colDef?.field === "inspectionCost"
                  ? "定期点検基本料金"
                  : params.colDef?.field === "planTotalWorkCost"
                  ? "作業費用計"
                  : params.colDef?.field === "planTotalWorkCostWeekOut"
                  ? "作業費用計(平日)"
                  : params.colDef?.field === "planTotalWorkCostHol"
                  ? "作業費用計(休日)"
                  : params.colDef?.field === "planTotalWorkCostHolOut"
                  ? "作業費用計(休日時間外)"
                  : params.colDef?.field === "planTotalWorkCostForeign"
                  ? "作業費用計(海外)"
                  : ""
              }
            >
              {val === ""
                ? Number(0).toLocaleString(undefined, {
                    style: "currency",
                    currency: "JPY",
                  })
                : Number(val).toLocaleString(undefined, {
                    style: "currency",
                    currency: "JPY",
                  })}
            </div>
          </div>
        )}
        {auth.user().bemacFlg && auth.isEngineer() && (
          <label
            style={wrapStyle}
            className="my-2 text-start"
            data-cy={
              params.colDef?.field === "inspectionCost"
                ? "定期点検基本料金"
                : params.colDef?.field === "planTotalWorkCost"
                ? "作業費用計"
                : params.colDef?.field === "planTotalWorkCostWeekOut"
                ? "作業費用計(平日)"
                : params.colDef?.field === "planTotalWorkCostHol"
                ? "作業費用計(休日)"
                : params.colDef?.field === "planTotalWorkCostHolOut"
                ? "作業費用計(休日時間外)"
                : params.colDef?.field === "planTotalWorkCostForeign"
                ? "作業費用計(海外)"
                : ""
            }
          >
            {val === ""
              ? Number(0).toLocaleString(undefined, {
                  style: "currency",
                  currency: "JPY",
                })
              : Number(val).toLocaleString(undefined, {
                  style: "currency",
                  currency: "JPY",
                })}
          </label>
        )}
      </>
    );
  }

  // 定期点検・部品交換情報エリアのテキストボックスのレンダラー
  // テキストボックスのレンダラー
  function textBoxCellRenderer(params: ICellRendererParams) {
    let val = "";
    let unit = "";
    let kind: "text" | "number" | "date" = "number";
    let maxLength = 0;
    let disabledFlg = false;

    // 非活性条件
    if (
      params.data.orderCreateUserId === -1000 ||
      (params.colDef?.field !== "dockName" &&
        params.colDef?.field !== "dockScheduleStart" &&
        params.colDef?.field !== "dockScheduleEnd" &&
        params.data.quotationNo &&
        params.data.quotationStatusClassValue !== QuotationStatus.DRAFT &&
        params.data.quotationStatusClassValue !== QuotationStatus.REQUESTED) ||
      ((params.colDef?.field === "dockName" ||
        params.colDef?.field === "dockScheduleStart" ||
        params.colDef?.field === "dockScheduleEnd") &&
        ((params.data.quotationNo &&
          params.data.quotationStatusClassValue !== QuotationStatus.DRAFT &&
          params.data.quotationStatusClassValue !== QuotationStatus.REQUESTED &&
          params.data.quotationStatusClassValue !== QuotationStatus.ANSWERED &&
          params.data.quotationStatusClassValue !== QuotationStatus.ACCEPTED) ||
          (params.data.orderNo &&
            params.data.orderStatusClassValue !== OrderStatus.ORDERED &&
            params.data.orderStatusClassValue !== OrderStatus.ARRANGING &&
            params.data.orderStatusClassValue !==
              OrderStatus.REQUESTED_CHANGE)))
    ) {
      // ドック名、ドックスケジュールの場合は注文完了時非活性にする
      // その他は見積作成中且つ、見積依頼中でない場合非活性にする
      disabledFlg = true;
    }

    switch (params.colDef?.field) {
      case "dockName":
        // ドック名の場合
        if (params.data.dockName) {
          val = params.data.dockName;
        }
        kind = "text";
        maxLength = 50;
        break;
      case "dockScheduleStart":
        // ドックスケジュール開始日の場合
        if (params.data.dockScheduleStart) {
          val = formatDate(params.data.dockScheduleStart, "YYYY-MM-DD");
        }
        kind = "date";
        break;
      case "dockScheduleEnd":
        // ドックスケジュール終了日の場合
        if (params.data.dockScheduleEnd) {
          val = formatDate(params.data.dockScheduleEnd, "YYYY-MM-DD");
        }
        kind = "date";
        break;
      case "acbMitsubishiQuantity":
        // ACB(三菱)の場合
        if (params.data.acbMitsubishiQuantity) {
          val = params.data.acbMitsubishiQuantity;
        }
        unit = "　式";
        kind = "number";
        maxLength = 2;
        if (!props.editableRecommendProductsArea()) {
          disabledFlg = true;
        }

        break;
      case "acbTerasakiQuantity":
        // ACB(寺崎)の場合
        if (params.data.acbTerasakiQuantity) {
          val = params.data.acbTerasakiQuantity;
        }
        unit = "　式";
        kind = "number";
        maxLength = 2;
        if (!props.editableRecommendProductsArea()) {
          disabledFlg = true;
        }
        break;
      case "inspectionCost":
        // 定期点検基本料金の場合
        if (params.data.inspectionCost) {
          val = params.data.inspectionCost;
        }
        kind = "number";
        maxLength = 10;

        //自動化装置、ロガーのいずれかが設定、もしくはACB(寺崎)、ACB(三菱)のいずれかが設定されていたら非活性
        if (
          params.data.autoMachineFlg ||
          params.data.dataLoggerFlg ||
          (!!params.data.acbMitsubishiQuantity &&
            params.data.acbMitsubishiQuantity !== "0") ||
          (!!params.data.acbTerasakiQuantity &&
            params.data.acbTerasakiQuantity !== "0")
        ) {
          disabledFlg = true;
        }

        if (!props.editableRecommendProductsArea()) {
          disabledFlg = true;
        }
        break;
      case "planTariffPerHour":
        // 作業タリフ
        if (params.data.planTariffPerHour) {
          val = params.data.planTariffPerHour;
        }
        kind = "number";
        maxLength = 5;
        if (!props.editableRecommendProductsArea()) {
          disabledFlg = true;
        }
        break;
      case "planTariffPerHourWeekOut":
        // 作業タリフ(平日)
        if (params.data.planTariffPerHourWeekOut) {
          val = params.data.planTariffPerHourWeekOut;
        }
        kind = "number";
        maxLength = 5;
        if (!props.editableRecommendProductsArea()) {
          disabledFlg = true;
        }
        break;
      case "planTariffPerHourHol":
        // 作業タリフ(休日)
        if (params.data.planTariffPerHourHol) {
          val = params.data.planTariffPerHourHol;
        }
        kind = "number";
        maxLength = 5;
        if (!props.editableRecommendProductsArea()) {
          disabledFlg = true;
        }
        break;
      case "planTariffPerHourHolOut":
        // 作業タリフ(休日時間外)
        if (params.data.planTariffPerHourHolOut) {
          val = params.data.planTariffPerHourHolOut;
        }
        kind = "number";
        maxLength = 5;
        if (!props.editableRecommendProductsArea()) {
          disabledFlg = true;
        }
        break;
      case "planTariffPerHourForeign":
        // 作業タリフ(海外)
        if (params.data.planTariffPerHourForeign) {
          val = params.data.planTariffPerHourForeign;
        }
        kind = "number";
        maxLength = 5;
        if (!props.editableRecommendProductsArea()) {
          disabledFlg = true;
        }
        break;

      case "planInspectionTime":
        // 作業時間の場合
        if (params.data.planInspectionTime) {
          val = params.data.planInspectionTime;
        }
        unit = "　h";
        kind = "number";
        maxLength = 2;
        if (!props.editableRecommendProductsArea()) {
          disabledFlg = true;
        }
        break;

      case "planInspectionTimeWeekOut":
        // 作業時間(平日時間外)の場合
        if (params.data.planInspectionTimeWeekOut) {
          val = params.data.planInspectionTimeWeekOut;
        }
        unit = "　h";
        kind = "number";
        maxLength = 2;
        if (!props.editableRecommendProductsArea()) {
          disabledFlg = true;
        }
        break;
      case "planInspectionTimeHol":
        // 作業時間(休日)の場合
        if (params.data.planInspectionTimeHol) {
          val = params.data.planInspectionTimeHol;
        }
        unit = "　h";
        kind = "number";
        maxLength = 2;
        if (!props.editableRecommendProductsArea()) {
          disabledFlg = true;
        }
        break;
      case "planInspectionTimeHolOut":
        // 作業時間(休日時間外)の場合
        if (params.data.planInspectionTimeHolOut) {
          val = params.data.planInspectionTimeHolOut;
        }
        unit = "　h";
        kind = "number";
        maxLength = 2;
        if (!props.editableRecommendProductsArea()) {
          disabledFlg = true;
        }
        break;

      case "planInspectionTimeForeign":
        // 作業時間(海外)の場合
        if (params.data.planInspectionTimeForeign) {
          val = params.data.planInspectionTimeForeign;
        }
        unit = "　h";
        kind = "number";
        maxLength = 2;
        if (!props.editableRecommendProductsArea()) {
          disabledFlg = true;
        }
        break;

      default:
        break;
    }

    return (
      <>
        {props.editableMaintenanceScheduleArea() && (
          <div className="d-flex align-items-center">
            {kind === "text" && (
              <textarea
                className="form-control my-2 textarea"
                rows={2}
                disabled={disabledFlg}
                value={val}
                maxLength={maxLength}
                onChange={(e) => {
                  props.handleChangeTextArea(
                    params.colDef?.field,
                    e,
                    params.rowIndex
                  );
                }}
                data-cy={
                  !params.colDef
                    ? ""
                    : params.colDef.field === "dockName"
                    ? "ドック名テキスト"
                    : ""
                }
              />
            )}
            {kind === "date" && (
              <input
                type={kind}
                className="form-control my-3"
                value={val}
                disabled={disabledFlg}
                onChange={(e) => {
                  props.handleChangeDate(
                    params.colDef?.field,
                    e,
                    params.rowIndex
                  );
                }}
                data-cy={
                  !params.colDef
                    ? ""
                    : params.colDef.field === "dockScheduleStart"
                    ? "ドック予定開始テキスト"
                    : params.colDef.field === "dockScheduleEnd"
                    ? "ドック予定終了テキスト"
                    : ""
                }
              />
            )}
            {kind === "number" && (
              <input
                type={kind}
                className="form-control my-3"
                value={val}
                maxLength={maxLength}
                disabled={disabledFlg}
                onChange={(e) => {
                  props.handleChangeInput(
                    params.colDef?.field,
                    e,
                    params.rowIndex
                  );
                }}
                data-cy={
                  !params.colDef
                    ? ""
                    : params.colDef.field === "acbMitsubishiQuantity"
                    ? "ACB(三菱)テキスト"
                    : params.colDef.field === "acbTerasakiQuantity"
                    ? "ACB(寺崎)テキスト"
                    : params.colDef.field === "planTariffPerHour"
                    ? "作業タリフテキスト"
                    : params.colDef.field === "planTariffPerHourWeekOut"
                    ? "作業タリフテキスト(平日時間外)"
                    : params.colDef.field === "planTariffPerHourHol"
                    ? "作業タリフテキスト(休日)"
                    : params.colDef.field === "planTariffPerHourHolOut"
                    ? "作業タリフテキスト(休日時間外)"
                    : params.colDef.field === "planTariffPerHourForeign"
                    ? "作業タリフテキスト(海外)"
                    : params.colDef.field === "planInspectionTime"
                    ? "作業時間テキスト"
                    : params.colDef.field === "planInspectionTimeWeekOut"
                    ? "作業時間テキスト(平日時間外)"
                    : params.colDef.field === "planInspectionTimeHol"
                    ? "作業時間テキスト(休日)"
                    : params.colDef.field === "planInspectionTimeHolOut"
                    ? "作業時間テキスト(休日時間外)"
                    : params.colDef.field === "planInspectionTimeForeign"
                    ? "作業時間テキスト(海外)"
                    : ""
                }
              />
            )}

            {unit}
          </div>
        )}
        {!props.editableMaintenanceScheduleArea() && val && val !== "" && (
          <label
            style={wrapStyle}
            className="my-2 text-start"
            data-cy={
              !params.colDef
                ? ""
                : params.colDef.field === "dockName"
                ? "ドック名"
                : params.colDef.field === "dockScheduleStart"
                ? "ドック予定開始"
                : params.colDef.field === "dockScheduleEnd"
                ? "ドック予定終了"
                : params.colDef.field === "acbMitsubishiQuantity"
                ? "ACB(三菱)"
                : params.colDef.field === "acbTerasakiQuantity"
                ? "ACB(寺崎)"
                : params.colDef.field === "inspectionCost"
                ? "定期点検基本料金"
                : params.colDef.field === "planTariffPerHour"
                ? "作業タリフ"
                : params.colDef.field === "planTariffPerHourWeekOut"
                ? "作業タリフ(平日時間外)"
                : params.colDef.field === "planTariffPerHourHol"
                ? "作業タリフ(休日)"
                : params.colDef.field === "planTariffPerHourHolOut"
                ? "作業タリフ(休日時間外)"
                : params.colDef.field === "planTariffPerHourForeign"
                ? "作業タリフ(海外)"
                : params.colDef.field === "planInspectionTime"
                ? "作業時間"
                : params.colDef.field === "planInspectionTimeWeekOut"
                ? "作業時間(平日時間外)"
                : params.colDef.field === "planInspectionTimeHol"
                ? "作業時間(休日)"
                : params.colDef.field === "planInspectionTimeHolOut"
                ? "作業時間(休日時間外)"
                : params.colDef.field === "planInspectionTimeForeign"
                ? "作業時間(海外)"
                : params.colDef.field === "planTotalWorkCost"
                ? "作業費用計"
                : params.colDef.field === "planTotalWorkCostWeekOut"
                ? "作業費用計(平日時間外)"
                : params.colDef.field === "planTotalWorkCostHol"
                ? "作業費用計(休日)"
                : params.colDef.field === "planTotalWorkCostHolOut"
                ? "作業費用計(休日時間外)"
                : params.colDef.field === "planTotalWorkCostForeign"
                ? "作業費用計(海外)"
                : ""
            }
          >
            {kind === "number"
              ? !params.colDef
                ? ""
                : params.colDef.field === "acbMitsubishiQuantity" ||
                  params.colDef.field === "acbTerasakiQuantity" ||
                  params.colDef.field === "planInspectionTime" ||
                  params.colDef.field === "planInspectionTimeWeekOut" ||
                  params.colDef.field === "planInspectionTimeHol" ||
                  params.colDef.field === "planInspectionTimeHolOut" ||
                  params.colDef.field === "planInspectionTimeForeign"
                ? val + unit
                : Number(val).toLocaleString(undefined, {
                    style: "currency",
                    currency: "JPY",
                  }) + unit
              : kind === "date"
              ? formatDate(val, "YYYY/MM/DD")
              : val + unit}
          </label>
        )}
      </>
    );
  }

  // 定期点検・部品交換情報エリアのコンボボックスのレンダラー
  function comboBoxCellRenderer(params: ICellRendererParams) {
    let val = null;
    let label = undefined;
    let options: SelectOption<string>[] = [];
    let disabledFlg = false;

    if (
      params.data.orderCreateUserId === -1000 ||
      (params.data.quotationNo &&
        params.data.quotationStatusClassValue !== QuotationStatus.DRAFT &&
        params.data.quotationStatusClassValue !== QuotationStatus.REQUESTED)
    ) {
      // 見積作成中且つ、見積依頼中でない場合非活性にする
      disabledFlg = true;
    }

    if (params.colDef) {
      switch (params.colDef.field) {
        case "dockCountryClassValue":
          // ドック国の場合
          options = props.dockCountryOptions;
          if (params.data.dockCountryClassValue) {
            label = props.dockCountryOptions.find(
              (it) => it.value === params.data.dockCountryClassValue
            )?.label;
            val = {
              label: label,
              value: params.data.dockCountryClassValue,
            };
          }
          if (
            (props.editableRecommendProductsArea() &&
              params.data.quotationNo &&
              params.data.quotationStatusClassValue !== QuotationStatus.DRAFT &&
              params.data.quotationStatusClassValue !==
                QuotationStatus.REQUESTED) ||
            (!props.editableRecommendProductsArea() && params.data.quotationNo)
          ) {
            disabledFlg = true;
          }
          break;
        case "inspectionDetailClassValue":
          options = props.inspectionDetailOptions;
          // 点検内訳の場合
          if (params.data.inspectionDetailClassValue) {
            label = props.inspectionDetailOptions.find(
              (it) => it.value === params.data.inspectionDetailClassValue
            )?.label;
            val = {
              label,
              value: params.data.inspectionDetailClassValue,
            };
          }
          if (!props.editableRecommendProductsArea()) {
            disabledFlg = true;
          }
          break;
        default:
          break;
      }

      return (
        <>
          {props.editableMaintenanceScheduleArea() && (
            <div
              data-cy={
                params.colDef?.field === "dockCountryClassValue"
                  ? "ドック国コンボ"
                  : params.colDef?.field === "inspectionDetailClassValue"
                  ? "点検内訳コンボ"
                  : ""
              }
            >
              <Select
                value={val}
                isClearable
                placeholder={TextConst.COMBO_PLACEHOLDER}
                options={options}
                styles={defaultSelectStyle}
                menuPortalTarget={document.body}
                isDisabled={disabledFlg}
                className="my-2"
                onChange={(e) => {
                  props.handleChangeSelect(
                    params.colDef?.field,
                    e,
                    params.rowIndex,
                    Number(params.data.inspectionYearClassValue)
                  );
                }}
              />
            </div>
          )}
          {!props.editableMaintenanceScheduleArea() && (
            <label
              data-cy={
                params.colDef?.field === "dockCountryClassValue"
                  ? "ドック国"
                  : params.colDef?.field === "inspectionDetailClassValue"
                  ? "点検内訳"
                  : ""
              }
            >
              {label}
            </label>
          )}
        </>
      );
    }
  }

  // 定期点検・部品交換情報エリアのトグルのレンダラー
  function toggleCellRenderer(params: ICellRendererParams) {
    let checked = false;
    let text = "";
    let disabledFlg = false;
    if (params.data.orderCreateUserId === -1000) {
      disabledFlg = true;
    } else if (!props.editableRecommendProductsArea()) {
      disabledFlg = true;
    } else if (
      params.data.quotationNo &&
      params.data.quotationStatusClassValue !== QuotationStatus.DRAFT &&
      params.data.quotationStatusClassValue !== QuotationStatus.REQUESTED
    ) {
      // 見積作成中且つ、見積依頼中でない場合非活性にする
      disabledFlg = true;
    }

    switch (params.colDef?.field) {
      case "autoMachineFlg":
        // 自動化装置の場合
        if (params.data.autoMachineFlg) {
          checked = params.data.autoMachineFlg;
        }
        text = checked ? "有り" : "無し";
        break;
      case "dataLoggerFlg":
        // ロガーの場合
        if (params.data.dataLoggerFlg) {
          checked = params.data.dataLoggerFlg;
        }
        text = checked ? "有り" : "無し";
        break;
      default:
        break;
    }

    return (
      <>
        {props.editableMaintenanceScheduleArea() && (
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            className="my-3"
          >
            <div style={checked ? {} : { color: "lightgray" }}>{text}</div>

            <div
              className="form-check form-switch mt-1"
              style={{
                display: "flex",
                alignItems: "center",
                lineHeight: "1.5",
              }}
            >
              <input
                className="form-check-input"
                style={{ marginLeft: "-2rem" }}
                type="checkbox"
                disabled={disabledFlg}
                checked={checked}
                onChange={(e) => {
                  props.handleChangeToggle(
                    params.colDef?.field,
                    e,
                    params.rowIndex
                  );
                }}
                data-cy={
                  !params.colDef
                    ? ""
                    : params.colDef.field === "autoMachineFlg"
                    ? "自動化装置チェックボックス"
                    : params.colDef.field === "dataLoggerFlg"
                    ? "ロガーチェックボックス"
                    : ""
                }
              />
            </div>
          </div>
        )}
        {!props.editableMaintenanceScheduleArea() && (
          <label
            data-cy={
              !params.colDef
                ? ""
                : params.colDef.field === "autoMachineFlg"
                ? "自動化装置"
                : params.colDef.field === "dataLoggerFlg"
                ? "ロガー"
                : ""
            }
          >
            {text}
          </label>
        )}
      </>
    );
  }

  // 定期点検・部品交換情報グリッドの列定義
  const columnDefsMaintenanceSchedule = [
    {
      headerName: "実施年度",
      children: [
        {
          headerName: "",
          field: "inspectionYear",
          width: 150,
          lockPosition: "left",
          headerClass: ["b-cell-border-right-solid-thin-gray"],
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-end"],
          pinned: true,
        },
      ],
      headerClass: ["b-cell-border-right-solid-thin-gray"],
    },
    {
      headerName: "ドック",
      children: [
        {
          headerName: "ドックエリア",
          width: 250,
          field: "dockCountryClassValue",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: comboBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "ドック名",
          width: 250,
          field: "dockName",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "ドック開始日",
          width: 200,
          field: "dockScheduleStart",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "ドック終了日",
          width: 200,
          field: "dockScheduleEnd",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
      ],
      headerClass: ["b-cell-border-right-solid-thin-gray"],
    },
    {
      headerName: "定期点検",
      children: [
        {
          headerName: "自動化装置",
          width: 120,
          field: "autoMachineFlg",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: toggleCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "ロガー",
          width: 120,
          field: "dataLoggerFlg",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: toggleCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "ACB(三菱)",
          width: 150,
          field: "acbMitsubishiQuantity",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "ACB(寺崎)",
          width: 150,
          field: "acbTerasakiQuantity",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "定期点検基本料金",
          width: 260,
          field: "inspectionCost",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "",
          field: "inspectionCostSub",
          hide: true,
        },
      ],
      headerClass: ["b-cell-border-right-solid-thin-gray"],
    },
    {
      headerName: "部品交換",
      openByDefault: false,
      children: [
        {
          headerName: "タリフ(平日)",
          width: 170,
          field: "planTariffPerHour",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "時間(平日)",
          width: 170,
          field: "planInspectionTime",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "作業費(平日)",
          width: 170,
          field: "planTotalWorkCost",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: labelCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "タリフ(平日時間外)",
          width: 170,
          field: "planTariffPerHourWeekOut",
          columnGroupShow: "open",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "時間(平日時間外)",
          width: 170,
          field: "planInspectionTimeWeekOut",
          columnGroupShow: "open",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "作業費(平日時間外)",
          width: 170,
          columnGroupShow: "open",
          field: "planTotalWorkCostWeekOut",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: labelCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "タリフ(休日)",
          width: 170,
          columnGroupShow: "open",
          field: "planTariffPerHourHol",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "時間(休日)",
          width: 170,
          columnGroupShow: "open",
          field: "planInspectionTimeHol",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "作業費(休日)",
          width: 170,
          columnGroupShow: "open",
          field: "planTotalWorkCostHol",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: labelCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "タリフ(休日時間外)",
          width: 170,
          columnGroupShow: "open",
          field: "planTariffPerHourHolOut",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "時間(休日時間外)",
          width: 170,
          columnGroupShow: "open",
          field: "planInspectionTimeHolOut",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "作業費(休日時間外)",
          width: 170,
          columnGroupShow: "open",
          field: "planTotalWorkCostHolOut",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: labelCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "タリフ(海外)",
          width: 170,
          columnGroupShow: "open",
          field: "planTariffPerHourForeign",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "時間(海外)",
          width: 170,
          columnGroupShow: "open",
          field: "planInspectionTimeForeign",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: textBoxCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
        {
          headerName: "作業費(海外)",
          width: 170,
          columnGroupShow: "open",
          field: "planTotalWorkCostForeign",
          lockPosition: "left",
          headerClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-header-cell-center",
          ],
          cellRenderer: labelCellRenderer,
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-center"],
          autoHeight: true,
        },
      ],
      headerClass: ["b-cell-border-right-solid-thin-gray"],
    },
    {
      headerName: "点検内訳",
      children: [
        {
          headerName: "",
          width: 260,
          field: "inspectionDetailClassValue",
          lockPosition: "right",
          cellRenderer: comboBoxCellRenderer,
          autoHeight: true,
        },
      ],
    },
  ];

  return (
    <>
      {" "}
      <RepairScheduleAccordionArea
        className="w-100"
        show={showScheduleAccordion}
        onChangeShow={(value) => {
          setScheduleAccordion(value);
        }}
        title="定期点検・部品交換情報"
      >
        <div>
          <>
            {props.editableMaintenanceScheduleArea() && props.imoNo && (
              <div className="mx-3 mt-3">
                <div className="input-group">
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    一括設定
                  </span>
                  <div
                    className="form-check form-switch"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      lineHeight: "1.5",
                    }}
                  >
                    <input
                      className="form-check-input"
                      style={{ marginLeft: "-2rem" }}
                      type="checkbox"
                      name="replyInstallmentDeliveryFlg"
                      defaultChecked={props.allSettingMaintenanceScheduleFlg}
                      onChange={(e) => {
                        props.setAllSettingMaintenanceScheduleFlg(
                          e.target.checked
                        );
                      }}
                      data-cy="定期点検・部品交換情報一括設定チェックボックス"
                    />
                  </div>
                </div>
              </div>
            )}
          </>
          <div
            className="ag-theme-alpine w-100 mx-auto b-grid-outer"
            style={{ height: 450 }}
            data-cy="定期点検・部品交換情報グリッド"
          >
            <AgGridReact
              domLayout="normal"
              columnDefs={columnDefsMaintenanceSchedule}
              rowData={props.gridMaintenaceScheduleList}
              ref={props.gridRefMaitenanceSchedule}
              rowDragManaged={true}
              overlayNoRowsTemplate={props.noRowsText}
            />
          </div>
          <>
            {props.editableMaintenanceScheduleArea() && props.imoNo && (
              <div className="w-100 my-3 text-end">
                <span style={{ color: "red" }}>
                  ※点検内容の入力後、点検内容登録ボタンを押してください。
                </span>
                <input
                  type="button"
                  className="btn b-btn-primary me-3"
                  value="点検内容登録"
                  onClick={(e) => props.handleClickInpectionSaveButton()}
                  data-cy="点検内容登録ボタン"
                />
              </div>
            )}
          </>
        </div>
      </RepairScheduleAccordionArea>
    </>
  );
}

export default RepairScheduleMaintenanceSchedule;
