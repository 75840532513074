import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuthUserContext } from "../context/AuthUser";

type Props = {
  component: React.ReactNode;
  redirect: string;
};

export const AuthGuard: React.FC<Props> = (props) => {
  const from = useLocation();
  const auth = useAuthUserContext();

  if (!auth.hasAuth()) {
    // 認証されてなければリダイレクトする
    return <Navigate to={props.redirect} state={{ from }} replace={false} />;
  }

  return <>{props.component}</>;
};
