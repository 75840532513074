import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Select, { SelectInstance, SingleValue } from "react-select";
import { useSplashMessage } from "src/hook/useSplashMessage";

import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { ClassValuesGetResponse } from "../../model/api/response/ClassValuesGetResponse";
import { CompaniesGetResponse } from "../../model/api/response/CompaniesGetResponse";
import { UserSuggestionsGetResponse } from "../../model/api/response/UserSuggestionsGetResponse";
import { defaultColDef } from "../../util/AgGridUtil";
import { TextConst } from "../../util/Constant";
import { SelectOption, defaultSelectStyle } from "../../util/SelectUtil";
import { getBizError } from "../../util/ecUtil";
import PageSizeSelector from "../common/PageSizeSelector";
import SearchArea from "../common/SearchArea";
import TooltipIcon from "../common/TooltipIcon";
import CompanyDetail, { CompanyDetailHandles } from "./CompanyDetail";

function CompanyList() {
  //定数宣言
  const USER_CLASS_SALES = "sales";
  const COMPANY_CSV = "得意先マスタCSV";
  const COMPANY_VESSELS_CSV = "管理船舶CSV";
  const FILE_NAME_ERROR_COMPANY_CSV = "エラーファイル_得意先CSV取込.csv";
  const FILE_NAME_ERROR_COMPANY_VESSELS_CSV =
    "エラーファイル_管理船舶CSV取込.csv";
  const FILE_NAME_ERROR_COMPANY_VESSELS_CSV_OUTPUT =
    "エラーファイル_管理船舶CSV出力.csv";
  const COMPANY_VESSELS_OUTPUT = "管理船舶_";

  // API使用宣言
  const api = useApi();

  /// 明細部定義
  const gridRef = useRef<AgGridReact>(null);

  // 得意先詳細定義
  const CompanyDetailRef = useRef<CompanyDetailHandles>(null);

  // 明細0件時のテキスト
  const [noRowsText, setNoRowsText] = useState(TextConst.GRID_INITIAL);

  // Bemac主担当１オプション
  const [bemacClassOptions, setBemacClassOptions] = useState<
    SelectInstance<number>[]
  >([]);

  // Bemac主担当１コンボ
  const bemacClassRef = useRef<SelectInstance<number>>(null);

  //得意先マスタCSV取り込み時間
  const [lastCompanyImportDateTime, setLastCompanyImportDateTime] = useState();
  //管理船舶CSV取り込み時間
  const [lastVesselImportDateTime, setLastVesselImportDateTime] = useState();

  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();
  //ダイアログ仕様宣言
  const showDialog = useDialog();

  // 検索条件（コンボはundefinedが入る）
  type SearchValueType = {
    companyCode: string | undefined;
    companyName: string | undefined;
    responsibleUserId: number | undefined;
  };
  const [searchValues, setSearchValues] = useState<SearchValueType>({
    companyCode: "",
    companyName: "",
    responsibleUserId: undefined,
  });
  // 検索エリアテキストボックス変更
  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.value;
    // 入力値をセット
    setSearchValues({ ...searchValues, [name]: value });
  }

  // 検索エリアコンボ変更
  function handleChangeSelect(
    name: string,
    e: SingleValue<SelectOption<string | number | undefined>>
  ) {
    // 選択値をセット
    const value = e?.value;
    setSearchValues({ ...searchValues, [name]: value });
  }

  // 初回レンダリングのみ実行
  useEffect(() => {
    const userClass = USER_CLASS_SALES ?? "";
    // Bemac主担当１リスト
    api
      .get(`/api/v1/user-suggestions?userClass=${userClass}`)
      .then((response) => {
        const users = response.data.map((row: UserSuggestionsGetResponse) => {
          return {
            label: row.userName,
            value: row.userId,
            delFlg: row.delFlg,
          };
        });
        setBemacClassOptions(users);
      });
    setImportCompanyDateTime(); //得意先マスタCSVの前回取り込み日時を設定
    setImportVesselDateTime(); //管理船舶CSVの前回取り込み日時を設定
    setNoRowsText(TextConst.GRID_INITIAL); //検索エリアのメッセージを初期化
  }, []);

  // 一覧データ
  const [data, setData] = useState<CompaniesGetResponse[]>(() => {
    // 初期値設定
    return [];
  });

  // 検索条件アコーディオン開閉状態
  const [showConditionAccordion, setShowConditionAccordion] = useState(true);

  // イベント
  // 検索ボタンクリック
  function onClickSearchButton() {
    const queryParams: string[] = [];
    //検索条件を設定
    if (searchValues.companyCode) {
      queryParams.push(`companyCode=${searchValues.companyCode}`);
    }
    if (searchValues.companyName) {
      queryParams.push(`companyName=${searchValues.companyName}`);
    }
    if (searchValues.responsibleUserId) {
      queryParams.push(`responsibleUserId=${searchValues.responsibleUserId}`);
    }
    const query = queryParams.join("&");
    //得意先リスト取得APIを呼び出し
    api
      .get(`/api/v1/companies?${query}`)
      .then((response) => {
        // グリッドテキスト変更
        setNoRowsText(TextConst.GRID_NO_DATA);
        // 検索結果をセット
        setData(response.data);
        // 検索条件アコーディオンを開閉を設定
        if (response.data.length > 0) {
          setShowConditionAccordion(false);
        } else {
          setShowConditionAccordion(true);
        }
      })
      .catch((error) => {
        // エラー時の処理
        if (error.response.status !== 404) {
          showDialog({ error });
        }
      });
  }

  //得意先マスタCSV取り込み押下後、ファイルを選択し、開くを押した後の処理
  function selectFileCompany(e: React.ChangeEvent<HTMLInputElement>) {
    let formData = new FormData();
    formData.append("companiesCsv", e.currentTarget.files![0]);
    //得意先CSV登録APIを呼び出し
    api
      .post(`/api/v1/companies-csv`, formData)
      .then((it) => {
        setImportCompanyDateTime();
        //検索条件のクリア、再検索
        searchValues.companyCode = "";
        searchValues.companyName = "";
        searchValues.responsibleUserId = undefined;
        onClickSearchButton();
        splashMessage.show("I059", [COMPANY_CSV]);
      })
      .catch((error) => {
        //csvエラーファイルが返ってきているかどうか
        if (error.response.data !== "") {
          //文字化け回避
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
          const csv = new Blob([bom, error.response.data], {
            type: "text/csv",
          });
          //aタグ作成
          const a = document.createElement("a");
          // ダウンロードされるファイル名
          a.download = FILE_NAME_ERROR_COMPANY_CSV;
          a.href = URL.createObjectURL(csv);
          // ダウンロード開始
          a.click();
        } else {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          }
        }
      });
  }

  //管理船舶CSV取り込み押下後、取り込み前の確認メッセージ
  function onClickVesselfile() {
    showDialog({
      id: "I058",
      confirm: true,
      callback(isOk) {
        if (isOk) {
          // OKが押された場合
          document.getElementById("vesselfile")?.click();
        }
      },
    });
  }

  //管理船舶CSV取り込み押下後、ファイルを選択し、開くを押した後の処理
  function selectFileVessel(e: React.ChangeEvent<HTMLInputElement>) {
    let formData = new FormData();
    formData.append("companyVesselsCsv", e.currentTarget.files![0]);
    //得意先船舶紐付CSV登録APIを呼び出し
    api
      .post(`/api/v1/company-vessels-csv`, formData)
      .then((it) => {
        splashMessage.show("I059", [COMPANY_VESSELS_CSV]);
        setImportVesselDateTime();
      })
      .catch((error) => {
        //csvエラーファイルが返ってきているかどうか
        if (error.response.data !== "") {
          //文字化け回避
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
          const csv = new Blob([bom, error.response.data], {
            type: "text/csv",
          });
          //aタグ作成
          const a = document.createElement("a");
          // ダウンロードされるファイル名
          a.download = FILE_NAME_ERROR_COMPANY_VESSELS_CSV;
          a.href = URL.createObjectURL(csv);
          // ダウンロード開始
          a.click();
        } else {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          }
        }
      });
  }
  //得意先マスタCSV前回取り込み日時の設定
  function setImportCompanyDateTime() {
    //分類値マスタ取得APIを呼び出し
    api.get(`/api/v1/class/csv_upload_datetime/values`).then((response) => {
      const importDateTime = response.data.filter(
        (it: ClassValuesGetResponse) => it.classValue === "COMPANY"
      )[0].className;
      setLastCompanyImportDateTime(importDateTime.trim());
    });
  }
  //管理船舶CSV前回取り込み日時の設定
  function setImportVesselDateTime() {
    //分類値マスタ取得APIを呼び出し
    api.get(`/api/v1/class/csv_upload_datetime/values`).then((response) => {
      const importDateTime = response.data.filter(
        (it: ClassValuesGetResponse) => it.classValue === "COM_VESSEL"
      )[0].className;
      setLastVesselImportDateTime(importDateTime.trim());
    });
  }

  //管理船舶CSV出力
  function outputFileVessel() {
    //得意先船舶紐付CSV取得APIを呼び出し
    api
      .get(`/api/v1/company-vessels-csv`)
      .then((it) => {
        //文字化け回避
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const csv = new Blob([bom, it.data], {
          type: "text/csv",
        });
        //aタグ作成
        const a = document.createElement("a");
        const date = new Date();
        //日付フォーマットをyyyyMMddに
        const outputDate =
          String(date.getFullYear()) +
          String(date.getMonth() + 1).padStart(2, "0") +
          String(date.getDate()).padStart(2, "0");

        // ダウンロードされるファイル名
        a.download = COMPANY_VESSELS_OUTPUT + outputDate + ".csv";
        a.href = URL.createObjectURL(csv);
        // ダウンロード開始
        a.click();
        splashMessage.show("I055");
      })
      .catch((error) => {
        //csvエラーファイルが返ってきているかどうか
        if (error.response.data !== "") {
          //文字化け回避
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
          const csv = new Blob([bom, error.response.data], {
            type: "text/csv",
          });
          //aタグ作成
          const a = document.createElement("a");
          // ダウンロードされるファイル名
          a.download = FILE_NAME_ERROR_COMPANY_VESSELS_CSV_OUTPUT;
          a.href = URL.createObjectURL(csv);
          // ダウンロード開始
          a.click();
        } else {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          }
        }
      });
  }

  // グリッドの列定義
  const columnDefs = [
    {
      headerName: "得意先コード",
      field: "companyCode",
      width: 200,
      sortable: true,
      cellRenderer: (params: any) => {
        return (
          <a
            href="#"
            className="text-link"
            onClick={() => CompanyDetailRef.current?.show(params.value, false)}
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: "得意先名",
      field: "companyName",
      sortable: true,
      width: 400,
    },
    {
      headerName: "BEMAC主担当１",
      field: "bemacCharge",
      width: 350,
      sortable: true,
    },
    {
      headerName: "電話番号",
      field: "tel",
      width: 300,
      sortable: true,
    },
  ];

  return (
    <div className="b-container-list">
      <div className="title-area-style">
        <span>得意先一覧</span>
      </div>
      {/* 検索条件定義 */}
      <SearchArea
        show={showConditionAccordion}
        toolTip={
          <TooltipIcon
            messageId="T001"
            args={[100, "得意先コード"]}
            data-cy="検索ツールチップ"
          />
        }
        onChangeShow={(value) => {
          setShowConditionAccordion(value);
        }}
      >
        <div className="row">
          <div className="col-4 d-flex">
            <span className="col-form-label px-2 text-nowrap">
              得意先コード
            </span>
            <input
              type="text"
              name="companyCode"
              placeholder={TextConst.INPUT_PLACEHOLDER}
              className="form-control border-0"
              maxLength={10}
              value={searchValues.companyCode}
              onChange={handleChangeInput}
              data-cy="得意先コードテキスト"
            />
          </div>
          <div className="col-4 d-flex">
            <span className="col-form-label px-2 text-nowrap">得意先名</span>
            <input
              type="text"
              name="companyName"
              placeholder={TextConst.INPUT_PLACEHOLDER}
              className="form-control border-0"
              maxLength={100}
              value={searchValues.companyName}
              onChange={handleChangeInput}
              data-cy="得意先名テキスト"
            />
          </div>
          <div className="col-4 d-flex">
            <span className="col-form-label px-2 text-nowrap">
              BEMAC主担当１
            </span>
            <div data-cy="BEMAC主担当１コンボ" className="flex-grow-1">
              <Select
                instanceId="bemacSelect"
                styles={defaultSelectStyle}
                placeholder={TextConst.COMBO_PLACEHOLDER}
                isClearable
                options={bemacClassOptions}
                onChange={(e) => {
                  handleChangeSelect("responsibleUserId", e);
                }}
                ref={bemacClassRef}
              />
            </div>
          </div>
        </div>
        {/* 検索ボタン */}
        <div className="text-center">
          <input
            type="button"
            className="btn b-btn-primary"
            value={"検索"}
            onClick={(e) => onClickSearchButton()}
            data-cy="検索ボタン"
          />
        </div>
      </SearchArea>
      <div
        className="ag-theme-alpine b-ag-deleted-row b-header-row-white"
        style={{ position: "relative", margin: "10px 30px 0px 30px" }}
        data-cy="得意先一覧グリッド"
      >
        {/* グリッド定義 */}
        <AgGridReact
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowSelection="multiple"
          suppressRowClickSelection
          pagination={true}
          paginationPageSize={20}
          rowData={data}
          ref={gridRef}
          overlayNoRowsTemplate={noRowsText}
        />
        <PageSizeSelector grid={gridRef.current} />
      </div>
      <input
        type="file"
        id="companyfile"
        accept=".csv"
        style={{ display: "none" }}
        onChange={selectFileCompany}
        onClick={(e: any) => {
          e.target.value = "";
        }}
        data-cy="得意先CSVファイル選択"
      />
      <input
        type="file"
        id="vesselfile"
        accept=".csv"
        style={{ display: "none" }}
        onChange={selectFileVessel}
        onClick={(e: any) => {
          e.target.value = "";
        }}
        data-cy="管理船舶CSVファイル選択"
      />
      {/* ボタン定義 */}
      <div
        className="text-center my-2"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div>
          <input
            type="button"
            className="btn b-btn-primary"
            value="得意先マスタ取込"
            onClick={() => document.getElementById("companyfile")?.click()}
            style={{ width: 150 }}
            data-cy="得意先マスタ取込ボタン"
          />
          <div className="text-center" style={{ color: "gray" }}>
            {lastCompanyImportDateTime !== undefined &&
              lastCompanyImportDateTime !== "" && (
                <div data-cy="得意先CSV前回取込成功日時">
                  前回CSV取込成功日時 :<br></br> {lastCompanyImportDateTime}
                </div>
              )}
          </div>
        </div>
        <div className="ms-3">
          <input
            type="button"
            className="btn b-btn-primary"
            value="管理船舶取込"
            onClick={() => onClickVesselfile()}
            style={{ width: 150 }}
            data-cy="管理船舶取込ボタン"
          />
          <div className="text-center" style={{ color: "gray" }}>
            {lastVesselImportDateTime !== undefined &&
              lastVesselImportDateTime !== "" && (
                <div data-cy="管理船舶CSV前回取込成功日時">
                  前回CSV取込成功日時 : <br></br>
                  {lastVesselImportDateTime}
                </div>
              )}
          </div>
        </div>
        <div className="ms-3">
          <input
            type="button"
            className="btn b-btn-primary"
            value="管理船舶出力"
            onClick={() => outputFileVessel()}
            style={{ width: 150 }}
            data-cy="管理船舶出力ボタン"
          />
          <TooltipIcon
            messageId="T011"
            position="up-left"
            data-cy="CSV出力ツールチップ"
          />
        </div>
      </div>
      <CompanyDetail ref={CompanyDetailRef} />
    </div>
  );
}

export default CompanyList;
