/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useDropzone } from "react-dropzone";

/** prop定義 */
type Props = {
  onSelectFiles: (files: File[]) => void;
};

/** style定義 */
const areaStyle = css`
  padding: 1rem;
  border-style: dashed;
  border-radius: 11px;
  color: gray;
  &:hover {
    cursor: pointer;
  }
  label:hover {
    cursor: pointer;
  }
`;

/** メイン関数 */
function FileDropZone(props: Props) {
  // ファイル選択時orドロップ時
  const onDrop = (files: File[]) => {
    props.onSelectFiles(files);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // レンダリング
  return (
    <>
      <div css={areaStyle} {...getRootProps()}>
        <input {...getInputProps()} data-cy="添付ファイル選択" />
        <div>ここをクリックするかファイルをドラッグして添付できます</div>
      </div>
    </>
  );
}

export default FileDropZone;
