import {
  ChangeEvent,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Modal } from "react-bootstrap";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { useSplashMessage } from "../../hook/useSplashMessage";
import { ProductSubPostRequest } from "../../model/api/request/ProductSubPostRequest";
import { ProductGetResponse } from "../../model/api/response/ProductGetResponse";
import { getBizError } from "../../util/ecUtil";
import S3Uploader, { S3UploaderHandles } from "../common/S3Uploader";
import TooltipIcon from "../common/TooltipIcon";

// 公開する関数の定義
export interface ProductDetailHandles {
  show(productCode: string | undefined, detailNo: number | undefined): void;
}
export interface ProductListModalHandles {
  show(): void;
}
type updateButtonName = "更新";
type Props = {
  isMaintenanceMode: boolean;
  onClick?: (productCode: string, detailNo: number | undefined) => void;
  isSelectedProduct?: (
    productCode: string,
    detailNo: number | undefined
  ) => boolean;
  onClickSaveButton?: (buttonName: updateButtonName) => void;
};
/** 商品詳細コンポーネント */
const ProductDetail = forwardRef<ProductDetailHandles, Props>((props, ref) => {
  // ログイン情報(Bemac管理者か否か)
  const isBemacAdmin = useAuthUserContext().isBemacAdmin;

  // ダイアログ使用宣言
  const showDialog = useDialog();
  //API使用宣言
  const api = useApi();
  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();

  // エリアごとのCSSクラス
  const areaClass = "p-1 bg-white b-detail-items";

  //単価
  let [unitPriceValue, setUnitPriceValue] = useState<string>("");

  // ファイル添付
  const uploaderRef = useRef<S3UploaderHandles>(null);

  // ユーザ入力有無
  const [formChanged, setFormChanged] = useState<boolean>(false);

  // 公開する関数の実装
  useImperativeHandle(ref, () => ({
    show(productCode: string | undefined, detailNo: number | undefined) {
      setFormChanged(false);
      //商品単一取得API実行
      api
        .get(`/api/v1/products/${productCode}`)
        .then((it) => {
          setProductData(it.data); //商品情報
          setDetailNo(detailNo);
          setPostRequest({
            productCode: it.data.productCode,
            updateDateTime: it.data.updateDateTime,
            note: it.data.note ?? "",
          });
          if (it.data.unitPrice === undefined || it.data.unitPrice === null) {
            setUnitPriceValue("要問合せ");
          } else {
            setUnitPriceValue(`\u00A5${it.data.unitPrice.toLocaleString()}`);
          }

          //ファイルアップロード先のpath
          const s3root = "product-image/" + productCode + "/";
          uploaderRef.current?.init(s3root, true);
        })
        .catch((error) => {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          } else {
            showDialog({ id: "E026" });
          }
        });

      //選択状態か否かでボタンの表示を変更
      if (props.isSelectedProduct) {
        if (props.isSelectedProduct(productCode!, detailNo)) {
          setSelectButtonValue("選択中");
        } else {
          setSelectButtonValue("選択");
        }
      }
      setShow(true);
    },
  }));

  // state
  /** 商品情報 */
  const [productData, setProductData] = useState<ProductGetResponse>();
  /** 参照明細NO */
  const [detailNo, setDetailNo] = useState<number | undefined>();
  /** 商品サブ登録リクエスト*/
  const [postRequest, setPostRequest] = useState<ProductSubPostRequest>({
    productCode: "",
    note: "",
    updateDateTime: "",
  });
  const [show, setShow] = useState(false);
  const [selectButtonValue, setSelectButtonValue] = useState<string>("選択");

  // モーダルクローズハンドラ
  const handleClose = () => {
    if (formChanged || uploaderRef.current?.hasChanged()) {
      //変更があるとき
      showDialog({
        id: "I001",
        confirm: true,
        callback: (isOk) => {
          if (isOk) {
            setFormChanged(false);
            setProductData(undefined);
            setDetailNo(undefined);
            setShow(false);
          }
        },
      });
    } else {
      setProductData(undefined);
      setDetailNo(undefined);
      setShow(false);
    }
  };

  //テキストボックス入力値変更時の処理
  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    setFormChanged(true);
    const value = e.target.value;
    setPostRequest({ ...postRequest, note: value });
  }

  //更新ボタン押下時、更新を実行するか確認するダイアログを表示する
  function onClickUpdateButton(buttonName: updateButtonName) {
    showDialog({
      id: "I004",
      confirm: true,
      callback(isOk) {
        if (isOk) {
          // OKが押された場合
          onClickUpdateButtonEvent(buttonName);
        }
      },
    });
  }

  //更新処理実行
  function onClickUpdateButtonEvent(buttonName: updateButtonName) {
    api
      .post(`/api/v1/product-sub`, postRequest)
      .then((it) => {
        uploaderRef.current!.save().then(() => {
          splashMessage.show("I051");
          setProductData(undefined);
          setDetailNo(undefined);
          setShow(false);
          if (props.onClickSaveButton) {
            props.onClickSaveButton(buttonName);
          }
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          //エラーメッセージの表示
          showDialog({ id: "E051" });
        } else if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E026" });
        }
      });
  }

  // 選択ボタンクリック
  function onClickOnSelectButton() {
    if (props.onClick) {
      props.onClick(productData!.productCode, detailNo);
    }
    setProductData(undefined);
    setShow(false);
  }

  // 添付ファイル変更可否判定
  function editableFileUpload() {
    return props.isMaintenanceMode && isBemacAdmin() ? true : false;
  }

  // スタイル
  // ヘッダ部見出しの幅
  const itemTitleStyle = { width: "10rem" };

  // モーダルbody部レンダリング
  function modalBody() {
    return (
      <>
        <div className={areaClass}>
          <div className="row">
            <div className="input-group">
              <div className="col-1 b-input-group-text" style={itemTitleStyle}>
                品目コード
              </div>
              <span className="p-2 col-4 form-control" data-cy="品目コード">
                {productData?.productCode}
              </span>
              <div className="col-1 b-input-group-text" style={itemTitleStyle}>
                メーカー名
              </div>
              <span className="p-2 col-4 form-control" data-cy="メーカー名">
                {productData?.makerName}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="input-group">
              <div className="col-1 b-input-group-text" style={itemTitleStyle}>
                品名
              </div>
              <span className="p-2 col-4 form-control" data-cy="品名">
                {productData?.productGroupName}
              </span>
              <div className="col-1 b-input-group-text" style={itemTitleStyle}>
                型式
              </div>
              <span className="p-2 col-4 form-control" data-cy="型式">
                {productData?.spec}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="input-group">
              <div className="col-1 b-input-group-text" style={itemTitleStyle}>
                単位
              </div>
              <span className="p-2 col-2 form-control" data-cy="単位">
                {productData?.unit}
              </span>
              <div className="col-1 b-input-group-text" style={itemTitleStyle}>
                単価
              </div>
              <span
                className="p-2 col-2  form-control"
                style={{
                  textAlign: unitPriceValue === "要問合せ" ? "left" : "right",
                }}
                data-cy="単価"
              >
                {unitPriceValue}
              </span>
              <div className="col-1 b-input-group-text" style={itemTitleStyle}>
                標準納期
              </div>
              <span className="p-2 col-2 form-control" data-cy="標準納期">
                {productData?.deliveryDate}
              </span>
            </div>
          </div>
          <div className="col-12">
            <div className="input-group">
              <span className="b-input-group-text" style={itemTitleStyle}>
                備考
                {isBemacAdmin() && props.isMaintenanceMode && (
                  <span className="px-1">
                    <TooltipIcon messageId="T008"></TooltipIcon>
                  </span>
                )}
              </span>

              {isBemacAdmin() && props.isMaintenanceMode ? (
                <input
                  type="text"
                  className="form-control"
                  maxLength={50}
                  value={postRequest.note}
                  onChange={handleChangeInput}
                  data-cy="備考テキスト"
                /> // BEMAC管理者でマスタ管理の場合
              ) : (
                <span className="p-2 form-control" data-cy="備考">
                  {productData?.note}
                </span> // 検索機能の場合
              )}
            </div>
          </div>
        </div>
        <div className={areaClass}>
          {isBemacAdmin() &&
            props.isMaintenanceMode && ( // BEMAC管理者でマスタ管理の場合
              <div className="pt-2">
                <div className="pb-1">
                  <span
                    className="b-input-group-text"
                    style={{ display: "inline" }}
                  >
                    ファイルを選択
                  </span>
                  <span className="ms-2" style={{ color: "red" }}>
                    ※ファイル添付は、最大2件までです。
                  </span>
                </div>
              </div>
            )}
          <S3Uploader
            editable={editableFileUpload()}
            maxCount={2}
            ref={uploaderRef}
            imgWidth={200}
          />
        </div>
      </>
    );
  }

  // レンダリング
  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      scrollable
      data-cy="商品詳細モーダル"
    >
      <Modal.Header closeButton>
        <Modal.Title>商品詳細</Modal.Title>
      </Modal.Header>

      <Modal.Body>{modalBody()}</Modal.Body>

      <Modal.Footer>
        {isBemacAdmin() &&
          props.isMaintenanceMode && ( // BEMAC管理者でマスタ管理の場合
            <Button
              className="b-btn-primary"
              onClick={(e) => onClickUpdateButton("更新")}
              data-cy="更新ボタン"
            >
              更新
            </Button>
          )}
        {!props.isMaintenanceMode && (
          // 検索機能の場合
          <Button
            className="text-white b-btn-primary border-0"
            style={
              selectButtonValue === "選択中"
                ? { backgroundColor: "#02aafe" }
                : { backgroundColor: "#FD6B00" }
            }
            onClick={onClickOnSelectButton}
            data-cy="選択切替ボタン"
          >
            {selectButtonValue}
          </Button>
        )}
        <Button
          className="b-btn-close ms-3"
          onClick={handleClose}
          data-cy="Closeボタン"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default ProductDetail;
