import LcmCalendarList from "src/component/lcm/LcmCalendarList";

/** ページヘッダの高さ */
export const PAGE_HEADER_HEIGHT = "50px";

// 見積番号オールゼロ
export const QUOTATION_NO_ALL_ZERO = "0000000";

// 注文番号オールゼロ
export const ORDER_NO_ALL_ZERO = "0000000";

/** 見積ステータス */
export namespace QuotationStatus {
  /** 見積作成中 */
  export const DRAFT = "0";
  /** 見積依頼中 */
  export const REQUESTED = "1";
  /** 見積取消 */
  export const DELETED = "2";
  /** 見積回答確認中 */
  export const ANSWERED = "3";
  /** 見積否成約 */
  export const DENIED = "4";
  /** 見積成約 */
  export const ACCEPTED = "5";
}

/** 注文ステータス */
export namespace OrderStatus {
  /** 注文作成中 */
  export const DRAFT = "0";
  /** 注文中（顧客が注文した） */
  export const ORDERED = "1";
  /** 部品手配中（営業が注文を承認） */
  export const ARRANGING = "2";
  /** 注文取消依頼中（顧客が取消を依頼） */
  export const REQUESTED_CANCEL = "3";
  /** 注文取消（営業が注文取消依頼を確定） */
  export const STOPPED_SHIPPING = "4";
  /** 注文否認（営業が注文変更を依頼） */
  export const REQUESTED_CHANGE = "5";
  /** 注文変更（顧客が注文変更） */
  export const CHANGED = "6";
  /** 注文停止（顧客が注文変更依頼を否認） */
  export const DENIED_CHANGE = "7";
  /** 完了（出荷手配済み） */
  export const COMPLETED = "8";
  /** 注文仮承認（営業がLCM注文を仮承認） */
  export const ACCEPTED_LCM = "9";
}

/** 注文区分 */
export namespace OrderFactor {
  /** EC部品交換 */
  export const EC_PARTS = "1";
  /** LCM部品交換 */
  export const LCM_PARTS = "2";
  /** LCMレコメンド */
  export const LCM_RECOMMEND = "3";
}

/** BEMACユーザ分類 */
export namespace BemacUserClassValue {
  /** 営業 */
  export const SALES = "1";
  /** 技師 */
  export const ENGINEER = "2";
  /** 営業マネージャー */
  export const SALES_MANAGER = "3";
  /** 技師マネージャー */
  export const ENGINEER_MANAGER = "4";
}

/** テキストリテラル */
export namespace TextConst {
  /** 検索前 */
  export const GRID_INITIAL = "検索してください";
  /** 検索後 */
  export const GRID_NO_DATA = "表示対象データがありません";
  /** コンボボックスプレースホルダー */
  export const COMBO_PLACEHOLDER = "Type or Select";
  /** 入力テキストボックス */
  export const INPUT_PLACEHOLDER = "Type";
  /** 検索後～選択前 */
  export const GRID_NOT_SELECTED = "選択してください";
  /** パスワード */
  export const INPUT_PASSWORD = "Password";
  /** クイックフィルター */
  export const INPUT_QUICK_FILTER = "quick filter...";
  /** 自動採番 */
  export const AUTOMATIC_NUMBERING = "Automatic Numbering";
  /** 自己紹介 BEMACユーザ*/
  export const SELF_INTRODUCTION_BEMAC =
    "BEMAC社員は自己紹介を必ず記載してください。";
  /** 自己紹介 顧客ユーザ*/
  export const SELF_INTRODUCTION_CUSTOMER =
    "自己紹介や趣味など、ご自由にご記入ください";
  /** 担当船一覧*/
  export const LIST_OF_VESSELS_IN_CHARGE =
    "IMOや船名を入力すると絞り込みができます...";
}

/** レコメンドステータス */
export namespace RecommendStatusClassName {
  /** 作成中 */
  export const DRAFT = "作成中";
  /** 未提案 */
  export const UNRECOMMENDED = "未提案";
  /** 提案中 */
  export const RECOMMENDED = "提案中";
  /** 実施予定 */
  export const SCHEDULED = "実施予定";
  /** 未実施 */
  export const UNIMPLEMENTED = "未実施";
  /** 実施済 */
  export const COMPLETED = "実施済";
}

/** レコメンドステータス */
export namespace RecommendStatusClassValue {
  /** 作成中 */
  export const DRAFT = "0";
  /** 未提案 */
  export const UNRECOMMENDED = "1";
  /** 提案中 */
  export const RECOMMENDED = "2";
  /** 実施予定 */
  export const SCHEDULED = "3";
  /** 未実施 */
  export const UNIMPLEMENTED = "4";
  /** 実施済み */
  export const COMPLETED = "5";
}

/** ファイルアップロード用遷移パラメータ */
export namespace FileUploadParamater {
  /** Service Tariff */
  export const SERVICE_TARIFF = "1";
  /** System Diagram */
  export const SYSTEM_DIAGRAM = "2";
  /** サポート終了部品 */
  export const SUPPORT_END = "3";
  /** マニュアル */
  export const MANUAL = "4";
}

//品目コード
export namespace ProductCodeConst {
  /** 送料 */
  export const DELIVERY_PRICE_CODES = ["7478000", "7478001"];
  /** 経費 */
  export const EXPENSES = "ｹｲﾋ";
  /** 空行 */
  export const BLANK = "---";
  /** 準備費 */
  export const READY = "ｼﾞｭﾝﾋﾞﾋ";
  /** 臨注品 */
  export const SPECIAL_CODES = "7819055";
}

/** ファイルアップロード履歴 ファイル種別 */
export namespace FileUploadFileType {
  /** 不具合対応履歴CSV */
  export const TROUBLE_HISTORY_CSV = "trouble";
  /** 訪船履歴CSV */
  export const VISIT_VESSEL_HISTORY_CSV = "visit";
  /** 工事レポート */
  export const DISPATCH_REPORT_DIR = "dispatch";
}

/** LCMカレンダーステータス */
export namespace LcmCalendarStatus {
  /** 計画中 */
  export const PLAN = "計画中";
  /** 引合中 */
  export const QUOTATION = "引合中";
  /** 未受注 */
  export const NON_ORDER = "未受注";
  /** 受注済 */
  export const ORDER = "受注済";
  /** 発送済 */
  export const DELIVERY = "発送済";
  /** 工事済 */
  export const COMPLETE = "工事済";
}
