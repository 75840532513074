import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "src/component/home/Home";
import LcmCalendarList from "src/component/lcm/LcmCalendarList";
import MaintenanceHistoryList from "src/component/maintenance/MaintenanceHistoryList";

import OwnShipListHome from "../component/home/OwnShipListHome";
import LcmTop from "../component/lcm/LcmTop";
import Login from "../component/login/Login";
import OrderAnalysisTop from "../component/order/OrderAnalysisTop";
import OrderList from "../component/order/OrderList";
import QuotationList from "../component/order/QuotationList";
import CompanyList from "../component/setting/CompanyList";
import ProductList from "../component/setting/ProductList";
import ServiceNewsList from "../component/setting/ServiceNewsList";
import ShipList from "../component/setting/ShipList";
import UserList from "../component/setting/UserList";
import AuthGuardRoute from "./AuthGuardRoute";

function RouteConfig() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route index element={<AuthGuardRoute component={<Home />} />} />
        <Route
          path="/company-list"
          element={<AuthGuardRoute component={<CompanyList />} />}
        />
        <Route
          path="/lcm-top"
          element={<AuthGuardRoute component={<LcmTop />} />}
        />
        <Route
          path="/maintenance-history-list"
          element={<AuthGuardRoute component={<MaintenanceHistoryList />} />}
        />
        <Route
          path="/order-analysis-top"
          element={<AuthGuardRoute component={<OrderAnalysisTop />} />}
        />
        <Route
          path="/order-list"
          element={<AuthGuardRoute component={<OrderList />} />}
        />
        <Route
          path="/product-list"
          element={<AuthGuardRoute component={<ProductList />} />}
        />
        <Route
          path="/quotation-list"
          element={<AuthGuardRoute component={<QuotationList />} />}
        />
        <Route
          path="/ship-list"
          element={<AuthGuardRoute component={<ShipList />} />}
        />
        <Route
          path="/user-list"
          element={<AuthGuardRoute component={<UserList />} />}
        />
        <Route
          path="/service-news-list"
          element={<AuthGuardRoute component={<ServiceNewsList />} />}
        />
        <Route
          path="/home"
          element={<AuthGuardRoute component={<OwnShipListHome />} />}
        />
        <Route
          path="/lcm-calendar-list"
          element={<AuthGuardRoute component={<LcmCalendarList />} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default RouteConfig;
