import axios from "axios";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { useDialog } from "../../context/DialogProvider";
import { useS3 } from "../../hook/useS3";
import { useSplashMessage } from "../../hook/useSplashMessage";
import { FileUploadParamater } from "../../util/Constant";
import FileDropZone from "../common/FileDropZone";

// 公開する関数の定義
export interface FileUploadHandles {
  show(menuCode: string | undefined): void;
}

// 添付ファイルの型
type FileType = {
  name: string;
  size: number;
  file: File[];
  onDelete: boolean;
};

// pdf
const pdf = "application/pdf";
//主処理
const FileUpload = forwardRef<FileUploadHandles>((props, ref) => {
  // state宣言
  const [fileList, setFileList] = useState<FileType[]>([]);
  // ファイル種別
  const [fileCategory, setFileCategory] = useState<string | undefined>();
  // 拡張子
  const [extension, setExtension] = useState<string | undefined>();
  // 拡張子確認用
  const [checkExtension, setCheckExtension] = useState<string | undefined>();
  // 遷移元メニュー
  const [menuCode, setMenuCode] = useState<string | undefined>();
  // ファイル選択フラグ
  const [selectedFlg, setSelectedFlg] = useState(false);
  // ファイルサイズ
  const [fileSize, setFileSize] = useState("");
  // 表示設定
  const [show, setShow] = useState(false);
  // 画面更新チェック
  const [formChanged, setFormChanged] = useState<boolean>(false);
  // アップロード済みファイル名
  const [uploadedFileName, setUploadedFileName] = useState<string>();

  // S3使用宣言
  const s3 = useS3();

  // ダイアログ使用宣言
  const showDialog = useDialog();

  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();

  // 画面初期化
  useImperativeHandle(ref, () => ({
    show(menuCode: string | undefined) {
      //画面遷移パラメータ保持
      setMenuCode(menuCode);
      //画面初期状態設定
      if (menuCode === FileUploadParamater.SERVICE_TARIFF) {
        //Service Tariffを設定
        setFileCategory("Service Tariff");
        setExtension("PDF");
        setCheckExtension(pdf);
      } else if (menuCode === FileUploadParamater.SYSTEM_DIAGRAM) {
        //System Diagramを設定
        setFileCategory("System Diagram");
        setExtension("PDF");
        setCheckExtension(pdf);
      } else if (menuCode === FileUploadParamater.SUPPORT_END) {
        //サポート終了部品を設定
        setFileCategory("サポート終了部品");
        setExtension("PDF");
        setCheckExtension(pdf);
      } else if (menuCode === FileUploadParamater.MANUAL) {
        //マニュアルを設定
        setFileCategory("マニュアル");
        setExtension("PDF");
        setCheckExtension(pdf);
      }

      getFileList(menuCode);
      setShow(true);
    },
  }));

  // 対象のファイルを取得する
  function getFileList(menuCode: string | undefined) {
    let s3Prefix;
    let isPublic: boolean = false; // デフォルトは署名付き

    // 取得データを初期化
    setSelectedFlg(false);
    setFileList([]);
    setUploadedFileName("");
    setFileSize("");
    setFormChanged(false);

    switch (menuCode) {
      case FileUploadParamater.SERVICE_TARIFF:
        // Service Tariff
        s3Prefix = `document/service-tariff/`;
        isPublic = false;
        break;
      case FileUploadParamater.SYSTEM_DIAGRAM:
        // System Diagram
        s3Prefix = `document/system-diagram/`;
        isPublic = true;
        break;
      case FileUploadParamater.SUPPORT_END:
        // サポート終了部品
        s3Prefix = `document/end-of-support-item/`;
        isPublic = true;
        break;
      case FileUploadParamater.MANUAL:
        // マニュアル
        s3Prefix = `manual/`;
        isPublic = true;
        break;
      default:
        break;
    }

    if (s3Prefix) {
      s3.getS3FileList(s3Prefix, isPublic).then((response) => {
        response.data.forEach((objectKey) => {
          if (isPublic) {
            const url = s3.getS3Path(objectKey, true);
            loadFile(url, objectKey);
          } else {
            // 署名付きURLを取得してからデータ取得
            s3.getS3PresignedUrl("GET", objectKey).then((res2) => {
              const presignedUrl = res2.data;
              loadFile(presignedUrl, objectKey);
            });
          }
        });
      });
    }
  }

  // S3からファイルの実データを取得
  function loadFile(url: string, objectKey: string) {
    axios.get<Blob>(url, { responseType: "blob" }).then((blobResponse) => {
      if (blobResponse) {
        const fileName = objectKey.substring(objectKey.lastIndexOf("/") + 1);
        const targetFile = new File([blobResponse.data], fileName, {
          type: blobResponse.headers["content-type"],
        });

        // 取得したファイル情報を設定
        setUploadedFileName(fileName);
        //ファイルサイズをkb（少数第一まで）に変換
        setFileSize(`${Math.floor((targetFile.size / 1024) * 10) / 10}`);
      }
    });
  }

  // FileDropZone ファイル選択
  function handleSelectFile(files: File[]) {
    if (files.length > 1) {
      showDialog({ id: "E064", confirm: false });
      return;
    }
    let fileType = true;
    let newFile: FileType = {
      name: "",
      size: 0,
      file: [],
      onDelete: false,
    };
    const newFileList = files.map((file) => {
      //ファイルの拡張子を判定
      if (!(file.type === checkExtension)) {
        fileType = false;
        return newFile;
      }
      //値を設定
      newFile.name = file.name;
      newFile.size = file.size;
      newFile.file = newFile.file.concat(file);

      setSelectedFlg(true);
      return newFile;
    });
    //ファイルの拡張子を判定
    if (!fileType) {
      showDialog({ id: "E020", args: ["PDFファイル"], confirm: false });
      return;
    }

    setFileList([...newFileList]);

    // 更新チェック
    if (!formChanged) {
      setFormChanged(true);
    }
  }

  // Uploadボタン押下
  function onUploadButton() {
    // 正常処理後の動作
    const saveFunc = () => {
      saveData();
    };
    //ファイル更新確認
    showDialog({
      id: "I060",
      args: [fileCategory],
      confirm: true,
      callback(isOk) {
        if (isOk) {
          // 保存処理
          saveFunc();
        }
      },
    });
  }

  // データ保存
  function saveData() {
    // 画像をS３にアップロード
    const uploadPromises = Promise.all([savePicture()]).then(() => {
      splashMessage.show("I035");
      getFileList(menuCode);
    });
    return uploadPromises;
  }

  // 画像ファイルをS3に登録
  function savePicture() {
    let s3PrefixDelete;
    let s3PrefixUpload;
    let isPublic: boolean = false; // デフォルトは署名付き

    // 既存画像の削除
    switch (menuCode) {
      case FileUploadParamater.SERVICE_TARIFF:
        // Service Tariff
        if (uploadedFileName) {
          s3PrefixDelete = `document/service-tariff/${uploadedFileName}`;
        }

        if (fileList.length > 0) {
          s3PrefixUpload = `document/service-tariff/${fileList[0].name}`;
        }
        isPublic = false;
        break;
      case FileUploadParamater.SYSTEM_DIAGRAM:
        // System Diagram
        if (uploadedFileName) {
          s3PrefixDelete = `document/system-diagram/${uploadedFileName}`;
        }
        if (fileList.length > 0) {
          s3PrefixUpload = `document/system-diagram/${fileList[0].name}`;
        }
        isPublic = true;
        break;
      case FileUploadParamater.SUPPORT_END:
        // サポート終了部品
        if (uploadedFileName) {
          s3PrefixDelete = `document/end-of-support-item/${uploadedFileName}`;
        }

        if (fileList.length > 0) {
          s3PrefixUpload = `document/end-of-support-item/${fileList[0].name}`;
        }
        isPublic = true;
        break;
      case FileUploadParamater.MANUAL:
        // マニュアル
        if (uploadedFileName) {
          s3PrefixDelete = `manual/${uploadedFileName}`;
        }

        if (fileList.length > 0) {
          s3PrefixUpload = `manual/${fileList[0].name}`;
        }
        isPublic = true;
        break;
      default:
        break;
    }
    // 既存ファイルをS3から削除
    const deletePromises: any[] = [];
    if (s3PrefixDelete) {
      deletePromises.push(s3.deleteS3File(s3PrefixDelete, isPublic));
    }

    // 追加指定したファイルをS3に登録
    const putPromises: any[] = [];
    if (s3PrefixUpload) {
      putPromises.push(
        s3.putS3File(s3PrefixUpload, fileList[0].file[0], isPublic)
      );
    }
    return Promise.all([...deletePromises, ...putPromises]);
  }

  // 添付ファイルリストレンダリング
  function attachmentFiles() {
    if (fileList.length !== 0) {
      return (
        <div>
          {fileList.map((it) => {
            const onClick = () => {
              if (it.file) {
                if (!it.onDelete) {
                  if (isPdfFile(it.name)) {
                    // 画像・PDFは別ウィンドウで開く
                    const blobUrl = URL.createObjectURL(it.file[0]);
                    window.open(blobUrl);
                  } else {
                    // その他はダウンロード(基本的にはないはずだが念の為)
                    const a = document.createElement("a");
                    a.download = it.name;
                    a.href = URL.createObjectURL(it.file[0]);
                    a.click();
                    a.remove();
                  }
                }
              }
            };
            if (selectedFlg) {
              return (
                <div key={it.name}>
                  <a href="#" onClick={onClick}>
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-pdf.png`}
                      title={it.name}
                      alt={it.name}
                      style={{ height: "auto", width: "80px" }}
                      data-cy="添付ファイルサムネイル"
                    />
                  </a>
                  <br />
                </div>
              );
            } else {
              return undefined;
            }
          })}
        </div>
      );
    }
  }

  // PDFファイル判定
  function isPdfFile(path: string) {
    return path.endsWith(".pdf");
  }

  // モーダルクローズハンドラ
  const handleClose = () => {
    if (formChanged) {
      showDialog({
        id: "I001",
        confirm: true,
        callback(isOk) {
          if (isOk) {
            setShow(false);
            setMenuCode(undefined);
          }
        },
      });
    } else {
      setShow(false);
      setMenuCode(undefined);
    }
  };

  // モーダルbody部レンダリング
  function modalBody() {
    return (
      <>
        <div>
          {/* 1行目 */}
          <div className="row">
            <div className="input-group">
              {/* ファイル種別 */}
              <span className="b-input-group-text" style={{ width: "15rem" }}>
                ファイル種別
              </span>
              <span
                className="form-control"
                style={{
                  lineHeight: 2,
                }}
                data-cy="ファイル種別"
              >
                {fileCategory} &nbsp;&nbsp;&nbsp;&nbsp;({extension})
              </span>
            </div>
          </div>
          {/* 2行目 */}
          <div className="row">
            <div className="input-group">
              {/* ファイル種別 */}
              <span className="b-input-group-text" style={{ width: "15rem" }}>
                アップロード済ファイル名
              </span>
              <div
                className="form-control"
                style={{
                  lineHeight: 2,
                }}
                data-cy="アップロード済ファイル名"
              >
                {uploadedFileName}
                {uploadedFileName !== "" && (
                  <span
                    style={{
                      marginLeft: "8rem",
                      lineHeight: 2,
                    }}
                    data-cy="ファイルサイズ"
                  >
                    ({fileSize}&nbsp;kb)
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* ファイル添付 */}
          <div className="mt-3  my-3">
            <FileDropZone onSelectFiles={handleSelectFile} />
            <div className="p-1">{attachmentFiles()}</div>
          </div>
        </div>
      </>
    );
  }

  // モーダルfooter部レンダリング
  function modalFooter() {
    return (
      <>
        <Button
          type="button"
          className="b-btn-primary"
          data-cy="Uploadボタン"
          onClick={() => onUploadButton()}
          disabled={!selectedFlg}
        >
          Upload
        </Button>
        <Button
          className="b-btn-light"
          onClick={handleClose}
          data-cy="Closeボタン"
        >
          Close
        </Button>
      </>
    );
  }

  // レンダリング
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      scrollable
      data-cy="ファイル登録モーダル"
    >
      <Modal.Header closeButton data-cy="モーダルヘッダー">
        <Modal.Title>ファイル登録</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBody()}</Modal.Body>
      <Modal.Footer>{modalFooter()}</Modal.Footer>
    </Modal>
  );
});

export default FileUpload;
