function RequireMark() {
  return (
    <>
      <span style={{ color: "red", marginLeft: "5px" }} data-cy="必須マーク">
        *
      </span>
    </>
  );
}

export default RequireMark;
